import { Base } from '../proto/base';
import { ClientEvent as ClientEventV3 } from '../proto/v3/client_event';
import { ClientEvent as ClientEventV2 } from '../proto/v2/client_event';
import { logFactory } from '@/core/log';
import { SendConfig } from '@/core';

export interface UrlObj {
    page?: string;
    params?: any;
}

export interface UrlMapFnParam {
    url?: string;
    page?: string;
    params?: any;
}

export type UrlMapFn = (param: UrlMapFnParam) => (UrlObj | string);

export type BaseOptions = {
    /**
     * did
     */
    device_id?: string;
    /**
     * 预留字段, 端内页面不可缺省，服务端需解密
     */
    global_id?: string;
    /**
     * 用户唯一标志
     */
    user_id?: string;
    /**
     * 标识调用方,产品线（新注册时自动增加）,找韩小龙要
     * 默认是0，表示产品未被注册= =
     */
    product?: number;
    /**
     * 来源
     */
    channel?: string;
    /**
     * 版本名
     */
    version_name?: string;
    /**
     * 应用包名
     */
    package_name?: string;
    /**
     * 代替app_product参数上报具体接入的app，具体值请联系韩小龙分配
     */
    product_name?: string;
    /**
     * 各端设备识的备用参数，例如微信小程序端上报微信内部用户的唯一标识
     */
    union_id?: string;
    /**
     * 网络类型
     */
    network_type?: keyof typeof networkType,
    /**
     * 区分平台
     */
    container?: Base.Container;
    /**
     * 中台服务名称，比如积木、im sdk等，如果业务log未使用中台服务，该字段置空
     */
    service_name?: string;
    /**
     * 子业务场景
     */
    sub_biz?: string;
    /**
     * 是否需要加密
     */
    need_encrypt?: boolean;
    /**
     * 防盗链id
     */
    safety_id?: string;
    /**
     * AB实验
     */
    experiment?: Base.Experiment[];
    /**
     * H5 附加参数
     */
    h5_extra_attr?: H5ExtraAttr;
    /**
     * 表示宿主平台环境，默认是 PC 端 web 页面
     */
    platform?: Base.Platform;
    /**
     * 表示页面会话 id
     */
    session_id?: string;
};
/**
 * 事件
 */
// 网络类型
export const networkType = {
    unknown: Base.NetworkPackageType.UNKNOWN_NETWORK,
    none: Base.NetworkPackageType.NOT_CONNECTED,
    wifi: Base.NetworkPackageType.WIFI,
    '4g': Base.NetworkPackageType.MOBILE_4G,
    '3g': Base.NetworkPackageType.MOBILE_3G,
    '2g': Base.NetworkPackageType.MOBILE_2G,
    'slow-2g': Base.NetworkPackageType.MOBILE_2G,
};

// 任务事件类型
export enum TaskOperations {
    CLICK = 'CLICK',
    DOUBLE_CLICK = 'DOUBLE_CLICK',
    TRIPLE_CLICK = 'TRIPLE_CLICK',
    LONG_PRESS = 'LONG_PRESS',
    PULL = 'PULL',
    DRAG = 'DRAG',
    SCALE = 'SCALE',
    PULL_DOWN = 'PULL_DOWN',
    PULL_UP = 'PULL_UP'
}

// 事件类型
export enum EventTypes {
    PV = 'PV',
    SHOW = 'SHOW',
    // Costume
    CUSTOM = 'CUSTOM',
    RADAR = 'RADAR',
}

export type StatusTypes = keyof typeof Base.ActionStatus;

export type OperationDirectionTypes = keyof typeof Base.Direction;

export type TaskStatusTypes = keyof typeof Base.TaskStatus;

export interface OtherEventOptions {
    name: string;
    params?: any;
    referUrlPackage?: Base.UrlPackage;
    currentUrlPackage: Base.UrlPackage;
    contentPackage?: ContentPackage;
    status?: TaskStatusTypes;
    taskType?: TaskEventTypeStrings;
    eventId?: string;
    operationDirection?: OperationDirectionTypes;
}


// H5 附加参数
export interface H5ExtraAttr {
    // 主要用于 bridge 模式下，将当前产品的 product_name 透传
    product_name?: string;
    // 描述实际埋点对应的产品名称。比如一个h5的产品A，嵌入到产品B中使用，则product_name = A
    host_product?: string;
    // 宿主客户端版本号
    app_version_name?: string;
    seq_id?: number;
    http_seq_id?: number;
    domain?: string;
    [key: string]: any;
}


export type PageTypeKey = 'web' | 'native' | 'mina';

export interface UrlMap {
    [key: string]: string;
}


export type ContentPackage = string | {[key: string]: any};

export interface ShowEventOptions {
    type: 'SHOW' | 'PV';
    referUrlPackage?: Base.UrlPackage;
    currentUrlPackage: Base.UrlPackage;
    action: 'enter' | 'leave' | 'hidden' | 'visible';
    beginTime?: number;
    name?: string;
    params?: any;
    contentPackage?: ContentPackage;
    status?: StatusTypes;
    operationDirection?: OperationDirectionTypes;
    actionType?: keyof typeof Base.ActionType;
    auto?: boolean;
}

export interface ShowEventProps {
    referUrlPackage?: Base.UrlPackage;
    currentUrlPackage: Base.UrlPackage;
    type: 'enter' | 'leave' | 'hidden' | 'visible';
    beginTime?: number;
    name: string;
    params?: any;
    contentPackage?: ContentPackage;
    status?: StatusTypes;
    eventId?: string;
    operationDirection?: OperationDirectionTypes;
    actionType?: keyof typeof Base.ActionType;
    auto?: boolean;
}

export type TaskEventTypeStrings = keyof typeof ClientEventV3.TaskEventType;

export type TaskEventOperationTypes = keyof typeof Base.OperationType;

export interface TaskEventOptions {
    sessionId: string;
    referUrlPackage?: Base.UrlPackage;
    currentUrlPackage: Base.UrlPackage;
    name: string;
    params?: any;
    type: TaskOperations;
    contentPackage?: ContentPackage;
    status?: StatusTypes;
    taskType?: TaskEventTypeStrings | keyof typeof ClientEventV2.ClickType;
    operationDirection?: keyof typeof Base.Direction;
}

export interface CustomStatEventOptions {
    name: string;
    params: any;
    currentUrlPackage: Base.UrlPackage;
    referUrlPackage?: Base.UrlPackage;
    [key: string]: any;
}

export interface Weblogger {
    logConfig: any;
    collect: (type: string, ...params: any) => void;
    sendImmediately: (type: string, ...params: any) => void;
    plug: (params: any, options?: any) => void;
    addPluginInstance: (plugin: any) => void;
    unplug: (name: string) => void;
    updateBase: (options: BaseOptions) => void;
    updateCommonPackage: (options: BaseOptions) => void;
    updateReferUrlPackage: (url?: string | UrlObj, type?: 'web' | 'native' | 'mina') => void;
    updateCurrentUrlPackage: (url: string | UrlObj, type?: 'web' | 'native' | 'mina') => void;
    flush: () => void;
    logger: any;
    currentUrlPackage: Base.UrlPackage & { getRealUrlPackage: Function, update: (page: string, params: any) => void };
    referUrlPackage?: Base.UrlPackage & { getRealUrlPackage: Function };
    currentUrl: string;
    commomPackage: any;
    baseOption: any;
    plugins: {[key: string]: any};
    bridge?: any;
    yoda?: any;
    sessionId: string;
    Utils: {
        [key: string]: any;
    };
    generateLog: (
        type: string | SendConfig,
        name: string | PVOptions | ShowOptions | TaskOptions | CustomOptions | OtherEventOptions,
        params?: any,
        contentPackage?: ContentPackage,
        factory?: (type: string, data: any) => any,
    ) => any;
}

export type AutoPVFilter = (newUrl: string, oldUrl: string | null, type: 'enter' | 'leave' | 'hidden' | 'visible') => boolean;


/**
 * PV 类型参数
 */
export interface PVOptions {
    page?: string;
    identity?: string;
    pageType?: string;
    params?: string | any;
    contentPackage?: ContentPackage;
    currentUrlPackage?: Base.UrlPackage;
    status?: StatusTypes;
    type: 'enter' | 'leave' | 'hidden' | 'visible';
    beginTime?: number;
    eventId?: string;
    auto?: boolean;
    callback?: (error?: any) => void;
}

interface UrlPage {
    page?: string; // url_package.page 当前 page 的 name
    identity?: string;
}

/**
 * Show 类型参数
 */
export interface ShowOptions {
    action: string;
    params?: any;
    contentPackage?: ContentPackage;
    currentUrlPackage?: Base.UrlPackage;
    eventId?: string;
    urlPage?: UrlPage;
    callback?: (error?: any) => void;
}

/**
 * TASK 类型参数
 */
export interface TaskOptions {
    action: string;
    params?: any;
    contentPackage?: ContentPackage;
    currentUrlPackage?: Base.UrlPackage;
    type?: TaskEventTypeStrings;
    status?: TaskStatusTypes;
    eventId?: string;
    urlPage?: UrlPage;
    callback?: (error?: any) => void;
}

/**
 * CUSTOM 类型参数
 */
export interface CustomOptions {
    key: string;
    value?: any;
    eventId?: string;
    urlPage?: UrlPage;
    currentUrlPackage?: Base.UrlPackage;
    callback?: (error?: any) => void;
}
