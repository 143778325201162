/**
 * @file 劫持 XMLHttpRequest，自动记录请求成功、失败打点
 * @author Malcolm Yu
 * @created 2019-11-16
 *
 * 由于发现了一个好用的 hook，这里就不闷头开发了：https://github.com/jpillora/xhook
 */

import { xhook } from '@ks/xhook';
import { RADAR_KEY, apiPerfMap } from './const';
import { APILog, APIPerf, KVPair, PerfMapType } from './interface';
import { isInURLWhiteList } from './utils';
import RadarPlugin from './plugin';
import { getStringBytes } from '../../util';

interface RequestMark extends APILog {
    request: Request;
    startTime: number;
}
const validXhook = xhook.xhook || xhook;
// 引入后先关闭，默认会替换 FormData
validXhook.disable();

export default class APIMonitor extends RadarPlugin {
    private queue: RequestMark[] = [];
    // private xhrLog: APIPerf[] = [];

    public key = 'api';
    private logList: APIPerf[] = [];
    private perfList: PerfMapType[] = [];

    when(entry: PerformanceEntry) {
        if (isInURLWhiteList(entry.name as string, this.radar.ignoreList)) {
            return false;
        }
        return entry.entryType === 'resource' && (entry.initiatorType === 'fetch' || entry.initiatorType === 'xmlhttprequest');
    }

    onPerfReport(entry: PerformanceEntry) {
        const logData = this.radar.generateLog(apiPerfMap, entry as PerformanceEntry);
        this.perfList.push(logData);
        this.mergeAPIPerf({ ...logData, api: logData.file });
    }

    mergeAPIPerf(allPerf: PerfMapType | null, info?: APIPerf) {
        const item = allPerf || info;
        const list = allPerf ? this.logList : this.perfList;

        if (!item) return;
        let index = -1;
        // 每次只处理一个item且fetch请求不会被xhook获取 所以可以单独处理一下fetch
        // 但是上报的信息没有xhr全面。后续有方案了可以补全
        if (item.res_type === 'fetch') {
            delete item.file;
            this.radar.logCollect(this.sepDimension({
                key: RADAR_KEY.API,
                value: item,
            }));
            return;
        }
        // xhr需要沿用原有方案
        for (let i = 0, len = list.length; i < len; i++) {
            const listItem = list[i];
            const itemAPI = (item.api || '').replace(/\?.+$/, '');
            const listItemAPI = (listItem.api || '').replace(/\?.+$/, '');

            const perfSize = item.size;
            if (itemAPI === listItemAPI) {
                Object.assign(item, listItem);
                // 这里 file 与 api 重复了
                delete item.file;
                // perf api 中的 size 更精确一些
                if (perfSize) {
                    item.size = perfSize;
                }

                // 依然没有得到 size，尝试计算 responseText 体积
                if (!item.size && item.responseData) {
                    item.size = getStringBytes(item.responseData);
                }
                delete item.responseData;

                index = i;
            }
        }

        if (index !== -1) {
            list.splice(index, 1);

            // 这里如果找不到匹配 API，还不如不报
            this.radar.logCollect(this.sepDimension({
                key: RADAR_KEY.API,
                value: item,
            }));
        }
    }

    sepDimension(kv: KVPair) {
        const { key } = kv;
        const { protocol, cached, status, api, method, ...value } = kv.value;
        const dimension = { protocol, api, status, cached, method };
        return { key, value, dimension };
    }

    private requestURLCompletion(url: string) {
        const { protocol, host } = document.location;

        if (url.indexOf('//') === 0) {
            return protocol + url;
        }
        if (url.indexOf('/') === 0) {
            return `${protocol}//${host}${url}`;
        }

        return url;
    }

    private beforeHook = (request: Request) => {
        // 禁 止 套 娃
        if (!isInURLWhiteList(request.url, this.radar.ignoreList)) {
            const info = {
                api: this.requestURLCompletion(request.url),
                method: request.method,
                request,
                startTime: Date.now(),
            };
            this.queue.push(info);
        }
    };
    // xhook重写了fetch函数，看起来会导致headers前后不相同
    // https://github.com/jpillora/xhook/blob/gh-pages/src/xhook.coffee#L524
    private afterHook = (request: Request, response: Response) => {
        const req = this.queue.filter(v => {
            // 适配fetch
            // 匹配xhr请求
            return v.request === request;
        })[0];
        if (!req) return;
        // @ts-ignore
        delete req.request;
        const { startTime, ...rest } = req;

        let infoStatus: number = response.status;
        // 如果用户有配置status，则使用用户配置的，返回为0则为异常
        if (typeof this.options.customizeRadarStatus === 'function') {
            infoStatus = this.options.customizeRadarStatus(response);
        }

        const contentLength = getResponseSize(response.headers);
        const info: any = {
            duration: Date.now() - startTime,
            // 字段兼容
            total: Date.now() - startTime,
            status: infoStatus, // 传给后端的是response的status或者是用户配置的status
            size: +contentLength,
            ...rest,
        };

        if (typeof response.text === 'string') {
            info.responseData = response.text;
        }

        // 重新定义一个response，然后进行错误判断
        const judgedResponse: Response = { ...response, status: infoStatus };
        const isError = this.isResponseError(judgedResponse);

        // TODO: 兼容性处理可以更优秀一点
        if (isError) {
            this.radar.logCollect(this.sepDimension({
                key: RADAR_KEY.API,
                value: info,
            }));
        } else {
            this.logList.push(info);
            this.mergeAPIPerf(null, info);
        }

        // 过滤掉这个 request，防止内存堆积
        this.queue = this.queue.filter(v => v.request !== request);
    };

    private isResponseError(response: Response) {
        // 在 hook 的加持下，可以拿到异常的返回，这里 status 指定为 0
        return response.status === 0;
    }

    public created() {
        validXhook.disable();
        const NATIVE_FORM_DATA = window.FormData;

        validXhook.before(this.beforeHook);
        validXhook.after(this.afterHook);

        validXhook.enable();
        window.FormData = NATIVE_FORM_DATA;
    }

    public destroy() {
        validXhook.disable();
    }
}

function getResponseSize(headers: any) {
    if (!headers) {
        // fix: 接口504可能导致取不到header 会报错
        return 0;
    }
    if (typeof headers.get === 'function') {
        return +headers.get('Content-Length') || 0;
    }
    return +headers['content-length'] || 0;
}
