/**
 * @file 日志公参，基本确定不变的数据
 * @author <zhangshen@kuaishou.com>
 */
import { Base } from '../proto/base';
import { getCookie } from '../util/cookie';
import {
    isInApp,
    getDefaultKpn,
    parseQueryString
} from '../util';
import { getUAInfo } from '../util/ua';
import { generateDID } from '../util/uuid';
import { DeepPartial } from 'utility-types';
import { getModel, getResolution, isReactNative } from '../util/device-detect';
import { H5ExtraAttr, networkType } from '../config/types';
import { getBridgeNormalIncrementId, getBridgeCustomIncrementId, getHttpIncrementId, getChannelIncrementId, getV2EventIncrementId } from '../util/increment-id';
import { initPackageKeys } from '../config/base-params';
import Global from '../config/global';
import { ClientCommon as ClientCommonV2 } from '../proto/v2/client_common';
import { getDeviceInfo, getAppInfo } from '../util/yoda';

function network() {
    try {
        const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
        if (!connection) {
            // 不支持connection的全部为UNKNOWN_NETWORK
            return networkType.unknown;
        }
        const type = connection.type;
        const effectiveType = connection.effectiveType;
        return networkType[type] || networkType[effectiveType] || networkType.unknown;
    } catch (err) {
        return networkType.unknown;
    }
}

function platform(): Base.Platform {
    try {
        const uaInfo = getUAInfo();
        if (!uaInfo.os.name) {
            return Base.Platform.UNKNOWN_PLATFORM;
        }
        const inApp = isInApp();
        if (uaInfo.os.name === 'Android') {
            return inApp
                ? Base.Platform.ANDROID_PHONE_H5
                : Base.Platform.OUTSIDE_ANDROID_H5;
        }
        if (uaInfo.os.name === 'iOS') {
            return inApp
                ? Base.Platform.IPHONE_H5
                : Base.Platform.OUTSIDE_IOS_H5;
        }
        return Base.Platform.PC_WEB;
    } catch (err) {
        return Base.Platform.UNKNOWN_PLATFORM;
    }
}

export function container(): Base.Container {
    if (isReactNative()) {
        return Base.Container.REACT_NATIVE;
    }
    if (platform() === Base.Platform.PC_WEB) {
        return Base.Container.WEB;
    }
    return Base.Container.H5;
}

export type CommonPackageOptions = DeepPartial<Base.CommonPackage>;

function mergeOptions(target: any, options: any) {
    if (!options) {
        return;
    }
    Object.keys(options).forEach(key => {
        const value = options[key];
        const isNeedMerge = key !== 'h5_extra_attr';
        if (typeof value === 'object' && isNeedMerge && target[key] !== undefined) {
            mergeOptions(target[key], value);
        } else if (value) {
            target[key] = value;
        }
    });
}
const getOSVersion = () => {
    try {
        return getUAInfo().os.version || 'unknown';
    } catch (err) {
        return 'unknown';
    }
}


export function transformCommonPackage(base: {[key: string]: any}, config: any) {
    const initPackage:{[key: string]: any} = {
        identity_package: {},
        network_package: {},
        app_package: {},
        experiment: [],
        service_name: '',
        safety_id: '',
    };
    if ((Global.isV2 || Global.isBridge) && !base.product) {
        base.product = ClientCommonV2.Product[base.product_name || getCookie('kpn')] || 1;
    }
    for (const { keys, validator, type } of initPackageKeys) {
        const [baseKey, subKey] = keys;
        const baseValue = base[subKey || baseKey];
        if (!subKey) {
            initPackage[baseKey] = base[baseKey];
        } else {
            initPackage[baseKey][subKey] = base[subKey];
        }
        if (typeof baseValue !== 'undefined' && !validator(baseValue)) {
            throw new Error([
                `There is an error init value on '${subKey || baseKey}', `,
                `the type should be '${type}', `,
                `but your type is '${typeof baseValue}'`,
            ].join(''));
        }
    }
    if (base.network_type) {
        initPackage.network_package.type =
            networkType[base.network_type as keyof typeof networkType] ||
            networkType.unknown;
    } else {
        delete initPackage.network_package;
    }
    return new CommonPackage(initPackage, config);
}
export type ICommonPackage = typeof CommonPackage;
export default class CommonPackage implements Base.CommonPackage {
    identity_package = {
        device_id: generateDID(),
        global_id: isInApp() ? getCookie('egid') : '',
        user_id: getCookie('userId') || getCookie('userName') || '',
    };
    app_package = {
        product: undefined,
        language: navigator.language,
        platform: platform(),
        container: container(),
        package_name: '',
        product_name: '',
        version_name: getCookie('appver', undefined, true) || '', // 宿主客户端版本号
        channel: '',
        version_code: 0
    };

    device_package = {
        os_version: getOSVersion(),
        model: getModel(),
        ua: navigator.userAgent,
    };

    additional_seq_id_package?: Base.AdditionalSeqIdPackage;
    need_encrypt: boolean = false;
    network_package = {
        type: network(),
    };
    // H5 附加参数
    h5_extra_attr: H5ExtraAttr;
    sdk_version = '__VERSION__';
    // 默认 H5 附加信息
    static DefaultH5ExtraAttr: H5ExtraAttr = {
        host_product: getDefaultKpn(),
        app_version_name: getCookie('appver', undefined, true) || '', // 宿主客户端版本号
        resolution: getResolution(),
        domain: window && window.location && window.location.origin || '',
        sdk_name: '__SDK_NAME__',
        sdk_version: '__VERSION__',
    }

    constructor(options: CommonPackageOptions, logConfig: any) {
        mergeOptions(this, options);
        this.checkMustProps();

        if (this.app_package.version_name) {
            const lastDotIndex = this.app_package.version_name.lastIndexOf('.');
            this.app_package.version_code = +this.app_package.version_name.slice(lastDotIndex + 1) || 0;
        }

        // TODO: 这块参数有些混乱，options 和 logConfig 都可能传 h5_extra_attr 和 product_name 进来
        const mixinOptions: any = {
            ...options,
            ...logConfig
        };
        this.h5_extra_attr = {
            ...CommonPackage.DefaultH5ExtraAttr,
            ...mixinOptions.h5_extra_attr,
            app_version_name: this.app_package.version_name,
            product_name: mixinOptions.product_name
        };
    }
    /**
     * 检查必填参数
     */
    checkMustProps() {
        if (Global.isV2) {
            if (typeof this.app_package.product !== 'number') {
                console.error('请设置有效的 product 值!');
            }
        } else {
            if (typeof this.app_package.product_name !== 'string') {
                console.error('请设置有效的 product_name 值!');
            }
        }
    }

    setAdditionalSeqIdPackage(eventType: string) {
        this.additional_seq_id_package = {
            channel: Base.AdditionalSeqIdPackageChannel.NORMAL,
            channel_seq_id: getChannelIncrementId('NORMAL'),
            custom_type: eventType,
            custom_seq_id: getV2EventIncrementId(eventType)
        };
    }

    // h5 id 自增，用在 Bridge 模式下验证 Bridge 丢失率
    increaseH5SeqId(isCustom?: Boolean) {
        this.setH5ClientTimestamp();
        this.h5_extra_attr.seq_id = isCustom ? getBridgeCustomIncrementId() : getBridgeNormalIncrementId();
    }
    // 在 bridge 模式下生成时间戳
    setH5ClientTimestamp() {
        this.h5_extra_attr.client_timestamp = new Date().valueOf();
    }
    // HTTP 自增 id，每次发起请求连续递增，验证 http 请求丢失
    increaseHTTPSeqId() {
        this.setH5ClientTimestamp();
        this.h5_extra_attr.http_seq_id = getHttpIncrementId();
    }
    setChannel() {
        // for radar_channel
        // parseQueryString 居然需要自己去掉问号。。
        if(this.app_package.channel){
            this.h5_extra_attr.channel = this.app_package.channel
            return
        }
        const search = window && window.location && window.location.search || ''
        const queryStringRes = parseQueryString(search.slice(1))
        const queryChannel = queryStringRes ? queryStringRes.channel : ''
        this.h5_extra_attr.channel = queryChannel || getCookie('channel') || ''
    }
    getVersionName() {
        return this.app_package.version_name
    }
    getChannel() {
        return this.h5_extra_attr.channel
    }
    toJSON() {
        if (!this.identity_package.user_id) {
            this.identity_package.user_id = getCookie('userId') || getCookie('userName') || '';
        }
        const json: Base.CommonPackage = {
            ...this,
            h5_extra_attr: {
                ...this.h5_extra_attr
            },
            toJSON() {
                return {
                    ...json,
                    h5_extra_attr: JSON.stringify(json.h5_extra_attr)
                };
            }
        };
        if (Global.isV2) {
            // v2 中没有 product_name 和 sdk_version
            delete json.app_package.product_name;
            delete json.sdk_version;
            // v2 要求 user_id 是数字，不能为空字符串
            if (json.identity_package.user_id === '') {
                delete json.identity_package.user_id;
            }
        }
        return json;
    }
    // 设置 bridge 信息
    setBridgeInfo(info?: string) {
        if (info) {
            this.h5_extra_attr.bridge_info = info;
        }
    }
    getH5ExtraAttr() {
        return this.h5_extra_attr;
    }
    update(options: { [key: string]: any }) {
        if (typeof options !== 'object') return;
        const commonPackage = JSON.parse(JSON.stringify(this));
        Object.keys(options).forEach((key) => {
            let newValue = options[key];
            if (key in commonPackage) {
                if (typeof commonPackage[key] === 'object' && typeof newValue === 'object'
                    && !(Array.isArray(commonPackage[key]) && newValue)) {
                    newValue = {
                        ...commonPackage[key],
                        ...newValue
                    };
                }

                (this as any)[key] = newValue;
            } else {
                const innerPackageKeys = ['identity_package', 'app_package', 'device_package'];
                for (const innerKey of innerPackageKeys) {
                    if (key in commonPackage[innerKey]) {
                        (this as any)[innerKey][key] = newValue;
                        break;
                    }
                }
            }
        });
    }
    async initYodaDeviceInfo () {
        const info = await getDeviceInfo();
        if (info && info.mod) {
            this.device_package.model = info.mod;
        }
    }

    async initYodaAppInfo () {
        const info = await getAppInfo();
        if (info) {
            info.did && (this.identity_package.device_id = info.did);
            info.userId && !this.identity_package.user_id && (this.identity_package.user_id = info.userId);
            info.appver && (this.app_package.version_name = info.appver);
        }
    }
}
