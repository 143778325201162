/**
 * v2、v3 PB 相同部分
 */
export namespace Base {
    // 设备和用户Id
    export interface IdentityPackage {
        device_id: string;  // 设备Id, 唯一标识一台设备
        user_id?: string | number;  // 用户Id, 唯一标识一个用户
        iu_id?: string;  // 数盟sdk获取到的设备Id, 唯一标识一台设备(仅用于安卓)
        global_id?: string;  // 内部SDK生成的全局唯一id，识别多个app之间的相同设备
        union_id?: string;  // 各端设备识的备用参数，例如微信小程序端上报微信内部用户的唯一标识
        open_id?: string;  // 微信小程序open_id，需加解密
    }

    // 设备基础信息
    export interface DevicePackage {
        os_version: string; // 操作系统版本
        model: string; // 机型
        ua: string; // user agent
    }

    // 网络信息
    export enum NetworkPackageType {
        UNKNOWN_NETWORK = 0, // 未知
        NOT_CONNECTED = 1, // 网络处于关闭状态
        WIFI = 2, // WIFI网络
        MOBILE_4G = 3, // 移动网络, 4G
        MOBILE_3G = 4, // 移动网络, 3G
        MOBILE_2G = 5, // 移动网络, 2G
        MOBILE_UNKNOWN = 6, // 未识别出的移动网络类型
        MOBILE_5G = 7, // 移动网络, 5G
    }
    export interface NetworkPackage {
        type: NetworkPackageType; // 网络类型
        isp?: string; // 运营商
        ip?: string; // 外网IP地址
        ipv6?: string; // ipv6地址
    }

    // 地理位置信息
    export interface LocationPackage {
        country: string; // 国家
        province: string; // 省份/自治区/州
        city: string; // 城市
        county: string; // 县/区域
        street: string; // 街道
        latitude: number; // 纬度
        longitude: number; // 经度
    }

    // A/B测试实验
    export interface Experiment {
        name: string;  // 实验名称
        value: string; // 实验分组
    }

    export enum AdditionalSeqIdPackageChannel {
        UNKNOWN1 = 0,
        REALTIME = 1,
        HIGH_FREQ = 2,
        NORMAL = 3,
    }
    // 客户端日志额外的sequenceId，用来验证不同发送通道、不同类型的日志的丢失率等。
    export interface AdditionalSeqIdPackage {
        channel: AdditionalSeqIdPackageChannel; // 数据发送通道类型
        channel_seq_id: number; // 日志对应的发送通道的自增ID
        custom_type: string; // 自定义类型
        custom_seq_id: number; // 自定义类型的自增ID
    }
    // 平台类型
    export enum Platform {
        UNKNOWN_PLATFORM = 0, // 未知
        ANDROID_PHONE = 1, // Android手机
        ANDROID_PAD = 2, // Android Pad
        IPHONE = 3, // iPhone
        IPAD = 4, // iPad
        WINDOWS_PC = 5, //Windows PC  例如直播伴侣windows客户端
        // 注: 同上
        ANDROID_PHONE_H5 = 6, // Android手机H5   app端内嵌h5页面
        IPHONE_H5 = 7, // iPhone H5  app端内嵌h5页面
        OUTSIDE_ANDROID_H5 = 8, // 端外android H5  非端内嵌页面，例如android手机浏览器
        OUTSIDE_IOS_H5 = 9, // 端外ios H5  非端内嵌页面，例如ios手机浏览器
        PC_WEB = 10, // pc端web页面  非端内嵌页面，例如A站web端、web直播端
    }
    // 容器类型
    export enum Container {
        NATIVE = 'NATIVE', // 端内
        REACT_NATIVE = 'REACT_NATIVE', // React Native 端
        H5 = 'H5', // 手机端
        MP_WECHAT = 'MP_WECHAT', // 微信小程序
        MP_ALIPAY = 'MP_ALIPAY', // 支付宝小程序
        MP_BAIDU = 'MP_BAIDU', // 百度小程序
        WEB = 'WEB', // 非以上，定义为 WEB
    }

    // 应用基础信息
    export interface AppPackage {
        product?: number; // 产品名称, 不同产品独立进行统计分析(为了灵活接入新app，废弃该参数，新接入app请上报到参数8)
        platform: Platform; // 平台
        language: string; // 语言, 例: zh-cn
        channel: string; // 用户来源渠道
        version_name: string; // 版本名
        version_code: number; // 版本号
        package_name: string; // 应用包名
        product_name: string; // 产品名称(为了避免不同app参数值冲突，新接入app请到埋点平台注册，具体咨询陈瑞娟)
        hotfix_patch_version?: string; // 热更新的patch版本号, 用于相同版本号但是不同Patch的分析
        container: Container; //和platform组合使用。platfrom标识平台，container标识应用运行载体. 取值如下，NATIVE、H5、小程序（MP_WECHAT、MP_ALIPAY、MP_BAIDU）
    }

    // 所有 Event 都包含的数据
    export interface CommonPackage {
        identity_package: IdentityPackage; // 用户和设置Id
        app_package: any; // 应用信息
        device_package: DevicePackage; // 设备基本信息
        network_package: NetworkPackage; // 网络信息
        location_package?: LocationPackage; // 地理位置信息
        experiment?: Experiment[]; // A/B测试实验
        sdk_version?: string; // logsdk版本号
        service_name?: string; // 中台服务名称，比如积木、im sdk等，如果业务log未使用中台服务，该字段置空
        safety_id?: string; // 防盗链id
        sub_biz?: string; // 多业务场景，同一中台可能存在多业务场景，比如评论sdk有 说说、作品评论等不同场景
        style_type?: string; // 客户端UI模式，比如滑滑版/极速版/普通版
        additional_seq_id_package?: AdditionalSeqIdPackage; // 客户端日志额外的sequenceId，用来验证不同发送通道、不同类型的日志的丢失率等。
        need_encrypt?: boolean; // 标记此条数据是否需要做加密处理
        global_attr?: string; // App内全局参数，json格式. 比如流量运营导流参数. 避免key冲突，key统一由埋点平台维护
        h5_extra_attr: {[key: string]: any}; // H5埋点专用扩展参数,json格式.
    }

    export enum PageType {
        UNKNOWN_PAGE_TYPE = 0, // 未知
        NATIVE = 1, // native页面
        H5 = 2, // h5页面
        MINA = 3, // 小程序页面
    }

    // 页面信息
    export interface UrlPackage {
        page?: string; // 一级页面。参考埋点需求填写即可
        params: string; // 参数列表参考埋点需求填写，并转成 json 字符串格式
        identity: string; // 页面唯一标识，使用UUID即可
        page_type?: PageType; // 应用页面类型
    }

    export enum ElementStatus {
        UNKNOWN_STATUS = 0, // 未知
        CHECKED = 1, // 选中
        UNCHECKED = 2, // 未选中
    }

    // 页面元素详细信息
    export interface ElementPackage {
        action?: string;
        action2?: string;
        index?: number; // 废弃 元素位置, 从0开始 (备注：element补充参数统一定义到params中)
        value?: number; // 废弃 元素的值, 用于求和, 求平均等 (备注：element补充参数统一定义到params中)
        status?: ElementStatus; // 废弃 元素状态 (备注：element补充参数统一定义到params中)
        params: string; // 参数列表参考埋点需求填写，并转成 json 字符串格式
    }

    // 页面曝光动作
    export enum PageShowAction {
        UNKNOWN_ACTION = 0, // 未知
        ENTER = 1, // 进入页面
        LEAVE = 2, // 离开页面
        RESUME = 3, // 恢复页面, 也可以认为是热加载, 这种情况下不需要重新渲染
    }

    export enum ActionStatus {
        UNKNOWN_STATUS = 0, // 未知
        SUCCESS = 1, // 成功
        FAIL = 2, // 失败
    }

    export enum ActionType {
        UNKNOWN_ACTION_TYPE = 0, // 未知
        CLICK = 1, // 点击进入或离开页面
        LEFT_PULL = 2, // 左滑进入或离开页面
        RIGHT_PULL = 3, // 右滑进入或离开页面
        UP_PULL = 4, // 上滑进入或者离开页面
        DOWN_PULL = 5, // 下滑进入或者离开页面
    }

    export enum SubAction {
        UNKNOWN_SUB_ACTION = 0, // 未知
        PAGE_ENTER = 1, // 进入页面
        PAGE_LEAVE = 2, // 离开页面 退出页面
        PAGE_RESUME = 3, // 恢复页面, 也可以认为是热加载, 这种情况下不需要重新渲染
        PAGE_PAUSE = 4, // 页面暂停，用户进入下一级页面，当前页面被覆盖的情况
    }

    // 操作方向
    export enum Direction {
        UNKNOWN2 = 0,
        UP = 1,
        DOWN = 2,
        LEFT = 3,
        RIGHT = 4,
    }

    export interface Event {
        url_package?: UrlPackage; // 事件发生页面
        refer_url_package?: UrlPackage; // 事件发生页面的来源页面
        element_package?: ElementPackage; // 触发事件发生的行为
        refer_element_package?: ElementPackage; // 触发事件发生的来源元素信息
    }

    // 曝光事件
    export interface ShowEvent extends Event {
        action?: PageShowAction; // 进入或离开
        type?: number; // 页面类型
        status: ActionStatus; // 展现状态, 成功或者失败
        action_type?: number; // 记录进入或离开页面的方式
        show_type?: number; // 记录进入或离开页面的方式
        time_cost?: number; // 页面加载, 刷新或离开时长, 单位: ms
        stay_length?: number; // 页面停留时长, 单位: ms
        url_package: UrlPackage; // 页面, 精确到页面级别, 控件级别的展现, 需填充 element_package
        sub_page?: boolean; // 是否包含子页面，true为包含，false为不包含，所有页面enter事件均上报，统计加载时长数据时使用。
        first_load?: boolean; // 记录是否是首个加载，true代表是，false代表不是，仅子页面加载事件中上报（非子页面不上报），统计加载时长数据时使用。
        sub_action: SubAction; // 页面进入或离开的类型细分
    }

    export enum TaskStatus {
        UNKNOWN_STATUS = 0,
        START = 1, // 开始
        RETRY = 2, // 重试
        PAUSE = 3, // 暂停
        RESUME = 4, // 恢复
        PENDING = 5, // 等待
        PROCESSING = 6, // 进行中
        SUCCESS = 7, // 成功
        FAIL = 8, // 失败
        CANCEL = 9, // 取消
        FINISH = 10, // 完成，比如直播结束的，长链接关闭
    }

    export enum OperationType {
        UNKNOWN_OPERATION = 0,
        CLICK = 1, // 点击
        DOUBLE_CLICK = 2, // 双击
        TRIPLE_CLICK = 3, // 三击
        LONG_PRESS = 4, // 长按
        PULL = 5, // 滑动
        DRAG = 6, // 拖拽
        SCALE = 7, // 双指缩放
        PULL_DOWN = 8, // 下拉刷新
        PULL_UP = 9, // 上拉加载更多
        RIGHT_CLICK = 10, // 鼠标右键点击
        AUTO = 10, // 自动
    }

    // 任务事件
    export interface TaskEvent extends Event {
        status: TaskStatus // 任务状态
        session_id?: string; // 任务的 session Id, 确保是全局唯一, 通常使用UUID即可, 连续 Action 相同的 task 保持一样的 session id
        operation_type?: OperationType; // 用户操作类型
        operation_direction?: Direction;
        url_package: UrlPackage; // Task发生页面
        element_package: ElementPackage; // 触发Task发生的行为
    }

    // 自定义弱类型事件
    export interface CustomEvent {
        key: string; // 自定义事件的key
        value: string; // 自定义事件的value
    }
}