import { Base } from '../proto/base';
import { ClientEvent as ClientEventV3 } from '../proto/v3/client_event';
import { ClientEvent as ClientEventV2 } from '../proto/v2/client_event';
import Global from '../config/global';
import { TaskEventOptions } from '../config/types';

export default (options: TaskEventOptions): ClientEventV3.EventPackage | ClientEventV2.EventPackage => {
    const {
        sessionId,
        currentUrlPackage,
        referUrlPackage,
        name,
        params,
        type,
        contentPackage,
        status,
        taskType,
        operationDirection,
    } = options;
    let url_package = currentUrlPackage;
    let refer_url_package = referUrlPackage;
    if (Global.isV2) {
        if (url_package) {
            // @ts-ignore
            url_package.page2 = url_package.page;
            delete url_package.page;
        }
        if (refer_url_package) {
            // @ts-ignore
            refer_url_package.page2 = refer_url_package.page;
            delete refer_url_package.page;
        }
    }
    const commonInfo = {
        url_package,
        refer_url_package,
        element_package: {
            [Global.isV2 ? 'action2': 'action']: name,
            params: JSON.stringify(params),
        },
        [Global.isV2 ? 'content_wrapper' : 'content_package']: contentPackage
    }
    if (Global.isV2) {
        // v2 click_event 是一个单独的事件类型，v3 则属于 task_event
        if (type === 'CLICK') {
            return {
                click_event: {
                    type: taskType && ClientEventV2.ClickType[taskType as keyof typeof ClientEventV2.ClickType] || ClientEventV2.ClickType.CLICK,
                    direction: operationDirection && Base.Direction[operationDirection] || Base.Direction.UNKNOWN2,
                    ...commonInfo,
                }
            }
        }
        return {
            task_event: {
                action2: type,
                status: status && Base.TaskStatus[status] || Base.TaskStatus.UNKNOWN_STATUS,
                ...commonInfo,
            }
        }
    }
    return {
        task_event: {
            type: taskType && ClientEventV3.TaskEventType[taskType as keyof typeof ClientEventV3.TaskEventType] || ClientEventV3.TaskEventType.USER_OPERATION,
            status: status && Base.TaskStatus[status] || Base.TaskStatus.UNKNOWN_STATUS,
            operation_type: Base.OperationType[type] || Base.OperationType.CLICK,
            operation_direction: operationDirection && Base.Direction[operationDirection] || Base.Direction.UNKNOWN2,
            session_id: sessionId,
            ...commonInfo
        }
    }
}