export function log(message: any) {
    if (console && console.log) {
        console.log(message);
    }
}

export function warn(message: any) {
    if (console && console.warn) {
        console.warn(message);
    }
}

export function error(message: any) {
    if (console && console.error) {
        console.error(message);
    }
}
