import md5 from 'blueimp-md5';
import { getCookie, setCookie } from './cookie';
/**
 * 生成nano id
 * @return {string} 16长度的字符串
 */
export function nanoId() {
    const ts = ((Math.random() * 1e9) >>> 0);
    const randomer = [];
    const seed = '0123456789ABCDEF';
    for (let i = 0; i < 7; i++) {
        randomer.push(seed.charAt(Math.random() * 16));
    }

    return ts + randomer.join('');
}
/**
 * 提取顶级域名
 * @param {?string=} hostname
 * @return {string} 返回顶级域名
 */
function resolveTopLevelDomain(hostname: string = typeof location !== 'undefined' ? location.hostname : '') {
    if (!hostname) {
        return '';
    }

    const ret = hostname.split('.');
    const len = ret.length;
    return (len <= 2 ? '' : `.${ret[len - 2]}.${ret[len - 1]}`);
}
let didKey: string = 'did';
let did: string = '';
/**
 * 生成did
 * @return {string}
 */
export function generateDID() {
    if (did) return did;
    try {
        // 兼容一下原下发的did逻辑，如果能从did获取，那么就走did
        // 如果不能，那就通过log sdk自行维护的_did来搞
        did = getCookie(didKey) || getCookie('_did');
        if (!did) {
            did = `web_${nanoId()}`;
            const expires = new Date();
            expires.setFullYear(expires.getFullYear() + 1);
            setCookie('_did', did, {
                expires,
                domain: resolveTopLevelDomain(),
                path: '/',
            });
        }
        return did;
    } catch (err) {
        return '';
    }
}
/**
 * 生成唯一UUID
 */
export const uuid = function (page: string) {
    const randomStr = page + new Date().getTime() + generateDID() + nanoId();
    const hash = md5(randomStr);

    return hash;
};