
/**
 * 全局配置，可用于所有模块中
 */
interface Config {
    proto: 'v2' | 'v3',
    sessionId: string;
    isV2: boolean;
    // 业务方采样配置
    sampled?: boolean;
    isBridge: boolean;
    isDebug: boolean;
    [key: string]: any;
};

const GlobalConfig:Config = {
    proto: 'v3',
    sessionId: '',
    isDebug: false,
    isBridge: false,
    get isV2() { return GlobalConfig.proto === 'v2' },
};

export const updateConfig = (key: string, value: any) => {
    GlobalConfig[key] = value;
};

export const updateConfigs = (configs: {[key: string]: any}) => {
    Object.assign(GlobalConfig, configs);
}

export default GlobalConfig;