export interface YodaInterface {
    isInYoda: boolean;
    ready: (callback?: () => void) => Promise<undefined>;
    register: (arg: { namespace: string; name: string; }) => void;
    init: () => void;
    [x: string]: any;
}

let _yoda: YodaInterface | null;
let yodaNamespace: string = 'tool';
let yodaResolve: Promise<any> | null = null;
export let yodaAlready = false;

/**
 * 检查 yoda 环境
 */
export const checkYoda = (yoda: YodaInterface | null = _yoda || typeof window !== 'undefined' && (window as any).yoda) => {
    if (_yoda && _yoda.isInYoda) return _yoda;
    if (yoda && yoda.isInYoda) {
        _yoda = yoda;
        return _yoda;
    }
    return null;
};

/**
 * 初始化 yoda
 * @param namespace 
 */
export const initYoda = async () => {
    if (!_yoda || yodaAlready) return;
    try {
        if (yodaResolve) {
            await yodaResolve;
        } else {
            yodaResolve = _yoda.ready();
            await yodaResolve;
            _yoda.register({
                namespace: 'webview',
                name: 'getPageLoadData',
            });
            _yoda.register({
                namespace: 'tool',
                name: 'setClientLog',
            });
            _yoda.register({
                namespace: 'tool',
                name: 'sendRadarLog',
            });
            _yoda.register({
                namespace: 'tool',
                name: 'getKswitchData',
            });
        }
        yodaResolve = null;
        yodaAlready = true;
    } catch (err) {
        console.log('initYoda err', err);
    }
}
/**
 * 判断是否支持桥接接口
 * @param name 
 * @param namespace 
 */
export const isSupportBridge = async (name: string = 'setClientLog', namespace: string = yodaNamespace) => {
    let isSupport = false;
    try {
        if (_yoda) {
            if (!yodaAlready) await initYoda();
            const { canUse } = await _yoda.tool.canIUse({
                namespace,
                name,
            });
            isSupport = canUse;
        }
    } catch (e) {}
    return isSupport;
}

/**
 * 获取 webview 信息
 */
export const getWebviewLoadPerf = async (options: any = {}) => {
    if (!_yoda) return;
    try {
        if (!yodaAlready) await initYoda();
        return await _yoda.webview['getPageLoadData'](options);
    } catch (err) {}
};
/**
 * 发送雷达事件
 */
export const sendRadarLog = async (options: any = {}) => {
    if (!_yoda) return;
    try {
        if (!yodaAlready) await initYoda();
        return await _yoda.tool['sendRadarLog'](options);
    } catch (err) {}
};
/**
 * 发送业务事件
 * @param type 
 * @param data 
 */
export const setClientLog = async (type: string, data: any) => {
    if (!_yoda) return;
    try {
        if (!yodaAlready) await initYoda();
        return await _yoda.tool['setClientLog']({
            type,
            data
        });
    } catch (err) {}
}

export type SamplingConfig = {
    // 页面路径下整体采样
    overall?: number;
    // 是否强制生效
    overwrite?: boolean;
    // 插件采样
    radar: {[key: string]: number};
};

export type KSwitchSamplingType = {
    [key: string]: SamplingConfig;
};

/**
 * 采样配置：
 * undefined 表示还未获取到配置；
 * null 表示没有配置
 */
export let KSwitchSampling: KSwitchSamplingType | null | undefined = undefined;
/**
 * 获取 Kswitch 采样配置
 */
export const getKSwitchSampling = async () => {
    if (!_yoda) {
        KSwitchSampling = null;
        return KSwitchSampling;
    }
    if (typeof KSwitchSampling !== 'undefined') return KSwitchSampling;
    try {
        if (!yodaAlready) await initYoda();
        const {canUse} = await _yoda.tool.canIUse({
            namespace: 'tool',
            name: 'getKswitchData'
        });
        if (!canUse) {
            KSwitchSampling = null;
            return KSwitchSampling;
        }
        const res = await _yoda.tool.getKswitchData({
            keys: [
                {
                    key: 'webloggerSampling'
                }
            ]
        });
        if (res && res.data) {
            let kswitchData = res.data[0];
            if (typeof kswitchData === 'string') {
                kswitchData = JSON.parse(kswitchData);
            }
            let data = kswitchData ? kswitchData.value : null;
            if (data) {
                if (typeof data === 'string') {
                    data = JSON.parse(data);
                }
                // 只取当前域名的采样配置
                const host = typeof location !== 'undefined' ? location.host : '*';
                for (const pathname in data) {
                    if (pathname === '*' || pathname.indexOf(host) !== -1) {
                        if (!KSwitchSampling) KSwitchSampling = {};
                        KSwitchSampling[pathname] = data[pathname];
                    }
                }
                if (KSwitchSampling) return KSwitchSampling;
            }
        }
    } catch (err) {}
    KSwitchSampling = null;
    return KSwitchSampling;
};


export const clearYoda = () => {
    _yoda = null;
    yodaAlready = false;
    KSwitchSampling = undefined;
};

let deviceInfo = null;
export const getDeviceInfo = async () => {
    if (!_yoda) return null;
    const enable = await isSupportBridge('getDeviceInfo', 'system');
    if (enable) {
        try {
            deviceInfo = await _yoda.system.getDeviceInfo();
            return deviceInfo;
        } catch (err) {
            return null;
        }
    }

}
let appInfo = null;
export const getAppInfo = async () => {
    if (!_yoda) return null;
    const enable = await isSupportBridge('getAppInfo', 'system');
    if (enable) {
        try {
            appInfo = await _yoda.system.getAppInfo();
            return appInfo;
        } catch (err) {
            return null;
        }
    }

}

export default {
    isSupportBridge,
    getWebviewLoadPerf,
    sendRadarLog,
    setClientLog,
    yodaAlready,   
}