
/**
 * @file webLogger工具函数
 * @author <zhangshen@kuaishou.com>
 * @author 天翔Skyline(skyline0705@gmail.com)
 */

// TODO后面这里面的东西要拆一下，不能全一口气扔在util下面
interface CookieOptions {
    expires?: Date;
    path?: string;
    domain?: string;
    secure?: boolean;
    raw?: boolean;
}

/**
 * 创建一个cookie字符串，可被赋值给document.cookie.
 * @param {string} name cookie名称.
 * @param {string} value cookie值.
 * @param {boolean} encode 是否对值进行编码.
 * @param {Object} options (Optional) 配置项.
 * @return {string} 返回 cookie 字符串.
 */
function createCookieString(
    name: string,
    value: string,
    encode: boolean,
    options: CookieOptions = {},
) {
    let text = `${encodeURIComponent(name)}=${(encode ? encodeURIComponent(value) : value)}`;
    const expires = options.expires;
    const path = options.path || '/';
    const domain = options.domain || '';

    if (expires instanceof Date) {
        text += `; expires=${expires.toUTCString()}`;
    }

    if (typeof expires === 'number') {
        text += `; max-age==${expires}`;
    }

    if (path !== '') {
        text += `; path=${path}`;
    }

    if (domain !== '') {
        text += `; domain=${domain}`;
    }

    if (options.secure === true) {
        text += '; secure';
    }

    return text;
}

/**
 * 将cookie序列化成对象.
 * @param {string} text cookie值.
 * @param {boolean=} shouldDecode cookie是否需要被解码. 默认为true.
 * @param {object=} options 如何读取cookie的配置.
 * @return {object} 可访问cookie的序列化结果
 */
export function parseCookieString(
    text: string,
    shouldDecode: boolean,
    options: any = {},
) {
    const cookies: { [key: string]: any } = {};

    if (text.length > 0) {
        const decodeValue = !shouldDecode
            ? function (s: string) {
                return s;
            }
            : decodeURIComponent;
        const cookieParts = text.split(/;\s/g);
        let cookieName = null;
        let cookieValue = null;
        let cookieNameValue = null;

        for (let i = 0, len = cookieParts.length; i < len; i++) {
            cookieNameValue = cookieParts[i].match(/([^=]+)=/i);
            if (cookieNameValue !== null) {
                try {
                    cookieName = decodeURIComponent(cookieNameValue[1]);
                    cookieValue = decodeValue(cookieParts[i].substring(cookieNameValue[1].length + 1));
                } catch (ex) {
                    // 忽略
                }
            } else {
                // cookie 中没有 "=", 当成布尔值
                cookieName = decodeURIComponent(cookieParts[i]);
                cookieValue = '';
            }
            if (cookieName !== null) {
                // 默认后面的cookie覆盖前面的
                cookies[cookieName] = cookieValue;
            }
        }

    }

    return cookies;
}

let cookiesMap: {[key: string]: any} = {};
/**
 * 获取cookie值.
 * @param {string} name cookie名称.
 * @param {object=} options 配置项
 *                    raw: 是否进行编码，true则是未编码
 * @return {*} 不存在返回null.
 */
export function getCookie(name: string, options: CookieOptions = {}, useCaches = false) {
    try {
        if (typeof document === 'undefined' || !document.cookie) {
            return null;
        }
        if (useCaches && name in cookiesMap) {
            return cookiesMap[name];
        }
        const cookies = parseCookieString(document.cookie, !options.raw, options);
        cookiesMap = cookies;
        const cookie = cookies[name];
        if (cookie === void 0) {
            return null;
        }

        return cookie;
    } catch (err) {
        return null;
    }
}

/**
 * 给定的键值设置cookie
 * @param {string} name 名称.
 * @param {*} value cookie值.
 * @param {object=} options (Optional) 配置项:
 *      path 路径
 *      domain 域名
 *      expires 过期时间，一个Date对象
 *      secure 是否安全cookie
 *      raw (true/false). Setting raw to true indicates that the cookie should not be URI encoded before being set.
 */
export function setCookie(name: string, value: string, options: CookieOptions = {}) {
    try {
        document.cookie = createCookieString(name, value, !options.raw, options);
        return true;
    } catch (err) {
        return false;
    }
}

export default {
    getCookie,
    setCookie,
    parseCookieString
};