import { getLocalStorageItem, setLocalStorageItem } from '../util';
// 常规业务事件自增 id key
const LOCAL_INCREAMENT_ID_KEY = 'WEBLOGGER_INCREAMENT_ID_KEY';
// 自定义事件自增 id key
const LOCAL_CUSTOM_INCREAMENT_ID_KEY = 'WEBLOGGER_CUSTOM_INCREAMENT_ID_KEY';
// Bridge 常规事件自增 id 本地缓存 key
const H5_SEQ_ID_KEY = 'WEBLOGGER_H5_SEQ_ID';
// Bridge 自定义事件自增 id 本地缓存 key
const H5_CUSTOM_SEQ_ID_KEY = 'WEBLOGGER_H5_CUSTOM_SEQ_ID';
// http 请求自增 id 本地缓存 key
const LOCAL_HTTP_INCREMENT_ID_KEY = 'WEBLOGGER_HTTP_SEQ_ID';
// v2 自增 id 本地缓存 key
const V2_INCREMENT_ID_KEY = 'WEBLOGGER_V2_SEQ_ID';
const CHANNEL_INCREMENT_ID_KEY = 'WEBLOGGER_CHANNEL_SEQ_ID';
// 自增 id 最大值
const maxId = 1e8;

// 如果没有 localStorage 则使用运行时自增 id，用于非浏览器场景，比如 React Native
const runtimeIdMap:{[key: string]: number} = {};

const genId = (key: string) => {
    try {
        if (window && window.localStorage && typeof Storage !== 'undefined' && window.localStorage instanceof Storage) {
            let id = Number(getLocalStorageItem(key)) || 0;
            if (id + 1 > maxId) {
                id = 0;
            }
            setLocalStorageItem(key, id + 1);
            return id;
        }
    } catch (err) {};
    if (!runtimeIdMap[key]) {
        runtimeIdMap[key] = 0;
    }
    return runtimeIdMap[key] ++;
}
/**
 * 常规事件自增 id
 */
export const getNomalIncrementId = () => {
    return genId(LOCAL_INCREAMENT_ID_KEY);
};
/**
 * 自定义事件自增 id
 */
export const getCustomIncrementId = () => {
    return genId(LOCAL_CUSTOM_INCREAMENT_ID_KEY);
};
/**
 * 桥接常规事件自增 id
 */
export const getBridgeNormalIncrementId = () => {
    return genId(H5_SEQ_ID_KEY);
};
/**
 * 桥接自定义事件自增 id
 */
export const getBridgeCustomIncrementId = () => {
    return genId(H5_CUSTOM_SEQ_ID_KEY);
};
/**
 * http 请求自增 id
 */
export const getHttpIncrementId = () => {
    return genId(LOCAL_HTTP_INCREMENT_ID_KEY);
};
/**
 * v2 channel 自增 id
 */
export const getChannelIncrementId = (channel: string = '') => {
    return genId(`${CHANNEL_INCREMENT_ID_KEY}_${channel}`);
};
/**
 * v2 事件自增 id
 */
export const getV2EventIncrementId = (event: string) => {
    return genId(`${V2_INCREMENT_ID_KEY}_${event}`);
};