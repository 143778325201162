import {
    PerformanceTimingProperty,
    radarWebviewTimeingProperty,
} from '../plugins/types';
const perf = typeof window !== 'undefined'
    ? window.performance
    : null;

const performanceTiming: PerformanceTiming | PerformanceTimingProperty | null = perf && perf.timing || null;

const performanceNavigationTiming: PerformanceNavigationTiming | null = perf
    && perf.getEntriesByType
    && perf.getEntriesByType('navigation')[0] as PerformanceNavigationTiming
    || null;

// TODO下面这个暂时不能这么用，因为计量方式和perfirmanceTiming不一样
// export default performanceNavigationTiming || performanceTiming || null;
export default performanceTiming as (PerformanceTiming & PerformanceTimingProperty);
