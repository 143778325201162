import { Base } from '../base';

export namespace ClientEvent {
    // 页面元素详细信息
    export interface ElementPackage extends Base.ElementPackage {
        action?: string; // 元素业务类型。参考埋点需求填写即可
    }

    // 页面信息
    export interface UrlPackage extends Base.UrlPackage {
        page: string; // 一级页面。参考埋点需求填写即可
    }

    // 曝光类型
    export enum ShowType {
        UNKNOWN_TYPE = 0, // 未知
        PAGE_AUTO = 10, // sdk自动采集页面
        PAGE_CUSTOM = 11, // 自定义页面
        ELEMENT = 12, // 自定义元素
    }

    // 曝光事件
    export interface ShowEvent extends Base.ShowEvent {
        type?: ShowType;
        element_package?: ElementPackage;
        content_package?: string; // 相关内容。具体项目把具体业务要上报的参数转成 json 字符串格式
    }

    export enum TaskEventType {
        UNKNOWN_TYPE = 0,
        USER_OPERATION = 1, // 用户操作
        STAY_LENGTH_STAT_EVENT = 2, // 非页面级别的停留时长统计事件，比如不同tab的停留时长
        BACKGROUND_TASK_EVENT = 3, // 记录客户端发起的后台请求任务
    }
    // 任务事件
    export interface TaskEvent extends Base.TaskEvent {
        type: TaskEventType;
        content_package?: string; // 相关内容。具体项目把具体业务要上报的参数转成 json 字符串格式
    }

    export interface CustomEvent {
        custom_stat_event: Base.CustomEvent;
    }

    export interface EventPackage {
        show_event?: ShowEvent;
        task_event?: TaskEvent;
        stat_package?: CustomEvent;
    }
}