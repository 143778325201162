import { Base } from '../proto/base';
import { ClientEvent as ClientEventV3 } from '../proto/v3/client_event';
import { ClientEvent as ClientEventV2} from '../proto/v2/client_event';

import { ShowEventOptions } from '../config/types';
import Global from '../config/global';

let firstLoad: boolean | undefined = true;
let enterTime: number = new Date().valueOf();
export default (options: ShowEventOptions): ClientEventV3.EventPackage | ClientEventV2.EventPackage => {
    const {
        type,
        currentUrlPackage,
        referUrlPackage,
        name = '',
        action,
        beginTime,
        params = {},
        contentPackage,
        status,
        actionType, // 这值目前似乎没传进来
        auto
    } = options;
    let url_package = currentUrlPackage;
    let refer_url_package = referUrlPackage;
    if (Global.isV2) {
        if (url_package) {
            // @ts-ignore
            url_package.page2 = url_package.page;
            delete url_package.page;
        }
        if (refer_url_package) {
            // @ts-ignore
            refer_url_package.page2 = refer_url_package.page;
            delete refer_url_package.page;
        }
    }
    const commonInfo = {
        status: status ? (Base.ActionStatus[status] || Base.ActionStatus.UNKNOWN_STATUS) : Base.ActionStatus.SUCCESS,
        [Global.isV2 ? 'show_type' : 'action_type']: actionType ? (Base.ActionType[actionType] || Base.ActionType.UNKNOWN_ACTION_TYPE) : Base.ActionType.CLICK,
        url_package,
        refer_url_package,
        [Global.isV2 ? 'content_wrapper' : 'content_package']: contentPackage,
    };
    if (type === 'PV') {
        let sub_action = Base.SubAction.PAGE_ENTER;
        let actionPB = Base.PageShowAction.ENTER;
        let first_load = firstLoad;
        let stay_length = 0;
        firstLoad = false;
        switch (action) {
            case 'leave':
                actionPB = Base.PageShowAction.LEAVE;
                sub_action = Base.SubAction.PAGE_LEAVE;
                stay_length = new Date().valueOf() - (beginTime || enterTime);
                break;
            case 'enter':
                actionPB = Base.PageShowAction.ENTER;
                sub_action = Base.SubAction.PAGE_ENTER;
                enterTime = new Date().valueOf();
                break;
            case 'visible':
                actionPB = Base.PageShowAction.RESUME;
                sub_action = Base.SubAction.PAGE_RESUME;
                enterTime = new Date().valueOf();
                break;
            case 'hidden':
                actionPB = Base.PageShowAction.LEAVE;
                sub_action = Base.SubAction.PAGE_PAUSE;
                stay_length = new Date().valueOf() - (beginTime || enterTime);
                break;
        }
        let showType: ClientEventV3.ShowType | ClientEventV2.ShowType = auto ? ClientEventV3.ShowType.PAGE_AUTO : ClientEventV3.ShowType.PAGE_CUSTOM;
        if (Global.isV2) {
            first_load = undefined;
            showType = ClientEventV2.ShowType.PAGE;
        }
        return {
            show_event: {
                action: actionPB,
                sub_action,
                type: showType,
                first_load,
                time_cost: 0,
                stay_length,
                ...commonInfo
            }
        } as ClientEventV3.EventPackage | ClientEventV2.EventPackage
    }
    return {
        show_event: {
            action: Base.PageShowAction.ENTER,
            // @ts-ignore
            type: Global.isV2 ? undefined : ClientEventV3.ShowType.ELEMENT,
            sub_action: Base.SubAction.PAGE_ENTER,
            element_package: {
                [Global.isV2 ? 'action2': 'action']: name,
                params: JSON.stringify(params),
            },
            ...commonInfo
        }
    }
}