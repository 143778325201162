import { PerfMap, PerfName, DOMPerf, ResPerf, APIPerf, webviewPerf, radarWebviewPerf, EntryTypes, InitiatorTypes, radarCustomPerf } from './interface';
import navigationTiming from './timing';

export const ENTRY_TYPES: EntryTypes[] = ['resource', 'navigation'];
export const INITI_TYPES: string[] = ['css', 'fetch', 'img', 'script', 'xmlhttprequest', 'link'];
export const API_TYPES: string[] = ['fetch', 'xmlhttprequest'];

export const LEGACY_KEY = 'fe_radar';
// 统一的 custom_key
export const KEY = 'radar_log';

export enum RADAR_KEY {
    // 页面加载监控
    LOAD = 'load',
    // 静态资源监控
    RES = 'resource',
    // API 性能监控
    API = 'api',
    // 异常
    ERROR = 'error',
    // 脚本自身上报异常
    SDK_ERROR = 'sdk_error',
    // 用户自定义性能监控
    CUSTOM = 'custom',
    // 批量日志，用于处理批量情况
    BATCH = 'batch',
    // 事件
    EVENT = 'event',
}

export enum LEGACY_RADAR_KEY {
    // 页面加载监控
    LOAD = 'load_statistics',
    // 静态资源监控
    RES = 'resource_statistics',
    // API 性能监控
    API = 'api_statistics',
    // 异常
    ERROR = 'error_statistics',
    // 脚本自身上报异常
    SDK_ERROR = 'sdk_error',
    // 用户自定义性能监控
    CUSTOM = 'custom_statistics',
}

export enum ERROR_KEY {
    // 脚本异常
    SCRIPT = 'script',
    // 资源加载异常
    RES = 'res',
    // 接口请求异常
    API = 'api',
    // 视频异常
    VIDEO = 'video',
}

export const perfNameList: Array<PerfName<DOMPerf>> = [
    'dns',
    'tcp',
    'ssl',
    'ttfb',
    'trans',
    'dom_parse',
    'blank',
    'fb',
    'dom_ready',
    'total',
    'resource',
    'fp',
    'tti',
    'js_cost',
];

export const domPerfMap: PerfMap<DOMPerf> = {
    redirect: {
        end: 'fetchStart',
        start: 'navigationStart',
    },
    // DNS查找时间
    dns: {
        end: 'domainLookupEnd',
        start: 'domainLookupStart',
    },
    dns_start: {
        end: 'domainLookupEnd',
        start: 'navigationStart',
    },
    // TCP建连时间
    tcp: {
        end: 'connectEnd',
        start: 'connectStart',
    },
    // TCP建连时间
    tcp_start: {
        end: 'connectEnd',
        start: 'navigationStart',
    },
    redirect_count: {
        custom(key, perf: PerformanceResourceTiming) {
            // @ts-ignore
            return perf.redirectCount;
        },
    },
    // ssl 安全连接耗时
    ssl: {
        // 有可能不是 https，导致这里上报异常
        custom(key, perf: PerformanceResourceTiming) {
            if (perf.secureConnectionStart === 0) return null;
            return perf.connectEnd - perf.secureConnectionStart;
        },
    },
    ssl_start: {
        // 有可能不是 https，导致这里上报异常
        custom(key, perf: PerformanceResourceTiming) {
            if (perf.secureConnectionStart === 0) return null;
            return perf.secureConnectionStart - perf.redirectStart;
        },
    },
    // 首字节事件，Time to First Byte
    ttfb: {
        end: 'responseStart',
        start: 'requestStart',
    },
    // 数据传输耗时
    trans: {
        end: 'responseEnd',
        start: 'responseStart',
    },
    // DOM 解析耗时
    dom_parse: {
        end: 'domInteractive',
        start: 'responseEnd',
    },
    dom_parse_start: {
        end: 'domInteractive',
        start: 'navigationStart',
    },
    // 白屏耗时
    // （(domInteractive || domLoading) - fetchStart）
    blank: {
        end: 'domLoading',
        start: 'navigationStart',
    },
    // 首字节
    fb: {
        end: 'responseStart',
        start: 'navigationStart',
    },
    // DOM-ready
    dom_ready: {
        end: 'domContentLoadedEventEnd',
        start: 'navigationStart',
    },
    // 完整 loading 时间
    total: {
        end: 'loadEventStart',
        start: 'navigationStart',
    },
    // 页面中的同步加载耗时
    resource: {
        end: 'loadEventStart',
        start: 'domContentLoadedEventEnd',
    },
    // 首次渲染，可以用 entries 里面的 first_paint
    fp: {
        custom() {
            if (typeof performance.getEntries !== 'function') {
                return null;
            }
            const entries = performance.getEntries();
            const firstPaintInfo = entries
                .filter(item => item.name === 'first-paint')[0];
            if (firstPaintInfo) {
                return Math.ceil(firstPaintInfo.startTime + firstPaintInfo.duration);
            }
            return null;
        },
    },
    // 首屏JS耗时……暂时先这么对付了，具体JS的耗时后面再提供吧
    // 计算的时候以第一个script开始时间点为起始，一直到DOM Complete完成
    // 这样看起来应该是可被定义为JS耗时了……
    js_cost: {
        custom() {
            if (typeof performance.getEntries !== 'function') {
                return null;
            }

            const timing = navigationTiming();
            const entries = performance.getEntries();
            const pageTimeInfo = performance.getEntries()
                .filter(item => item.initiatorType === 'navigation')[0];
            const firstJSTimeInfo = entries
                .filter(item => item.initiatorType === 'script')[0];

            // 有的浏览器 getEntries 里没有 navigation
            const domComplete = pageTimeInfo ? pageTimeInfo.domComplete :
                timing.domComplete - timing.navigationStart;

            if (firstJSTimeInfo && domComplete) {
                return domComplete - firstJSTimeInfo.fetchStart;
            }
            return null;
        },
    },
    // 可交互时间，这个不确定，粗略加个默认的，让用户可以自定义
    tti: {
        end: 'domInteractive',
        start: 'navigationStart',
    },
    // 这里 load performance 如果可以的话也能拿到
    protocol: {
        custom(key, perf: PerformanceResourceTiming) {
            return perf.nextHopProtocol;
        },
    },
    dom_num: {
        custom() {
            return typeof document !== 'undefined' ? document.all.length : null;
        }
    }
};
export const apiPerfMap: PerfMap<APIPerf> = {
    // fetchStart，记录资源的 fetchStart，记录其跟页面加载的偏移量
    fetch_start: {
        custom(key, perf: PerformanceResourceTiming) {
            return Math.round(perf.fetchStart);
        },
    },
    // DNS查找时间
    dns: {
        end: 'domainLookupEnd',
        start: 'domainLookupStart',
        cachedSkip: true,
    },
    // TCP建连时间
    tcp: {
        end: 'connectEnd',
        start: 'connectStart',
        cachedSkip: true,
    },
    // ssl 安全连接耗时
    ssl: {
        cachedSkip: true,
        // 有可能不是 https，导致这里上报异常
        custom(key, perf: PerformanceResourceTiming) {
            if (perf.secureConnectionStart === 0) return null;
            return perf.connectEnd - perf.secureConnectionStart;
        },
    },
    // 首字节事件，Time to First Byte
    ttfb: {
        end: 'responseStart',
        start: 'requestStart',
        cachedSkip: true,
    },
    // 数据传输耗时
    trans: {
        end: 'responseEnd',
        start: 'responseStart',
        cachedSkip: true,
    },
    // 首字节
    fb: {
        end: 'responseStart',
        start: 'fetchStart',
        cachedSkip: true,
    },
    total: {
        end: 'responseEnd',
        start: 'fetchStart',
    },
    // 计算逻辑与total一致
    duration: {
        end: 'responseEnd',
        start: 'fetchStart',
    },
    // 体积
    size: {
        cachedSkip: true,
        custom(key, perf: PerformanceResourceTiming) {
            return perf.encodedBodySize;
        },
        noThrottle: true,
    },
    // 首次渲染，可以用 entries 里面的 first_paint
    protocol: {
        custom(key, perf: PerformanceResourceTiming) {
            return perf.nextHopProtocol;
        },
    },
    // file 静态资源地址
    file: {
        custom(key, perf: PerformanceResourceTiming) {
            return perf.name;
        },
        noThrottle: true,
    },
    res_type: {
        custom(key, perf: PerformanceResourceTiming) {
            return perf.initiatorType;
        },
    },
    // 是否缓存
    cached: {
        custom(key, perf: PerformanceResourceTiming) {
            if (perf.transferSize === 0) return true;
            // safari 没有实现 transferSize，因此用 domainLookupStart 来判断
            if (perf.transferSize == null && perf.domainLookupStart === 0) return true;
            return false;
        },
        noThrottle: true,
    }
};

export const resPerfMap: PerfMap<ResPerf> = {
    // fetchStart，记录资源的 fetchStart，记录其跟页面加载的偏移量
    fetch_start: {
        custom(key, perf: PerformanceResourceTiming) {
            return Math.round(perf.fetchStart);
        },
        noThrottle: true,
    },
    // DNS查找时间
    dns: {
        end: 'domainLookupEnd',
        start: 'domainLookupStart',
        cachedSkip: true,
    },
    // TCP建连时间
    tcp: {
        end: 'connectEnd',
        start: 'connectStart',
        cachedSkip: true,
    },
    // ssl 安全连接耗时
    ssl: {
        cachedSkip: true,
        // 有可能不是 https，导致这里上报异常
        custom(key, perf: PerformanceResourceTiming) {
            if (perf.secureConnectionStart === 0) return null;
            return perf.connectEnd - perf.secureConnectionStart;
        },
    },
    // 首字节事件，Time to First Byte
    ttfb: {
        end: 'responseStart',
        start: 'requestStart',
        cachedSkip: true,
    },
    // 数据传输耗时
    trans: {
        end: 'responseEnd',
        start: 'responseStart',
        cachedSkip: true,
    },
    // 首字节
    fb: {
        end: 'responseStart',
        start: 'fetchStart',
        cachedSkip: true,
    },
    total: {
        end: 'responseEnd',
        start: 'fetchStart',
    },
    // 计算逻辑与total一致
    duration: {
        end: 'responseEnd',
        start: 'fetchStart',
    },
    // 体积
    size: {
        cachedSkip: true,
        custom(key, perf: PerformanceResourceTiming) {
            return perf.encodedBodySize;
        },
        noThrottle: true,
    },
    // 首次渲染，可以用 entries 里面的 first_paint
    protocol: {
        custom(key, perf: PerformanceResourceTiming) {
            return perf.nextHopProtocol;
        },
    },
    // file 静态资源地址
    file: {
        custom(key, perf: PerformanceResourceTiming) {
            return perf.name;
        },
        noThrottle: true,
    },
    res_type: {
        custom(key, perf: PerformanceResourceTiming) {
            return perf.initiatorType;
        },
    },
    // 是否缓存
    cached: {
        custom(key, perf: PerformanceResourceTiming) {
            if (perf.transferSize === 0) return true;
            // safari 没有实现 transferSize，因此用 domainLookupStart 来判断
            if (perf.transferSize == null && perf.domainLookupStart === 0) return true;
            return false;
        },
        noThrottle: true,
    }
};

export const DEFAULT_IGNORE_LIST = [
    '//wlog.kuaishou.com/rest/n/log/web/collect',
    // v3
    '/rest/wd/common/log/collect/misc2',
    // v3 radar
    '/rest/wd/common/log/collect/radar',
    // v2
    '/rest/kd/log/collect',
];

// userStart: 1603074007776, // 外部传入打点WebView初始时间戳，比如调用JSBridge时间戳，客户端入口点击时间戳等。
// 	pageStart: 1603074007776, // Yoda收到任务的起始时间戳
// 	pageShow: 1603074007776, // Loading图显示时间
// 	preCreate: 1603074007776, // Yoda即将启动创建WebView实例的时间戳
// 	created: 1603074007776, // WebView对象创建完毕时间戳
// 	startLoad: 1603074007776, // WebView开始加载URL时间戳
// 	didStartLoad: 1603074007776, // WebView 加载URL后首个回调时间戳
// 	didEndLoad: 1603074007776, // WebView加载完毕时间戳

export const webviewPerfMap: PerfMap<radarWebviewPerf> = {
    to_webview_page_start: {
        end: 'pageStart',
        start: 'userStart',
    },
    to_webview_page_show: {
        end: 'pageShow',
        start: 'userStart',
    },
    to_webview_pre_create: {
        end: 'preCreate',
        start: 'userStart',
    },
    to_webview_created: {
        end: 'created',
        start: 'userStart',
    },
    to_webview_start_load: {
        end: 'startLoad',
        start: 'userStart',
    },
    to_webview_did_start_load: {
        end: 'didStartLoad',
        start: 'userStart',
    },
    to_webview_did_end_load: {
        end: 'didEndLoad',
        start: 'userStart',
    },
    to_navigation_start: {
        end: 'navigationStart',
        start: 'userStart',
    },
    to_fetch_start: {
        end: 'fetchStart',
        start: 'userStart',
    },
    to_connect_start: {
        end: 'connectStart',
        start: 'userStart',
    },
    to_dom_ready: {
        end: 'domContentLoadedEventEnd',
        start: 'userStart',
    },
    to_total: {
        end: 'loadEventStart',
        start: 'userStart',
    },
    to_fmp: {
        end: 'radarFmp',
        start: 'userStart'
    }
};
// load信息也需要上报时间戳了 这里提供一个rename的map
export const loadTimestamp = {
    navigation_start_time: 'navigationStart',
    fetch_start_time: 'fetchStart',
    dns_start_time: 'domainLookupStart',
    dns_end_time: 'domainLookupEnd',
    connect_start_time: 'connect_start',
    ssl_start_time: 'secureConnectionStart',
    request_start_time: 'requestStart',
    response_start_time: 'responseStart',
    response_end_time: 'responseEnd',
    dom_interactive_time: 'domInteractive',
    dom_loading_time: 'domLoading',
    dom_ready_time: 'domContentLoadedEventEnd',
    load_event_time: 'loadEventStart',
    fmp_time: 'radarFmp'
};