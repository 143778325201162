/**
 * @file 对于每个 Plugin 的共性，使用一个抽象类来描述
 */

import { RadarMonitor, CustomEntry, lcpEntry } from './interface';

export default abstract class RadarPlugin {
    constructor(protected radar: RadarMonitor, protected options: any) { }
    abstract key: string;

    abstract created(): void;
    abstract destroy(): void;
    abstract when(perf: PerformanceEntry | CustomEntry | lcpEntry | PerformanceEntry[], batch?: boolean): boolean;
    abstract onPerfReport(perf: PerformanceEntry | CustomEntry | PerformanceEntry[], batch?: boolean): void;
}

export type RadarPluginConstructor<T> = new (radar: RadarMonitor, options?: T) => any;
