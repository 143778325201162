import { RADAR_KEY, ERROR_KEY } from './const';
import { ErrorLog, CustomEntry, KVPair } from './interface';
import { addMonitor, removeMonitor, composedPath } from '../../util';
import RadarPlugin from './plugin';

export default class ErrorMonitor extends RadarPlugin {
    public key = 'error';
    // 用于存储页面报错信息，去重，合并上报
    public errorQueue: ErrorLog[] = [];
    public errorTimeInterval = 2000;
    // error 监听不响应 performance.observe
    when(entry: PerformanceEntry | CustomEntry) {
        // 接受 fmp/play_clicked/play_idr 三个自定义指标
        if ((entry as CustomEntry).custom) {
            const { key } = (entry as CustomEntry).entry || {};
            if (['play_block', 'play_error'].indexOf(key) > -1) {
                return true;
            }
        }
        return false;
    }

    // error 监听不响应 performance.observe
    onPerfReport(entry: CustomEntry) {
        // Nothing
        if ((entry as CustomEntry).custom) {
            const { key, value } = (entry as CustomEntry).entry || {};
            this.collect({
                error_type: ERROR_KEY.VIDEO,
                error_cons_type: key,
                msg: (value as string),
            });
        }
    }

    private sepDimension(kv: KVPair) {
        const { key } = kv;
        if (key === 'error') {
            const { file, error_cons_type, error_type, msg, col, line, stack, ...value } = kv.value;
            const dimension = { error_cons_type, file, error_type, msg, col, line, stack };
            return { key, value, dimension };
        }

        // 资源异常的处理
        const { protocol, file, cached, res_path, failed, res_type, ...value } = kv.value;
        const dimension = { protocol, file, cached, failed, res_path, res_type };
        return { key, value, dimension };
    }

    private collect(logInfo: ErrorLog) {
        this.radar.logCollect(this.sepDimension({
            key: RADAR_KEY.ERROR,
            value: logInfo,
        }));
    }

    // 劫持 window onerror
    private isScriptError(e: ErrorEvent) {
        // 有 message 基本上认为是脚本异常了
        return e.message || e.lineno != null;
    }
    private isSameErrorAndReport(error: ErrorLog) {
        /**
         * collect之前进行筛选
         * 策略是：相同的错误短时间内多次触发算作一条日志
         * 一般认为 同一文件，同一行，同一列的报错为同一错误 不重复收集
         * 
         */
        // 判断是否是同一错误
        // const index = this.errorQueue.findIndex(e => {
        //     const sameError = e.file === error.file && e.line === error.line && e.col === error.col
        //     return sameError
        // })

        if (this.getSameErrorIndex(error) >= 0) { return; } // 是同一错误则不进行收集
        this.errorQueue.push(error);
        this.collect(error);
        setTimeout(() => {
            // 超过阙值则重置
            // 重新计算他在队列中的位置
            this.errorQueue.splice(this.getSameErrorIndex(error), 1);
        }, this.errorTimeInterval);
    }
    /**
     * 
     * @param error 错误信息
     * @returns 
     */
    private getSameErrorIndex(error: ErrorLog) {
        return this.errorQueue.findIndex(e => {
            const sameError = e.file === error.file && e.line === error.line && e.col === error.col;
            return sameError;
        });
    }
    // 劫持 window.addEventListener('error')
    private onResError = (e: ErrorEvent & PromiseRejectionEvent) => {
        const isScriptError = this.isScriptError(e);
        const error = (e as ErrorEvent).error;

        if (isScriptError) {
            // 脚本报错
            const logInfo: ErrorLog = {
                error_type: ERROR_KEY.SCRIPT,
                error_cons_type: e.type,
                msg: e.message || e.reason,
                stack: error ? error.stack : null,
                file: e.filename,
                line: e.lineno,
                col: e.colno,
            };
            this.isSameErrorAndReport(logInfo);
        }
    };

    private onPromiseError = (e: PromiseRejectionEvent) => {
        const msg = e.reason && e.reason.message || '';

        const logInfo: ErrorLog = {
            error_type: ERROR_KEY.SCRIPT,
            error_cons_type: e.type,
            msg,
        };

        this.collect(logInfo);
    };

    created() {
        // 这里会上报重复，感觉没必要
        // window.onerror = (...args) => {
        //     if (typeof this.preWindowErrorHandler === 'function') {
        //         this.preWindowErrorHandler(...args);
        //     }
        //     this.onError(...args);
        // };

        addMonitor(window, 'error', this.onResError as EventListener, true);
        addMonitor(window, 'unhandledrejection', this.onPromiseError as EventListener);
    }

    destroy() {
        // window.onerror = this.preWindowErrorHandler;
        removeMonitor(window, 'error', this.onResError as EventListener, true);
        removeMonitor(window, 'unhandledrejection', this.onPromiseError as EventListener);
    }
}
