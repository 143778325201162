import { StatusTypes, TaskEventTypeStrings, OperationDirectionTypes, TaskStatusTypes, TaskEventOperationTypes } from '../config/types';

interface UrlPage {
    page?: string; // url_package.page 当前 page 的 name
    identity?: string;
}

/**
 * PV接口入参
 */
export interface CurrentPageOptions {
    page: string;
    identity: string;
    pageType: string;
    actionType: 'enter' | 'leave' | 'hidden' | 'visible';
    params?: any;
    contentPackage?: any;
    status?: StatusTypes;
    serviceName?: string;
    subBiz?: string;
    beginTime?: number;
    realtime?: boolean;
    h5ExtraAttr?: string;
    container?: string;
    eventId?: string;
    needEncrypt?: boolean;
}

/**
 * 元素曝光接口入参
 */
export interface ElementShowEventOptions {
    action: string;
    params?: any;
    contentPackage?: any;
    serviceName?: string;
    subBiz?: string;
    needEncrypt?: boolean;
    realtime?: boolean;
    h5ExtraAttr?: string;
    container?: string;
    eventId?: string;
    urlPage?: UrlPage;
}

/**
 * Task事件接口入参
 */
export interface TaskEventOptions {
    action: string;
    params?: any;
    contentPackage?: any;
    type: TaskEventTypeStrings;
    status?: TaskStatusTypes;
    operationType?: TaskEventOperationTypes;
    operationDirection?: OperationDirectionTypes;
    sessionId?: string;
    serviceName?: string;
    subBiz?: string;
    needEncrypt?: boolean;
    realtime?: boolean;
    h5ExtraAttr?: string;
    container?: string;
    eventId?: string;
    urlPage?: UrlPage;
}

/**
 * 自定义事件
 */
export interface CustomStatEventOptions {
    key: string;
    value?: string;
    serviceName?: string;
    subBiz?: string;
    needEncrypt?: boolean;
    realtime?: boolean;
    h5ExtraAttr?: string;
    container?: string;
    eventId?: string;
    urlPage?: UrlPage;
}

/**
 * 异常事件
 */
export interface ExceptionEventOptions {
    type: string;
    message: string;
    serviceName?: string;
    subBiz?: string;
    needEncrypt?: boolean;
    realtime?: boolean;
    h5ExtraAttr?: string;
    container?: string;
    eventId?: string;
}

/**
 * 自定义PB事件
 */
export interface CustomProtoOptions {
    type: string;
    payload: string;
    serviceName?: string;
    subBiz?: string;
    needEncrypt?: boolean;
    realtime?: boolean;
    h5ExtraAttr?: string;
    container?: string;
    eventId?: string;
}

/**
 * 用于日志合并的 radar yoda 事件
 */
export interface SendRadarLogOptions {
    removeStashedLog: string[];
    sendImmediate: boolean;
    customData: {
        h5ExtraAttr: any;
        project_id: string;
        data: any[];
    };
}

/**
 * bridge接口返回数据结构
 */
export interface ApiResult {
    result?: number;
    message?: string;
    logId?: string;
    timeData?: any;
}

/**
 * bridge实现方法
 */
export const BRIDGE_API = 'setClientLog';
export const PAGELOG_API = 'sendRadarLog';
export const GET_WEBVIEW_PERF = 'getPageLoadData';

/**
 * 入参类型
 */
export type optionTypes = CurrentPageOptions | TaskEventOptions | CustomStatEventOptions
    | ExceptionEventOptions | CustomProtoOptions | ElementShowEventOptions | SendRadarLogOptions | {};

/**
 * 必须实现的接口
 */
export interface IYodaBridge {
    setCurrentPage(options: CurrentPageOptions): void;
    addElementShowEvent(options: ElementShowEventOptions): void;
    addTaskEvent(options: TaskEventOptions): void;
    addExceptionEvent(options: ExceptionEventOptions): void;
    addCustomProtoEvent(options: CustomProtoOptions): void;
    addCustomStatEvent(options: CustomStatEventOptions): void;
    addRadarStatEvent(options: CustomStatEventOptions): Promise<any>;
    isSupportBridgeLogger(name?: string): Promise<boolean>;
}
