/**
 * @file iframe发送log
 *
 * @author 天翔Skyline(skyline0705@gmail.com)
 * May 14, 2019
 */
import { nanoId } from '../util/uuid';
import { DataOptions } from './types';

const commonHideStyle = [
    'display: none;',
    'position: absolute;',
    'top: -9999px',
    'left: -9999px',
].join('');

function createIframe(target: string) {
    const iframe = document.createElement('iframe');
    document.body.appendChild(iframe);
    (iframe.contentWindow as Window).name = target;
    iframe.style.cssText = commonHideStyle;
    return iframe;
}

function createForm(target: string, url: string) {
    const form = document.createElement('form');
    document.body.appendChild(form);
    form.target = target;
    form.enctype = 'multipart/form-data';
    form.method = 'POST';
    form.action = url;
    form.style.cssText = commonHideStyle;
    return form;
}

function appendDataToForm(form: HTMLFormElement, data: any) {
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            const input = document.createElement('input');
            input.name = key;
            input.value = JSON.stringify(data[key]);
            form.appendChild(input);
        }
    }
}

export default function sendData(
    { url, data }: DataOptions,
    callback?: (error?: any) => void,
) {
    const target = `iframe_${nanoId()}`;
    let iframe: HTMLIFrameElement | null = createIframe(target);
    let form: HTMLFormElement | null = createForm(target, url);
    appendDataToForm(form as HTMLFormElement, data);
    try {
        form.submit();
    } catch (e) {
        return callback && callback(e);
    }
    iframe.onload = function () {
        document.body.removeChild(iframe as HTMLIFrameElement);
        document.body.removeChild(form as HTMLFormElement);
        iframe = null;
        form = null;
    };
    callback && callback();
}
