import { getCookie } from './cookie';
import { ksAppList, ksOverseaAppList } from './const';
import { UrlMapFn, UrlMap, UrlMapFnParam } from '../config/types';

/**
 * @file webLogger工具函数
 * @author <zhangshen@kuaishou.com>
 * @author 天翔Skyline(skyline0705@gmail.com)
 */

/**
 * 获取 localStorage 缓存
 */
export function getLocalStorageItem(key: string) {
    try {
        if (window && window.localStorage) {
            const value = window.localStorage.getItem(key);
            if (value) {
                try {
                    return JSON.parse(value);
                } catch (err) {
                    return value;
                }
            }
        }
    } catch (err) {
        return null;
    }
    return null;

}
/**
 * 设置 localStorage 缓存
 */
export function setLocalStorageItem(key: string, value: any) {
    try {
        if (window && window.localStorage) {
            window.localStorage.setItem(key, JSON.stringify(value));
            return true;
        }
    } catch (err) {
        return false;
    }
    return false;
}

/**
 * 判断item是否在目标数组arr中
 * @param {*} arr   目标数组
 * @param {*} item  item
 */
export function inArray(arr: any[], item: any) {
    return arr.indexOf(item) > -1;
}
/**
 * 从目标arr中删除item
 * @param {*} arr  目标数组
 * @param {*} item  item
 */
export function deleteArray(arr: any[], item: any) {
    const index = arr.indexOf(item);

    if (index > -1) {
        arr.splice(index, 1);
    }
}

/**
 * 事件监听兼容处理
 * @param {*} evtTarget  事件监听目标
 * @param {*} evtName   事件名
 * @param {*} callback  回调函数
 * @param {*} options  相关参数设置
 */
export function addMonitor(
    evtTarget: Element | Window | Document,
    evtName: string,
    callback: EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions,
) {
    if ('attachEvent' in evtTarget) {
        return evtTarget.attachEvent('on' + evtName, callback);
    }
    return evtTarget.addEventListener(evtName, callback, options);
}

/**
 * 事件监听解绑兼容处理
 * @param {*} evtTarget  事件监听目标
 * @param {*} evtName   事件名
 * @param {*} callback  事件回调
 */
export function removeMonitor(
    evtTarget: Element | Window | Document,
    evtName: string,
    callback: EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions,
) {
    if ('attachEvent' in evtTarget) {
        return evtTarget.detachEvent('on' + evtName, callback);
    }
    return evtTarget.removeEventListener(evtName, callback, options);
}

export function leftPad(str: string, len: number, ch = ' ') {
    len = len - str.length;
    if (len <= 0) {
        return str;
    }
    let pad = '';
    while (len) {
        if (len & 1) {
            pad += ch;
        }
        len >>= 1;
        ch += ch;
    }
    return pad + str;
}

/**
 * 是否是快手系APP
 */
export const isInApp = (() => {
    // 防止SSR出问题，所以需要给出方法而不是直接属性
    let inApp: boolean | null = null;
    return () => {
        try {
            if (inApp === null) {
                for (let i = 0; i < ksAppList.length; i++) {
                    const isOversea = ksOverseaAppList.indexOf(ksAppList[i]) > -1;
                    if (isOversea) {
                        if (window.navigator.userAgent.toLowerCase().indexOf(ksAppList[i].toLowerCase()) > -1) {
                            inApp = true;
                            break;
                        }
                    } else {
                        if (window.navigator.userAgent.indexOf(ksAppList[i]) > -1) {
                            inApp = true;
                            break;
                        }
                    }
                }
            }
            return inApp;
        } catch (err) {
            return false;
        }
    };
})();

/**
 * 是否是快手APP
 */
export const isInKwai = (() => {
    let inApp: boolean | null = null;
    return () => {
        if (inApp === null) {
            inApp = window.navigator.userAgent.indexOf('Kwai') > -1;
        }
        return inApp;
    };
})();
export const isInKwaiSpeed = (() => {
    let inApp: boolean | null = null;
    return () => {
        if (inApp === null) {
            inApp = window.navigator.userAgent.indexOf('ksNebula') > -1;
        }
        return inApp;
    };
})();
function fakeRaf(callback: (...args: any[]) => void) {
    return setTimeout(callback, 1000 / 16);
}
const fakeCaf = clearTimeout;

export const raf = function (this: any, callback: (...args: any[]) => void) {
    const fn = window.requestAnimationFrame
        || window.mozRequestAnimationFrame
        || window.webkitRequestAnimationFrame
        || fakeRaf;
    return fn.call(this, callback);
};

export const caf = function (this: any, handle: number) {
    const fn = window.cancelAnimationFrame
        || window.mozCancelAnimationFrame
        || window.webkitCancelAnimationFrame
        || fakeCaf;
    return fn.call(this, handle);
};


export function parseQueryString(queryString: string) {
    const result: any = {};
    const queries = queryString.split('&');
    for (const query of queries) {
        const [key, value] = query.split('=');
        if (!(key in result)) {
            result[key] = value;
            continue;
        }
        if (result[key] instanceof Array) {
            result[key].push(value);
            continue;
        }
        result[key] = [result[key], value];
    }
    return result;
}

export function stringifyQuery(paramsString: any) {
    const params = typeof paramsString === 'string' ? JSON.parse(paramsString) : paramsString;
    const queries: string[] = [];
    Object.keys(params).forEach(key => {
        if (params[key] instanceof Array) {
            for (const val of params[key]) {
                queries.push(`${key}=${val}`);
            }
        } else if (typeof params[key] === 'object' && params[key] !== null) {
            queries.push(`${key}=${JSON.stringify(params[key])}`);
        } else {
            queries.push(`${key}=${params[key]}`);
        }
    });
    return queries.join('&');
}

/**
 * 随机生成长度为4的字符串
 */
export const s4 = function () {
    return ((1 + Math.random()) * 0x10000 | 0).toString(16).substring(1);
};


// 处理 DOM 路径，获取可读性强的列表
export function composedPath(path?: Element[]) {
    // 兼容下低版本安卓机报错问题。。
    if (!Array.isArray(path)) return '';

    const eleList = path.map(ele => {
        const { tagName = '', id, className = '' } = ele;
        let item = tagName.toLowerCase();
        if (id) item += `#${id}`;
        if (className) item += className.split(' ').map(v => `.${v}`).join('');
        return item;
    });

    // window 取不出来，感觉意义不大，直接过滤掉吧
    return eleList.filter(v => v).join(',');
}

// 参考：https://codereview.stackexchange.com/questions/37512/count-byte-length-of-string
export function getStringBytes(data: any) {
    let dataStr = '';

    // Force string type
    if (typeof data === 'string') {
        dataStr = data;
    } else {
        try {
            dataStr = JSON.stringify(data);
        } catch (e) {
            dataStr = String(data);
        }
    }

    let byteLen = 0;
    for (let i = 0; i < dataStr.length; i++) {
        const c = dataStr.charCodeAt(i);
        byteLen += c < (1 << 7) ? 1 :
            c < (1 << 11) ? 2 :
                c < (1 << 16) ? 3 :
                    c < (1 << 21) ? 4 :
                        c < (1 << 26) ? 5 :
                            c < (1 << 31) ? 6 : Number.NaN;
    }
    return byteLen;
}

/**
 * http://git.corp.kuaishou.com/ks-frontend/modules/web-util/blob/master/src/device.js
 */
const kpnFromUA = (() => {
    return () => {
        if (typeof window === 'undefined') return '';
        let ua = window.navigator.userAgent;
        // 注意顺序很重要
        let rules = [
            [/ wxwork\//, 'workWechat'],

            [/ MicroMessenger\//, 'wechat'],
            [/ Kwai\//, 'KUAISHOU'],
            [/ ksthanos\//, 'THANOS'],
            [/ ksNebula\//i, 'NEBULA'],
            [/ QQ\//, 'qq'],
            [/__weibo__([^_]+)/, 'weibo'],
            [/ livemate\//, 'livemate'],
            [/ baiduboxapp\//, 'baidu'],

            [/ MQQBrowser\//, 'qqBrowser'],
            [/\(MSIE ([^;]+);/, 'ie'],
        ];

        for (let i = 0; i < rules.length; i++) {
            let tmp = rules[i];
            let rule = tmp[0];
            let name = tmp[1];
            let rRes = (rule as RegExp).exec(ua);
            if (rRes) {
                return name;
            }
        }
        return '';
    };
})();

/**
 * 获取默认的 KPN
 */
export function getDefaultKpn() {
    const kpnInCookie = getCookie('kpn');
    const kpnInUA = kpnFromUA();

    return kpnInCookie || kpnInUA || '';
}

/**
 * lodash 的 pick 方法
 */
export function pick(data: any, paths: string[]) {
    let index = -1;
    const length = paths.length;
    const result: any = {};

    while (++index < length) {
        const path = paths[index];
        const value = data[path];
        result[path] = value;
    }

    return result;
}

/**
 * 检查依赖版本号是否不小于推荐版本
 */

export function checkVersion(recommendVersion: string, version: string) {
    if (!version) return false;
    if (version.indexOf('alpha') !== -1) return false;
    if (version.indexOf('beta') !== -1) return false;
    try {
        let matches = recommendVersion.match(/\d+\.\d+\.\d+/);
        recommendVersion = matches ? matches[0] : '';
        if (!recommendVersion) return true;

        matches = version.match(/\d+\.\d+\.\d+/);
        version = matches ? matches[0] : '';
        if (!version) return false;

        const [rMajor, rMinor, rPatch] = recommendVersion.split('.').map((i: string) => Number(i));
        const [major, minor, patch] = version.split('.').map((i: string) => Number(i));

        if (major < rMajor) return false;
        if (major > rMajor) return true;

        if (minor < rMinor) return false;
        if (minor > rMinor) return true;

        if (patch < rPatch) return false;

        return true;
    } catch (err) {
        return false;
    }
}

/**
 * 获取元素xpath
 * @param element 元素
 */
export function getXpathFromNode(element: HTMLElement): string {
    // if (element && element.id !== '')
    //     return 'id("' + element.id + '")';
    if (element === document.body)
        return 'HTML/' + element.tagName;

    let ix = 0;
    if (element && element.parentNode) {
        const siblings = element.parentNode.childNodes;
        for (let i = 0; i < siblings.length; i++) {
            const sibling = siblings[i] as HTMLElement;
            if (sibling === element)
                return getXpathFromNode(element.parentNode as HTMLElement) + '/' + element.tagName + '[' + (ix + 1) + ']';
            if (sibling.nodeType === 1 && sibling.tagName === element.tagName)
                ix++;
        }
    }
    return '';
}

/**
 * url中获取path和query
 */
export function getPathAndQueryFromUrl() {
    const { protocol, host, pathname, search, href } = window.location;
    const pageUrl = href;
    const pagePath = protocol + host + pathname;
    const pageParams = parseQueryString(search.slice(1));
    return { pageUrl, pagePath, pageParams };
}

export function getUrlAndQueryFromUrl(url = location.href) {
    const queryIndex = url.lastIndexOf('?');
    if (queryIndex === -1) {
        return {
            page: url
        };
    }
    const page = url.slice(0, queryIndex);
    const queryString = url.slice(queryIndex + 1);
    return {
        page,
        params: parseQueryString(queryString),
    };
};

export const getMappingPage = (urlPackage: UrlMapFnParam, urlMap?: UrlMap | UrlMapFn) => {
    let { url, page, params } = urlPackage;
    if (!page && typeof url === 'string') {
        const parsed = getUrlAndQueryFromUrl(url);
        page = parsed.page;
        params = parsed.params;
    }
    if (typeof urlMap === 'function') {
        try {
            const res = urlMap({ url, page, params });
            if (typeof res === 'string') {
                page = res;
            } else if (typeof res === 'object') {
                if (res.page) {
                    page = res.page;
                }
                if (res.params) {
                    params = res.params;
                }
            }
        } catch (err) { }
    } else if (typeof urlMap === 'object') {
        let mappingPage = '';
        for (const key in urlMap) {
            if ((url || page || '').indexOf(key) > -1) {
                mappingPage = urlMap[key];
                break;
            }
        }
        if (mappingPage) {
            page = mappingPage;
        }
    }
    return {
        page,
        params
    };
};

function supportsPushState() {
    const ua = window.navigator.userAgent;
    if (
        (ua.indexOf('Android 2.') !== -1 || ua.indexOf('Android 4.0') !== -1)
        && ua.indexOf('Mobile Safari') !== -1
        && ua.indexOf('Chrome') === -1
        && ua.indexOf('Windows Phone') === -1
    ) {
        return false;
    }
    return typeof window !== 'undefined' && window.history && 'pushState' in window.history;
}

export const urlChangeEventId = supportsPushState() ? 'popstate' : 'hashchange';
