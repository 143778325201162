import EventEmitter from '../util/events';
import { Weblogger } from '../config/types';

export abstract class BasePlugin extends EventEmitter {
    // @ts-ignore
    weblog: Weblogger = typeof window !== 'undefined' && window._GLOBAL_KS_WEBLOGGER_ && window._GLOBAL_KS_WEBLOGGER_.weblog;
    abstract destroy(): void;
    apply(weblog: Weblogger) {
        this.weblog = weblog;
    }
    /**
     * 统一由 weblog 在页面关闭前调用的生命周期函数
     */
    beforeUnload?: (event: Event) => void;
    static key: string;
}

export interface BasePluginConstructor {
    new(options?: any): any;
    new(weblogger?: any, options?: any): any;
    key: string;
}
/**
 * 在 bootstrap 环境下自动注册插件
 * @param Plugin 
 * @param optionKey 
 */
export const autoRegister = (Plugin: any, optionKey?: string, PluginPlaceholder?: any) => {
    if (
        typeof window === 'undefined' ||
        typeof window._GLOBAL_KS_WEBLOGGER_ === 'undefined' ||
        typeof Plugin !== 'function') return;
    const { weblog, config } = window._GLOBAL_KS_WEBLOGGER_;
    let option = optionKey && config.plugins[optionKey] ? config.plugins[optionKey].options : undefined;
    if (optionKey === 'radar') {
        // 雷达插件采样特殊处理
        const radarOptions = option || weblog.logConfig[optionKey];
        if (!radarOptions) return;
        if (!radarOptions.sampling || !Plugin.samplingControl(radarOptions)) {
            return weblog.plug(PluginPlaceholder);
        }
    }
    weblog.addPluginInstance(new Plugin(option));
}