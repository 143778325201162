import { ClientEvent as ClientEventV3 } from '../proto/v3/client_event';
import { ClientEvent as ClientEventV2 } from '../proto/v2/client_event';
import { OtherEventOptions, ShowEventProps, TaskOperations, TaskEventOptions } from '../config/types';
import ShowEvent from './show-event';
import TaskEvent from './task-event';
import CustomStatEvent from './custom';
import { DeepPartial } from 'utility-types';
import { leftPad } from '../util';
import { getCustomIncrementId, getNomalIncrementId } from '../util/increment-id';
import Global from '../config/global';

const GMT = (() => {
    const timeZoneGMTOffset = (new Date()).getTimezoneOffset() / 60;
    return timeZoneGMTOffset <= 0
        ? `GMT+${leftPad(-timeZoneGMTOffset + '', 2, '0')}:00`
        : `GMT-${leftPad(timeZoneGMTOffset + '', 2, '0')}:00`;
})();

// 为防止每个传出都带common_package，所以用base保留大多数信息
// 部分信息放到每个消息体内维护，比如client_timestamp
export class ReportEvent {
    // uint64 客户端时间, Event或Stat发生时的时间, 而不是上传时的时间
    client_timestamp = new Date().valueOf();

    // uint64 客户端自增Id, 每新增一条日志+1, 必须是连续的, 用于校检数据完整性
    client_increment_id = 0;

    // string 会话id，设备冷启动或者切到后台超过5分钟再切回前台时，重新生成一个id
    // 端内页面不可缺省
    session_id = Global.sessionId;
    time_zone?: string;
    event_package?: ClientEventV3.EventPackage | ClientEventV2.EventPackage;
    stat_package?: ClientEventV3.CustomEvent;
    // 埋点唯一主键， PB 中已不推荐使用
    event_id = '';
    constructor(options: DeepPartial<ReportEvent>) {
        // v2 没有 time_zone 字段
        if (!Global.isV2) {
            this.time_zone = GMT;
        }
        Object.assign(this, options);
        this.genIncrementId();
    }
    // 设置自增 id，自定义事件和常规事件使用不同的 localStorage key
    genIncrementId() {
        if (this.isCustomStatEvent()) {
            this.client_increment_id = getCustomIncrementId();
        } else {
            this.client_increment_id = getNomalIncrementId();
        }
    }
    // 是否是自定义事件
    isCustomStatEvent() {
        return !!(this.stat_package && this.stat_package.custom_stat_event);
    }
    // 获取埋点事件类型
    getEventType() {
        if (this.event_package) {
            const { show_event, click_event, task_event, custom_event} = this.event_package as ClientEventV2.EventPackage;
            if (show_event) return 'showEvent';
            if (click_event) return 'clickEvent';
            if (task_event) return 'taskEvent';
            if (custom_event) return 'customEvent';
        }
        return 'customEvent';
    }
}

// tslint:enable
export function logFactory(type: string, data: ShowEventProps | OtherEventOptions) {
    const { eventId: event_id, currentUrlPackage, referUrlPackage, contentPackage, name, params } = data;
    switch (type) {
        case 'PV':
        case 'SHOW':
            return new ReportEvent({
                event_package: ShowEvent({
                    type,
                    status: (data as ShowEventProps).status,
                    currentUrlPackage,
                    referUrlPackage,
                    action: (data as ShowEventProps).type,
                    beginTime: (data as ShowEventProps).beginTime,
                    name,
                    params,
                    contentPackage,
                    operationDirection: data.operationDirection,
                    auto: (data as ShowEventProps).auto
                }),
                event_id,
            });
        case 'CUSTOM':
            const customData = CustomStatEvent({
                name,
                params,
                currentUrlPackage,
                referUrlPackage,
            });
            return new ReportEvent(Global.isV2 ?
                {
                    event_package: {
                        custom_event: customData
                    },
                    event_id,
                }
                :
                {
                    stat_package: {
                        custom_stat_event: customData,
                    },
                    event_id,
                }
            );
        case 'RADAR':
            return new ReportEvent({
                stat_package: {
                    custom_stat_event: CustomStatEvent({
                        name,
                        params,
                        currentUrlPackage,
                        referUrlPackage,
                    }),
                },
                event_id,
            });
        default:
            return new ReportEvent({
                event_package: TaskEvent({
                    type: type as TaskOperations,
                    status: (data as TaskEventOptions).status,
                    taskType: (data as TaskEventOptions).taskType,
                    sessionId: Global.sessionId,
                    currentUrlPackage,
                    referUrlPackage,
                    name,
                    params,
                    contentPackage,
                }),
                event_id,
            });
    }
}
