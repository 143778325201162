import { isInApp } from './index';
import { getUAInfo } from './ua';

const devices: Array<[string, string, string[]]> = [
    ['a7', '640x1136', ['iPhone 5', 'iPhone 5s']],
    ['a7', '1536x2048', ['iPad Air', 'iPad Mini 2', 'iPad Mini 3']],
    ['a8', '640x1136', ['iPod touch (6th gen)']],
    ['a8', '750x1334', ['iPhone 6']],
    ['a8', '1242x2208', ['iPhone 6 Plus']],
    ['a8', '1536x2048', ['iPad Air 2', 'iPad Mini 4']],
    ['a9', '640x1136', ['iPhone SE']],
    ['a9', '750x1334', ['iPhone 6s']],
    ['a9', '1242x2208', ['iPhone 6s Plus']],
    ['a9x', '1536x2048', ['iPad Pro (1st gen 9.7-inch)']],
    ['a9x', '2048x2732', ['iPad Pro (1st gen 12.9-inch)']],
    ['a10', '750x1334', ['iPhone 7']],
    ['a10', '1242x2208', ['iPhone 7 Plus']],
    ['a10x', '1668x2224', ['iPad Pro (2th gen 10.5-inch)']],
    ['a10x', '2048x2732', ['iPad Pro (2th gen 12.9-inch)']],
    ['a11', '750x1334', ['iPhone 8']],
    ['a11', '1242x2208', ['iPhone 8 Plus']],
    ['a11', '1125x2436', ['iPhone X']],
    ['a12', '828x1792', ['iPhone Xr']],
    ['a12', '1125x2436', ['iPhone Xs']],
    ['a12', '1242x2688', ['iPhone Xs Max']],
    ['a12x', '1668x2388', ['iPad Pro (3rd gen 11-inch)']],
    ['a12x', '2048x2732', ['iPad Pro (3rd gen 12.9-inch)']],
];

export const getResolution = (() => {
    let resolution = '';
    return () => {
        if (resolution) {
            return resolution;
        }
        try {
            const ratio = window.devicePixelRatio || 1;
            const width = Math.floor(Math.min(screen.width, screen.height) * ratio); // 有的devicePixelRatio为小数，在此转为整数
            const height = Math.floor(Math.max(screen.width, screen.height) * ratio);
            resolution = `${width}x${height}`;
            return resolution;
        } catch (err) {
            return '';
        }
    };
})();

const getGPU = (() => {
    let gpu = '';
    return () => {
        if (gpu) {
            return gpu;
        }
        try {
            const canvas = document.createElement('canvas');
            const glRenderContext = canvas.getContext('webgl');
            if (glRenderContext) {
                const debugRenderInfo = glRenderContext.getExtension('WEBGL_debug_renderer_info');
                gpu = debugRenderInfo
                    ? glRenderContext.getParameter(debugRenderInfo.UNMASKED_RENDERER_WEBGL)
                    : 'unknown';
            } else {
                gpu = 'unknown';
            }
            return gpu;
        } catch (err) {
            return 'unknown';
        }
    };
})();

export const getModel = (() => {
    let model = '';
    return () => {
        if (model) {
            return model;
        }
        try {
            const uaInfo = getUAInfo();
            const inApp = isInApp();
            // 非iOS设备走ua判断
            // iOS端内也有问题= =妈的，傻逼苹果！应该是UIWebView特有的坑
            // 链接:https://www.jianshu.com/p/bae4db1f08b2
            if (uaInfo.os.name !== 'iOS' || inApp) {
                return uaInfo.device.model || 'unknown';
            }
            const gpu = getGPU();
            const resolution = getResolution();
            const matches = gpu.match(/^apple\s+([_a-z0-9-]+)\s+gpu$/i);
            if (!matches) {
                model = 'unknown';
                return model;
            }
            for (const device of devices) {
                if (matches[1].toLocaleLowerCase() === device[0] && resolution === device[1]) {
                    model = device[2].join(' or ');
                    break;
                }
            }
            model = model || uaInfo.device.model || 'unknown';
            return model;
        } catch (err) {
            return 'unknown';
        }
    };
})();

export function isReactNative() {
    const GLOBAL: any = typeof globalThis !== 'undefined' ? globalThis : (typeof window !== 'undefined' ? window : null);
    return GLOBAL && GLOBAL.navigator && GLOBAL.navigator.product === 'ReactNative'
        || GLOBAL && GLOBAL.ReactNative && GLOBAL.ReactNative.NativeModules;
}