import { generateDID } from '../util/uuid';
// import { storeLogs, getStoredLogs } from '../util/storer';
import Global from '../config/global';
import { ClientLog as ClientLogV3 } from '../proto/v3/client_log';
import { ClientLog as ClientLogV2} from '../proto/v2/client_log';
import CommonPackage from './common';
import { LogConfig } from '../config';
import sendData from '../io';
import { getCookie } from '../util/cookie';
import { parseQueryString } from '../util';

type SendCallback = (error?: any) => void;
// logger测试扫二维码后需要客户端种在cookie中的字段
interface WebloggerSwitch {
    // 将log切换发送到该地址
    reportHost: string;
    // log测试前端页面地址
    loggerHost: string;
    // 本次logger测试的sessionId
    loggerSessionId: string;
    handshakeApi?: string;
}
// 测试环境 url
const devHost = 'https://webapi.test.gifshow.com/';
const dataTrackHost = 'https://data-track.corp.kuaishou.com';
const testHost = dataTrackHost + '/';

const xhrGet = (url: string) => {
    try {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url);
        xhr.send();
    } catch (err) {}
};
const getReportUrl = (viewHost: string, reportHost: string, sessionId: string = generateDID()) => {
    const loggerUrl = reportHost.indexOf('data-track.corp') !== -1 ? `${dataTrackHost}/v3/#/logger/index?sessionId=${sessionId}` : `${viewHost}/#/home?sessionId=${sessionId}`;
    console.log(`%c日志抓包校验: %c${loggerUrl}`, 'color:#1abf89;font-size:1.4em;line-height:2.4em;', 'font-size:1.2em;');
    return `${reportHost}/${sessionId}/`;
};
/**
 * logger 平台上报 url
 */
const getLoggerReportUrl = () => {
    let did = generateDID();
    xhrGet(`${dataTrackHost}/rest/${did}`);
    return getReportUrl('', `${dataTrackHost}/rest`, did);
};
/**
 * 端内环境根据cookie值切换发送地址
 */
const getCookieReportUrl = () => {
    try {
        let webloggerSwitch = getCookie('weblogger_switch') ||
            typeof sessionStorage !== 'undefined' && sessionStorage.getItem('weblogger_switch');
        if (!webloggerSwitch) return;
        const { loggerSessionId, reportHost, loggerHost, handshakeApi }: WebloggerSwitch = JSON.parse(webloggerSwitch);
        // 若cookie内存在loggerSessionId，则会自动切换发送路径到测试地址，否则返回空，代表不切换地址
        if (!loggerSessionId) {
            return '';
        }
        xhrGet(handshakeApi || `${reportHost}/${loggerSessionId}`);
        return getReportUrl(loggerHost, reportHost, loggerSessionId);
    } catch (err) {
        return '';
    }
};

/**
 * 端外H5 or pc页面根据当前的url参数切换发送地址
 */
const getWebPageReportUrl = (url: string = location.href) => {
    const queryIndex = url.lastIndexOf('?');
    if (queryIndex === -1) {
        return '';
    }
    const queryString = url.slice(queryIndex + 1);
    const { webloggerSwitch } = parseQueryString(queryString);
    if (!webloggerSwitch) {
        return '';
    }
    try {
        const data = decodeURIComponent(webloggerSwitch);
        const { loggerSessionId, reportHost, loggerHost, handshakeApi } = JSON.parse(data);
        if (!loggerSessionId) {
            return '';
        }
        // 存到 sessionStorage 中使同域名页面都可以调试
        if (typeof sessionStorage !== 'undefined') {
            sessionStorage.setItem('weblogger_switch', data);
        }
        xhrGet(handshakeApi || `${reportHost}/${loggerSessionId}`);
        return getReportUrl(loggerHost, reportHost, loggerSessionId);
    } catch (err) {
        return '';
    }
};
// 不同环境的接口
const Hosts = {
    v2: {
        // 正式环境随机域名分流
        production: ['https://wlog.ksapisrv.com/', 'https://wlog.gifshow.com/'][Math.round(Math.random())],
        development: devHost,
        test: testHost
    },
    v3: {
        production: 'https://log-sdk.ksapisrv.com/',
        development: devHost,
        test: testHost
    }
};
const Apis = {
    v2: 'rest/kd/log/collect?_json=1&biz=',
    v3: 'rest/wd/common/log/collect/misc2',
    // 雷达都上报到 v3
    radar: 'rest/wd/common/log/collect/radar'
};
type v3Env = 'production' | 'test' | 'development';
const getUrl = (
    env: v3Env | string = 'production',
    radar = false,
    proto: 'v2' | 'v3' = Global.proto,
) => {
    if (['production', 'test', 'development'].indexOf(env) === -1) {
        return env + Apis[proto];
    }
    if (radar) {
        return Hosts.v3[env as v3Env] + Apis.radar;
    }
    return Hosts[proto][env as v3Env] + Apis[proto];
};

export type LogPackage = (ClientLogV3.ReportPackage | ClientLogV2.ReportPackage) & {seqid?: number};
type ReportEvent = ClientLogV3.ReportEvent | ClientLogV2.ReportEvent;
type BatchReportEvent = ClientLogV3.ReportEvent[] | ClientLogV2.ReportEvent[];

// 是否未排干日志队列
let drained = false;
export default class BatchLog {
    // 存储待发送的消息
    logQueue: BatchReportEvent = [];
    // 存储失败或者超时的数据
    errorQueue: LogPackage[] = [];
    sendingQuery: {[key: string]: LogPackage} = {};
    // 延迟发送定时器
    private batchWaitTimer: any;
    // 延时补报日志定时器
    private compensateTimer: any;
    commonPackage!: CommonPackage;
    config!: LogConfig;
    url: string = '';
    radarUrl: string = '';

    /*
    constructor() {
        this.compensateLogs();
    }
    */

    updateBase(commonPackage: CommonPackage) {
        this.commonPackage = commonPackage;
    }
    /**
     * 根据配置生成上报的 url 地址
     */
    updateUrls() {
        const { env, logger } = this.config;
        let url = getCookieReportUrl() || getWebPageReportUrl();
        if (url) {
            this.radarUrl = this.url = getUrl(url);
            Global.isDebug = true;
        } else if (logger || env === 'logger') {
            const loggerEnv = getLoggerReportUrl();
            this.url = getUrl(loggerEnv);
            this.radarUrl = this.url.indexOf(Apis.v2) !== -1 ? this.url.replace(Apis.v2, Apis.radar) : this.url.replace(Apis.v3, Apis.radar);
            Global.isDebug = true;
        } else if (env && /^(https?:)?\/\//.test(env)) {
            this.url = env;
            this.radarUrl = this.url.replace(Apis.v3, Apis.radar);
        } else {
            this.url = getUrl(env as v3Env);
            this.radarUrl = getUrl(env as v3Env, true);
        }
    }

    init(config: LogConfig, commonPackage: CommonPackage) {
        this.config = ({
            ...this.config,
            ...config,
        });
        this.updateUrls();
        this.updateBase(commonPackage);
    }
    /**
     * 之所以使用 JSON 而不是 commonPackage 对象，是因为对象可能会变化，而 JSON 则是当时的一个快照，一旦生成不会变化
     */
    getCommonPackageJSON() {
        if (!Global.isV2) {
            this.commonPackage.increaseHTTPSeqId();
        }
        return this.commonPackage.toJSON();
    }
    /**
     * 每个 logPackage 包含了所有上报所需的信息，上报地址和上报数据
     * @param logs 
     * @param url 
     */
    buildLogPackage(logs: BatchReportEvent, url = this.url) {
        if (Global.isV2) {
            return this.buildV2Package(logs as ClientLogV2.ReportEvent[], url);
        }
        return this.buildV3Package(logs as ClientLogV3.ReportEvent[], url);
    }
    buildV2Package(logs: ClientLogV2.ReportEvent[], url = this.url): ClientLogV2.ReportPackage {
        return {
            url,
            data: {
                log: {
                    event: logs
                }
            },
            format: 'string',
        }
    }
    buildV3Package(logs: ClientLogV3.ReportEvent[], url = this.url): ClientLogV3.ReportPackage {
        return {
            url,
            data: {
                common: this.getCommonPackageJSON(),
                logs: {
                    // 格式对应PB定义中RawBatchReportEvent
                    event: logs,
                },
            },
            format: 'formdata',
        }
    }
    /**
     * 批量发送日志
     * @param logs 
     * @param callback 
     */
    private sendLogs(logs: BatchReportEvent, callback?: SendCallback) {
        if (!logs || !logs.length) {
            return typeof callback === 'function' && callback();
        }
        const data: LogPackage = this.buildLogPackage(logs);
        // 如果已经调过 drained 方法应该直接走 drain 逻辑，但是在浏览器中会造成重复
        // if (drained) {
        //     this.beforeStore(data);
        //     return storeLogs([data]);
        // }
        // data.seqid = seqid ++;
        const callbackFn = (error?: any) => {
            // if (typeof data.seqid !== 'undefined') {
            //     delete this.sendingQuery[data.seqid];
            // }
            // 如果网络异常，日志发送失败，则将错误日志缓存，在网络恢复时清空一次错误队列
            if (error) {
                this.errHandler(data)
            } else {
                this.flushErrorLogs();
            }
            typeof callback === 'function' && callback(error);
        }
        // this.sendingQuery[data.seqid] = data;
        this.sendPackage(data, callbackFn);
    }
    /**
     * 直接发送一个数据包，包含 url 和 日志
     * @param data 
     * @param callback 
     */
    sendPackage(data: LogPackage, callback?: SendCallback) {
        const { timeout, sender } = this.config;
        try {
            // 如果业务方配置了 sender
            if (typeof sender === 'function') {
                sender(data, callback);
            } else {
                sendData({...data, timeout}, callback);
            }
        } catch (err) {
            typeof callback === 'function' && callback(err);
        }
    }
    /**
     * 清空当前日志队列
     * @param callback 
     */
    flush = (callback?: SendCallback) => {
        this.sendLogs(this.logQueue, callback);
        this.logQueue = [];
    }
    /**
     * 页面关闭前排干所有未发送的和发送失败的日志
     */
    drain = () => {
        this.flush();
        this.flushErrorLogs();
        drained = true;
        // 处理 beforunload 中断，实际页面未退出的情况
        setTimeout(() => {
            drained = false;
        }, 1000);
        /* 会导致重复率升高，春节后再看服务端去重后处理
            const logPackages = this.errorQueue.slice(0)
            this.errorQueue = [];
            this.sendingQuery = {};
            if (this.logQueue.length) {
                logPackages.unshift(this.buildLogPackage(this.logQueue));
                this.logQueue = [];
            }
            const unSendLogPackages: LogPackage[] = [];
            logPackages.forEach(logPackage => {
                this.beforeStore(logPackage);
                unSendLogPackages.push(logPackage);
            });
            // 不支持 sendBeacon 或者添加到 beacon 失败的日志则存到本地
            if (unSendLogPackages.length) {
                storeLogs(unSendLogPackages.concat(
                    Object.keys(this.sendingQuery).map(key => this.sendingQuery[key])
                ));
            }
        */
    }
    /**
     * 日志存储前标识处理
     * @param logPackage 
     */
    /* 会导致重复率升高，春节后再看服务端去重后处理
    beforeStore(logPackage: LogPackage) {
        // 使用 puppeteer 测试 sendBeacon 页面关闭前请求成功率低，这里使用 sendBeacon 发送，同时保存本地
        this.sendPackage(logPackage);
        // 标记日志是否来自存储
        if ((logPackage as ClientLogV3.ReportPackage).data.common) {
            (logPackage as ClientLogV3.ReportPackage).data.common.h5_extra_attr.stored = true;
        } else {
            (logPackage as ClientLogV2.ReportPackage).data.log.event.forEach(log => log.common_package.h5_extra_attr.stored = true);
        }
    }
    */
    
    send(data: ReportEvent, immediate = false, callback?: SendCallback) {
        // v2 是每个 log 都有 common_package
        if (Global.isV2) {
            // v2 log 的自增 id 是存在 common 信息中
            this.commonPackage.setAdditionalSeqIdPackage((data as any).getEventType());
            data.common_package = this.getCommonPackageJSON();
        }
        // 立即发送马上插入发送队列
        // 立即上报可以设置回调函数，延迟上报没有
        if (immediate || drained) {
            this.sendLogs([data] as BatchReportEvent, callback);
            return;
        }

        this.logQueue.push(data as any);
        // 积攒超过最大
        if (this.logQueue.length >= 50) {
            this.flush();
            return;
        }

        // debounce逻辑
        clearTimeout(this.batchWaitTimer);
        this.batchWaitTimer = setTimeout(() => {
            this.flush();
        }, this.config.wait);
    }
    /**
     * 雷达专用上报（雷达上报接口和其他事件上报接口做了区分，用于分流统计）
     * @param radar 
     */
    sendRadar(radar: ClientLogV3.ReportEvent) {
        const radarPackage = this.buildV3Package([radar], this.radarUrl);
        this.sendPackage(radarPackage);
    }
    /**
     * 日志上报出错处理，一般为网络异常
     * @param logPackage 
     */
    errHandler(logPackage: LogPackage) {
        if (Global.isV2) {
            this.errorQueue.unshift(logPackage);
            return;
        }
        const { data } = logPackage as ClientLogV3.ReportPackage;
        if (!data.logs.event.length) return;
        // http_seq_id 和 client_timestamp 不是必须的，合并时删除
        delete data.common.h5_extra_attr.http_seq_id;
        delete data.common.h5_extra_attr.client_timestamp;
        
        // 尝试合并相同 url 和 common 的请求
        let merged = false;
        for (let i = 0; i < this.errorQueue.length; i++) {
            const currentLog = this.errorQueue[i]  as ClientLogV3.ReportPackage;
            if (
                currentLog.url === logPackage.url &&
                currentLog.data.logs.event.length + data.logs.event.length <= 100 &&
                JSON.stringify(currentLog.data.common) === JSON.stringify(data.common)
            ) {
                merged = true;
                currentLog.data.logs.event.push(...data.logs.event);
                break;
            }
        }
        if (!merged) {
            // 先进后出
            // 最大错误数量限制为 5
            if (this.errorQueue.length >= 5) {
                this.errorQueue.pop();
            }
            this.errorQueue.unshift(logPackage);
        }
    }

    // 当网络恢复时尝试一次上报
    flushErrorLogs() {
        this.errorQueue.forEach(logPackage => {
            this.sendPackage(logPackage);
        });
        this.errorQueue = [];
    }

    /**
     * 补报本地存的日志，本地可能存在多个，根据时间顺序取出上报
     */
    /* 会导致重复率升高，春节后再看服务端去重后处理
    compensateLogs(secs: number = 1500) {
        this.compensateTimer = setTimeout(() => {
            const logPackages = getStoredLogs();
            if (!logPackages || !logPackages.length) return;
            logPackages.forEach((logPackage: LogPackage) => {
                sendData(logPackage);
            });
        }, secs);
    }
    */

    destory() {
        this.batchWaitTimer && clearTimeout(this.batchWaitTimer);
        this.compensateTimer && clearTimeout(this.compensateTimer);
    }
}
