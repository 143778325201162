/**
 * client_common.propto ts 声明文件
 * https://git.corp.kuaishou.com/data-platform/kuaishou-common-log-proto/-/blob/master/src/main/proto/kuaishou/log/client_log/client_common.proto
 */
import { Base } from '../base';

export namespace ClientCommon {
    export enum Product {
        UNKNOWN1 = 0, // 未知
        KUAISHOU = 1, // 快手, 目前仅有此一个Product
        KUAISHOU_GAME_EXTENSION = 2, // 快手游戏插件
        KUAISHOU_LIVE_MATE = 3, // 快手直播伴侣
        KUAISHOU_VIDEO_EDITOR = 4, // 快影
        KWAI = 5, // 快手海外版
        KUAISHOU_H5 = 6, // 快手H5
        KWAI_LITE = 7, // 快手印尼版
        KWAI_GO = 8, // 快手印度版
        KUAISHOU_LIVE_MATE_WP = 9, // 快手直播伴侣Win (仅供埋点平台使用，log上报继续使用参数3)
        WECHAT_SMALL_APP = 12, // 微信小程序
        NEBULA = 13, // 极速版快手
        KWAI_GAME_LIVE_PLUGIN = 14, // 快手游戏直播插件
        ACFUN_GAME_CENTER = 15, // A站游戏中心
        GAME_DIANDIANXIAO = 16, // 点点消
        THANOS = 17, // 滑滑版
        UVIDEO = 18, // 快手印度版新包
        GAME_ZONE = 19, // 电喵直播
        KWAI_GO_BRAZIL = 20, //快手巴西版
        KWAI_GO_COMICS = 21, // 快手中东版
        KUAISHOU_ANTMAN = 22, // 快手小包版
        UVIDEO_V2 = 23, // 快手印度版新包v2
        KWAI_BULLDOG = 24, // 海外快手滑滑版
        LOLITA_GLOBAL = 25,// 快手中东版新版
        KUAISHOU_NEBULA_ANTMAN = 26, // 极速版快手-小包版
        ZIKZAK = 27, // x6-zikzak
        KS_TV = 28, // 云视听快TV
        UVIDEO_LITE = 29,//海外UVIDEO lite版
        KUAISHOU_VISION = 30,//快手-PC主站首页
    }
    
    // 应用基础信息
    export interface AppPackage extends Base.AppPackage {
        product?: Product; // 产品名称, 不同产品独立进行统计分析(为了灵活接入新app，废弃该参数，新接入app请上报到参数8)
    }

    export enum TimePackageSyncStatus {
        UNSYNCHROIZED = 0,
        SYNCHROIZED = 1,
    }

    export interface TimePackage {
        sync_status: TimePackageSyncStatus; // 时间是否已经与服务器校准
        client_time_diff: number;// 该字段已废弃 客户端本地时间与ntp时间的差值，单位ms (ntp时间减去客户端时间的值)
        time_zone: string; // 时区 例：GMT+08:00 Asia/Shanghai
        client_time_difference: number;// 客户端本地时间与ntp时间的差值，单位ms (ntp时间减去客户端时间的值)
    }

    // 所有 Event 都包含的数据
    export interface CommonPackage extends Base.CommonPackage {
        app_package: AppPackage; // 应用信息
        time_package?: TimePackage; // 客户端时间的信息
    }
}