import { UrlMapFn } from './types';
import { updateConfig } from './global';
import {BasePlugin} from '../plugins/base'
import { YodaInterface } from '../util/yoda';

type EnvType = 'development' | 'test' | 'logger' | 'production';
export interface LogConfig {
    /**
     * 埋点上报环境
     */
    env?: EnvType;
    /**
     * logger 上报，建议使用 env: 'logger'
     */
    logger?: boolean;
    /**
     * 默认30秒超时, 仅在使用xhr异步发送数据时有用
     */
    timeout?: number;
    /**
     * 延迟发送毫秒数
     */
    wait?: number;
    /**
     * 同时发送消息的最大条数
     */
    maxBatchLength?: number;
    /**
     * 日志采样配置，值为 0~1 的 float number。日志发送按照 sampleRate * 100% 的比例采样
     */
    sampleRate?: number;
    /**
     * 自定义采样函数
     */
    sampleRateFn?: (() => boolean) | null;
    /**
     * 来源信息
     */
    referer?: {
        value: string,
        type?: 'web' | 'native' | 'mina'
    };
    /**
     * URL 映射
     */
    urlMap?: UrlMapFn | {[key: string]: string};
    // 自定义上报途径
    sender?: (data: {url: string, data: any, [key: string]: any}, callback?: (error?: any) => void) => void;
    /**
     * 插件实例数组
     */
    plugins?: BasePlugin[];
    /**
     * bridge 中的 命名空间
     */
    nameSpace?: string;
    /**
     * 是否在 bridge 模式下同时开启 HTTP 上报
     */
    openHttpSender?: boolean;
    /**
     * 埋点 PB 格式，v2 快手主站相关 或者 v3 新业务
     */
    proto?: 'v2' | 'v3';
    /**
     * 传入 yoda sdk，当在端内使用非桥接上报时，可以通过传入的 yoda 来使雷达桥接上报、获取 KSwitch 采样配置等功能
     */
    yoda?: YodaInterface;
}

export default (options?: LogConfig): LogConfig => {
    const logConfig:LogConfig = {
        env: 'production',
        proto: 'v3',
        timeout: 30000,
        wait: 1000,
        maxBatchLength: 50,
        sampleRate: 1,
        // 尝试从全局中获取 yoda
        yoda: typeof window !== 'undefined' && (window as any).yoda,
        ...(options || {})
    };
    // 业务方采样配置
    if (options && (typeof options.sampleRateFn === 'function' || typeof options.sampleRate !== 'undefined')) {
        try {
            const sampled = options.sampleRateFn ? options.sampleRateFn() : Math.random() < Number(options.sampleRate);
            updateConfig('sampled', sampled);
        } catch (err) {
            updateConfig('sampled', false);
        }
    }
    updateConfig('proto', logConfig.proto || 'v3');
    return logConfig;
};
