/**
 * @file 这里进行一些对底层的封装，底层包括：
 * 1. 中间件机制；
 * 2. 对 perf API 的繁杂处理；
 * 3. 对 weblogger 上报的封装；
 * @author Malcolm Yu
 */
import { Base } from '../../proto/base';
import { Weblogger } from '../../config/types';
import { addMonitor, removeMonitor, getDefaultKpn } from '../../util';
import { BasePlugin } from '../base';
import {
    PerfTime, PerfMapType, PerfKey, PerfItem,
    KVPair, RadarOptions, RadarMonitor,
    PerfMap, DOMPerf, ResPerf, CustomEntry, SetDimensionParam,
    chromeMetricsConfig,
    webviewPerf,
    APIPerf, kswitchSampled
} from './interface';
import RadarPlugin, { RadarPluginConstructor } from './plugin';
import navigationTiming from './timing';
import { KEY, RADAR_KEY } from './const';
import { error, warn } from '../../util/console';
import { PAGELOG_API } from '../../bridge/types';
import { radarPoint, radarCost, getWebViewType, getUAList, collectChromeMetrics, chromeMetricConfig, normalizeURL, removeBridgeJsErrorCollect, radarWarnLog, formatYodaRadarData, CustomStatEventOptions } from './utils';

const KWAI_KPN = ['KUAISHOU', 'THANOS', 'NEBULA'];
const REPORT_THRESSHOLD = 60 * 1000;

export class RadarRoot extends BasePlugin {
    private kpn: string = getDefaultKpn();
    private plugins: RadarPlugin[] = [];
    private logQueue: KVPair[] = [];
    private queueConfig = {
        // 每1s清空一次queue
        wait: 1000,
        maxBatchLength: 200,
    };
    private eventName = 'onpagehide' in window ? 'pagehide' : 'beforeunload';
    private batchTimer = 0;
    private currentUrlPackage!: Base.UrlPackage;
    private referUrlPackage?: Base.UrlPackage;
    private startedLoadReport: boolean = false;
    private radarSessionId: string = '';
    private chromeMetric: chromeMetricsConfig[] = chromeMetricConfig;
    // public kswitchSampled: kswitchSampled;
    public options: RadarOptions;
    public isInKwai: boolean = KWAI_KPN.indexOf(this.kpn) >= 0;
    public isUsingBridge: boolean | null = null;
    public isUsingLegacy: boolean | null = null;
    public isUsingDetachedReport: boolean | null = null;
    public isSupportedYodaConcat: boolean | null = null;
    public customDimension: SetDimensionParam = {};
    // 默认忽略列表
    public ignoreList: string[] = ['https://web-trace.ksapisrv.com/ktrace/collect'];
    lastRadarLogId: string = '';
    // 当初始化时没获取到kswitch数据时 为true 表示需要在flush时重新计算采样率
    public computedSamplingAgain: boolean = true;
    public realSampledList: any[] = [];
    constructor(weblog: Weblogger | RadarOptions, options?: RadarOptions) {
        super();
        // 兼容处理
        let opts;
        if (options) {
            opts = options;
        }
        if (weblog) {
            if ((weblog as Weblogger).logger) {
                this.apply(weblog as Weblogger);
            } else {
                opts = weblog;
            }
        }
        radarPoint('radarCreatedToFirstReport');
        radarPoint('radarCreatedToOnload');
        this.options = opts as RadarOptions;
        this.queueConfig = {
            ...this.queueConfig,
            ...(this.options ? this.options.queue : {}),
        };
        // FIXME
        this.ignoreList = this.ignoreList.concat(normalizeURL(this.options && this.options.ignoreList));
    }

    get KSwitchSampled(): kswitchSampled | undefined {
        // @ts-ignore
        return this.weblog.currentUrlPackage.sampled;
    }
    get isKSwitchSampled(): boolean {
        // @ts-ignore
        return this.weblog.isKSwitchSampled;
    }

    // 采样控制 迁移自radar.ts 子类重写该方法
    protected samplingControl(options?: RadarOptions | number) {
        if (!options) {
            return false;
        }
        // 计算随机数
        const rand = Math.random();
        if (typeof options === 'number') {
            // 传入的是单一模块采样率 无需从options中取值
            return rand < options;
        }
        // radar整体采样率逻辑
        const { sampling } = options;

        return rand < sampling;
    }
    protected use(plugin: RadarPluginConstructor<RadarOptions>, ctx: RadarMonitor) {
        const usedPlugin = new plugin(ctx, this.options);
        this.plugins.push(usedPlugin);
        // 如果是旧式引用，use 是在 apply 之后调用。
        if (this.weblog) {
            usedPlugin.created();
        }
    }


    apply(weblog: Weblogger) {
        this.weblog = weblog;
        this.upadteUrlPackage();
        this.register();
        this.ignoreList.push(this.weblog.logger.url);
        // 业务侧new Radar时 是不会传入weblog对象的 apply方法在weblogger插件内调用 并传入weblogger对象
        // 业务侧传入radar时，weblogger通过plug方法实例化radar并传入weblogger对象 此时由雷达自身调用apply方法
        // 结论：在调用apply方法时一定会存在weblogger对象
        this.created();
    }

    created() {
        // 通过apply调用created，旧式引用时 plugins 为空
        this.plugins.forEach(plugin => {
            plugin.created();
        });
    }
    destroy() {
        removeMonitor(window, 'load', this.asyncReportTiming);
        this.plugins.forEach(plugin => {
            plugin.destroy();
        });
    }


    computedRealSampled(sampling: number, type: string): number | undefined {
        // 请求kswitch后｜通过h5上报
        if (this.isKSwitchSampled) {
            let KSwitchSampling;
            if (typeof this.KSwitchSampled === 'object' && this.KSwitchSampled['radar']) {
                let allSample = this.KSwitchSampled['radar']['all'];
                allSample = typeof allSample !== 'number' ? 1 : allSample;
                let theSample = this.KSwitchSampled['radar'][type];
                // load等采样率和all是相乘关系
                KSwitchSampling = typeof theSample !== 'number' ? allSample : (theSample * allSample);
            } else {
                // if (typeof this.KSwitchSampled === 'boolean') 只有这两种情况
                KSwitchSampling = this.KSwitchSampled ? 1 : 0;
            }
            return KSwitchSampling * sampling;
        } else {
            return 1;
            // bridge没有返回 先按全量收集，flush的时候在重新检测
        }
    }

    /**
     * 通过 perf level 2 注册监听事件
     * 实际测试需要处理几个 case：
     * 1. 部分浏览器只支持 level 1
     * 2. 部分浏览器在 PerformanceObserver 时没有 navigation 事件
     */
    private async register() {

        // 不支持 level 1

        if (!navigationTiming()) {
            warn('[Radar] 由于不支持 Performance API，只进行请求监控和异常监控');
            return;
        }
        // 鉴于部分浏览器虽然支持 level2，但 observer 时没有 navigation 事件
        // 因此这里直接上报 level1 的数据（反正不需要高精度数据）

        // remove js注入的 error事件 后续会通过radar收集上报
        removeBridgeJsErrorCollect();
        // 兼容业务在onload之后实例化雷达 所以手动调用一次上报方法
        if (performance.timing.loadEventEnd > 0) {
            this.asyncReportTiming();
        } else {
            addMonitor(window, 'load', this.asyncReportTiming);
        }
        if (this.options && this.options.radarCost) {
            // 默认不上报雷达自身耗时
            addMonitor(window, this.eventName, this.asyncMetricCollectDone);
        }
        // beforeunload时清空当前logqueue
        addMonitor(window, this.eventName, this.flush);

        // 处理支持 level 2 的情况

        const { Utils: { yoda } } = this.weblog;

        if (!yoda) {
            this.isUsingBridge = false;
            this.isSupportedYodaConcat = false;
            this.isUsingLegacy = false;
        } else {
            if (this.isUsingBridge == null) {
                this.isUsingBridge = await yoda.isSupportBridge();
            }
            if (this.isSupportedYodaConcat == null) {
                this.isSupportedYodaConcat = await yoda.isSupportBridge(PAGELOG_API);
            }
            this.isUsingLegacy = this.isUsingBridge && !this.isSupportedYodaConcat;
        }

        //  生成唯一sessionid
        this.radarSessionId = this.nanoId();


        // 这里需要注意：在客户端内，而且不支持 bridge，或者支持 bridge 但不支持 yodaConcat
        this.isUsingDetachedReport = (this.isInKwai && !this.isUsingBridge) || this.isUsingLegacy;
    }

    private observeResource = (perf: PerformanceObserverEntryList | Performance) => {
        perf.getEntriesByType('resource')
            .forEach(v => this.reportPerformance(v));
    };

    asyncReportTiming = () => {
        // onload之后的调用会影响该时间戳
        radarPoint('radarCreatedToOnload');
        radarPoint('onloadToFirstReport');
        setTimeout(() => {
            // 雷达自身的时间戳 不受影响
            radarPoint('asyncReportTiming');
            this.reportPerformance(navigationTiming());
            this.startedLoadReport = true;
            try {
                // 先上报一部分已经生成的 entries 数据
                this.observeResource(performance);
                // 收集lcp等数据信息
                this.observeChromeMetrics(this.chromeMetric);
                new PerformanceObserver(this.observeResource)
                    .observe({ entryTypes: ['resource'] });
            } catch (e) {
                warn('[Radar] 由于不支持 Performance API Level 2，只进行请求监控和异常监控');
            }
            // 注册radar-第一次上报的时间
            // created-firstReport = async-report + register-1
            // new weblog之后 至 onload + unload 至 firstReport
            radarPoint('radarCreatedToFirstReport');
            radarPoint('onloadToFirstReport');
            radarPoint('asyncReportTiming');
        }, 1000); // 延迟一秒上报，确保不阻塞 fmp 和 total 等性能数据
    };

    chromeMetricCallback = (opt: any) => {
        let { name, value } = opt;
        name = name.toLocaleLowerCase();
        this.logCollect({
            key: RADAR_KEY.CUSTOM,
            value: {
                [name]: value
            }
        });
        radarPoint(`${name}Collect`);
        collectChromeMetrics({ [name]: value });
    };
    observeChromeMetrics = (arr: chromeMetricsConfig[]) => {
        // 默认配置几项可收集指标
        const options = this.options;
        // 判断默认不主动上报，由业务进行配置
        arr.forEach(metric => {
            // 收集数据
            const { name } = metric;

            if (!options || options[name]) {
                radarPoint(`${name}Collect`);
                metric.collectFn(this.chromeMetricCallback);
            }
        });
    };
    // 监听异步指标耗时上报情况，确保耗时数据完善
    // 通过unload事件上报
    asyncMetricCollectDone = () => {
        // 格式 key:duration⬇️
        const radarCostResult: any = {};

        Object.keys(radarCost).forEach(radar => {
            const costItem = radarCost[radar];
            if (!costItem.duration && costItem.startTime) {
                // 防止有部分指标在unload的时候未收集完毕，默认置0
                costItem.duration = 0;
                delete costItem.startTime;
            }
            radarCostResult[radar] = costItem.duration;
        });
        this.logCollect({
            key: RADAR_KEY.CUSTOM,
            value: radarCostResult
        });
        this.flush();
        this.weblog.flush();
        // 上报完毕删除监听
        removeMonitor(window, this.eventName, this.asyncMetricCollectDone);
    };
    /**
     * 处理上报超出阈值的情况
     */
    protected throttle(value: any, perf: PerfItem, key: string, detail: any = {}) {
        if (perf.noThrottle || typeof value !== 'number') return value;
        const { startPoint, endPoint } = detail;
        // 间隔超过一定阈值，认为上报的有问题，不上报并记录日志
        // NaN数据也算作一种异常情况 不上报
        // 雷达的异常数据采样10%
        if (value > REPORT_THRESSHOLD || value < 0 || isNaN(value)) {
            if ((Math.random() < 0.1)) {
                const msg = `[${key} 异常]: ${value}，原始数据为：${perf.end}: ${endPoint} - ${perf.start}: ${startPoint}`;
                this.logCollect({
                    key: RADAR_KEY.EVENT,
                    value: null,
                    dimension: {
                        name: '雷达数据异常', // 必填
                        event_type: 'radar_error', // 【可选】
                        message: msg, // 【可选】
                        src: location.href,
                        webViewType: getWebViewType(),
                        yoda_version: getUAList().yoda_version || '',
                    }
                });
            }
            return null;
        }
        return Math.round(value);
    }

    protected reportPerformance(entry: PerformanceEntry | CustomEntry) {

        this.plugins.forEach(plugin => {
            if (!plugin.when(entry)) {
                return;
            }
            plugin.onPerfReport(entry);
        });
    }

    // 处理上报计算逻辑
    private calculate(key: PerfKey, perfMap: PerfMap<DOMPerf & ResPerf & APIPerf & webviewPerf>, timing: PerfTime) {


        const perf = perfMap[key] as PerfItem;
        if (!perf) {
            error(`perf key ${key} is unexpected!`);
            return;
        }
        // 当资源有缓存时，好多数据为 0，不进行无意义的上报
        if (perf.cachedSkip) {
            const cached = this.calculate('cached' as PerfKey, perfMap, timing);
            if (cached) return;
        }
        if (typeof perf.custom === 'function') {
            const value = perf.custom(key, timing);
            return this.throttle(value, perf, key);
        }
        const { end, start } = perf;

        // 由于webview数据上报的时候 不会带上performance.time导致timing['fmp']之类的数据为NaN无法计算
        //计算webview指标时 timing[end as keyof PerfTime] 可能为undefined 故兼容一下
        // @ts-ignore
        const endPoint = +timing[end as keyof PerfTime] || performance.timing[end];
        // @ts-ignore
        const startPoint = +timing[start as keyof PerfTime] || performance.timing[start];
        // console.log(`[info] ${key} –> ${end}: ${endPoint} - ${start}: ${startPoint} = ${endPoint - startPoint}`);
        const value = endPoint - startPoint;

        // 间隔超过一定阈值，认为上报的有问题，不上报并记录日志
        return this.throttle(value, perf, key, { startPoint, endPoint });
    }

    // FIXME
    generateLog(perfMap: any, timing: PerformanceEntry) {

        const allPerf: PerfMapType = {};
        // 批量处理日志生成
        Object.keys(perfMap).forEach(k => {
            const key = k as PerfKey;

            const value = this.calculate(key, perfMap, timing as PerfTime);
            // 可能存在一些值取不到的情况，取不到的话就不上报
            if (value != null) {
                // @ts-ignore
                allPerf[key] = value;
            }
        });

        return allPerf;
    }

    subDimension(logData: KVPair) {
        if (this.customDimension) {
            if (!logData.dimension) {
                logData.dimension = {};
            }
            Object.assign(logData.dimension, this.customDimension);
        }
    }
    /**
     * 生成nano id
     * 目前radarsessionid生成逻辑与weblog相同，防止weblog修改，雷达自身保存一份
     * @return {string} 16长度的字符串
     */
    private nanoId() {
        const ts = ((Math.random() * 1e9) >>> 0);
        const randomer = [];
        const seed = '0123456789ABCDEF';
        for (let i = 0; i < 7; i++) {
            randomer.push(seed.charAt(Math.random() * 16));
        }

        return ts + randomer.join('');
    }

    // flush上报之前重新探测一下采样率的问题
    beforeFlush() {
        // 这里不关心weblogger的采样率

        if (this.computedSamplingAgain) {
            // 第一次flush
            const samplingList: { [key: string]: any; } = {
                radarLoadSampling: { sampling: 1, type: 'load' },
                radarApiSampling: { sampling: this.options && this.options.apiSampling || 1, type: 'api' },
                radarErrorSampling: { sampling: this.options && this.options.errorSampling || 1, type: 'error' },
                radarResourceSampling: { sampling: this.options && this.options.resourceSampling || 0.1, type: 'resource' },
                radarCustomSampling: { sampling: this.options && this.options.customSampling || 1, type: 'custom' },
                radarEventSampling: { sampling: this.options && this.options.eventSampling || 1, type: 'event' },
            };
            this.realSampledList = [];
            Object.keys(samplingList).forEach(item => {
                // 计算出每个模块的采样率
                const sampling = this.isKSwitchSampled ?
                    // 初始化的时候 kswitch没有返回且 flush时 发现kswitch有返回了 重新计算

                    // load默认为1 其他根据业务配置取值
                    this.samplingControl(this.computedRealSampled(samplingList[item]['sampling'] as number, samplingList[item]['type'] as string))
                    // 采样率为用户配置的采样率
                    : this.samplingControl(samplingList[item]);
                if (sampling) {
                    this.realSampledList.push(samplingList[item]['type']);
                }
            });
            if (this.isKSwitchSampled) {
                this.computedSamplingAgain = false;
            }
        }

        // 把没采样的数据从logqueue中洗出来。。。。。
        this.logQueue = this.logQueue.filter(log => this.realSampledList.indexOf(log.key) !== -1);
    }


    flush = () => {
        this.beforeFlush();
        if (!this.options) {
            return;
        }
        if (this.logQueue.length <= 0) {
            return;
        }
        // 初次上报之前都暂时存一下比较稳妥
        if (!this.startedLoadReport) {
            return;
        }

        const h5_extra_attr = JSON.stringify({
            ...this.weblog.commomPackage.getH5ExtraAttr(),
            // radar自定义新增非value字段可以加在这里
            // 获取客户端版本
            app_version_name: this.weblog.commomPackage.getVersionName(),
            url: location.href

        });

        // 判断端环境
        // 数据侧在业务设置了pagename的时候无法获取到真实的url，雷达这块重新写一次忽略pagename的逻辑比较复杂 故提供一个新的字段
        // radar_h5_extra_attr和radar_custom_extra_attr 共有字段app_version_name
        // 上报位置 custom_stat_event.value和h5_extra_attr
        const commonLogData = {
            project_id: this.options.projectId,
            radar_session_id: this.radarSessionId,
            h5_extra_attr
        };
        const { commomPackage: { service_name, sub_biz, need_encrypt, app_package } } = this.weblog;
        // @ts-ignore
        const currentUrlPackage = this.currentUrlPackage && this.currentUrlPackage.toJSON();
        // const key = this.isUsingLegacy ? LEGACY_KEY : KEY;
        // 尝试先直接桥接上报
        let sended = null;
        // 如果配置了优先http上报则不使用bridge上报数据
        if (!this.options.httpReportFirst) {
            sended = this.addRadarStatEvent({
                key: KEY,
                value: {
                    ...commonLogData,
                    data: this.logQueue,
                    // @ts-ignore
                    url_package: currentUrlPackage,
                    app_version_name: app_package.version_name
                },
                serviceName: service_name || '',
                subBiz: sub_biz || '',
                needEncrypt: need_encrypt || false,
                container: app_package.container,
                realtime: false,
                // 统一改成下划线格式
                h5ExtraAttr: h5_extra_attr
            });
        }
        // 桥接上报失败使用 weblogger 自定义事件上报
        if (!sended || this.options.httpReportFirst) {
            this.weblog.collect('RADAR', {
                name: KEY,
                params: {
                    ...commonLogData,
                    data: this.logQueue,
                },
                currentUrlPackage,
                // @ts-ignore
                referUrlPackage: this.referUrlPackage && this.referUrlPackage.toJSON(),
            });
        }
        this.logQueue = [];
    };
    upadteUrlPackage() {
        if (!this.weblog) return;
        this.computedSamplingAgain = true;
        // 通过 toJSON 确保 page 和 params 是日志产生时的信息
        // @ts-ignore
        this.currentUrlPackage = this.weblog.currentUrlPackage;
        // @ts-ignore
        this.referUrlPackage = this.weblog.referUrlPackage;
    }
    decorateLog(kv: KVPair) {
        if (!this.currentUrlPackage) {
            this.upadteUrlPackage();
        }

        if (this.customDimension) {
            this.subDimension(kv);
        }
        // if (this.isUsingLegacy) {
        //     // @ts-ignore
        //     kv.key = kv.key + '_statistics';
        // }

        // 由于 url 是日志公参，因此 url 发生变化时直接冲刷日志
        if (this.currentUrlPackage && this.currentUrlPackage.page !== this.weblog.currentUrlPackage.page) {
            this.flush();
            this.upadteUrlPackage();
        }
    }

    logCollect(kv: KVPair) {
        radarWarnLog('radarLog', kv);
        if (!this.options) {
            return;
        }
        // 不再防抖 改为节流
        // if (this.batchTimer) {
        //     clearTimeout(this.batchTimer);
        // }

        this.decorateLog(kv);
        // 当已经收集到load数据时 不会继续push 防止同时收集到多条load数据 适用于bridge和http上报
        if (kv.key === 'load') {
            const lastLoadData = this.logQueue.find(v => v.key === 'load');
            lastLoadData ? Object.assign(lastLoadData, kv) : this.logQueue.push(kv);
        } else {
            this.logQueue.push(kv);
        }
        // 上报窗口期内 不再创建定时器 只压入队列
        // flush判断当前情况是否可上报 包括采样，空队列等情况
        if (this.batchTimer) {
            return;
        }
        if (this.logQueue.length > this.queueConfig.maxBatchLength) {
            this.flush();
            return;
        }
        // 每隔wait秒上报一次数据 并在pagehide时上报所有logqueue里的数据
        this.batchTimer = window.setTimeout(() => {
            this.flush();
            clearTimeout(this.batchTimer);
            this.batchTimer = 0;
        }, this.queueConfig.wait);
    }

    addRadarStatEvent(log: CustomStatEventOptions) {
        try {
            const { Utils: { yoda } } = this.weblog;
            if (!yoda) { return false; }
            const { loadLog, commonLog } = formatYodaRadarData(log);
            if (loadLog) {
                if (this.lastRadarLogId) {
                    loadLog.removeStashedLog = [this.lastRadarLogId];
                    // 如果报进来的是一条sendImmediate为true的数据 且在之前已经上报过一条 load数据
                    // 则需要覆盖掉上一条load 保证客户端在destory的时候只上报一次load数据
                    if (loadLog.sendImmediate) {
                        // 当本条load数据是sendImmediate = true时 清除上一条false的数据
                        const empytLoadData = {
                            removeStashedLog: [this.lastRadarLogId],
                            sendImmediate: false,
                            custom: { "": "" }
                        };
                        yoda.sendRadarLog(empytLoadData).then((res: any) => {
                            if (res && res.logId) {
                                this.lastRadarLogId = res.logId;
                            }
                        });
                    }
                }

                yoda.sendRadarLog(loadLog).then((res: any) => {
                    if (res && res.logId) {
                        this.lastRadarLogId = res.logId;
                    }
                });
            }

            if (commonLog) {
                yoda.sendRadarLog(commonLog);
            }
            return true;
        } catch (err) {
            return false;
        }
    };
}
