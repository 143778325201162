import { Base } from '../proto/base';
import { BaseOptions } from './types';

export const BASE: BaseOptions = {
    device_id: '',
    global_id: '',
    union_id: '',
    user_id: '',
    session_id: '',
    product: 0,
    channel: '',
    version_name: '',
    package_name: '',
    product_name: '',
    network_type: 'unknown',
    container: Base.Container.H5,
    service_name: '',
    sub_biz: '',
    need_encrypt: false,
    safety_id: '',
    experiment: [],
    h5_extra_attr: {},
};

export const initPackageKeys = [
    {
        keys: ['identity_package', 'device_id'],
        type: 'string',
        validator: (val: any) => typeof val === 'string',
    },
    {
        keys: ['identity_package', 'user_id'],
        type: 'string',
        validator: (val: any) => typeof val === 'string',
    },
    {
        keys: ['identity_package', 'union_id'],
        type: 'string',
        validator: (val: any) => typeof val === 'string',
    },
    {
        keys: ['identity_package', 'global_id'],
        type: 'string',
        validator: (val: any) => typeof val === 'string',
    },
    {
        keys: ['app_package', 'product'],
        type: 'number',
        validator: (val: any) => typeof val === 'number',
    },
    {
        keys: ['app_package', 'channel'],
        type: 'string',
        validator: (val: any) => typeof val === 'string',
    },
    {
        keys: ['app_package', 'version_name'],
        type: 'string',
        validator: (val: any) => typeof val === 'string',
    },
    {
        keys: ['app_package', 'package_name'],
        type: 'string',
        validator: (val: any) => typeof val === 'string',
    },
    {
        keys: ['app_package', 'product_name'],
        type: 'string',
        validator: (val: any) => typeof val === 'string',
    },
    {
        keys: ['app_package', 'platform'],
        type: 'number',
        validator: (val: any) => typeof val === 'number',
    },
    {
        keys: ['experiment'],
        type: 'Array',
        validator: (val: any) => val instanceof Array,
    },
    {
        keys: ['service_name'],
        type: 'string',
        validator: (val: any) => typeof val === 'string',
    },
    {
        keys: ['sub_biz'],
        type: 'string',
        validator: (val: any) => typeof val === 'string',
    },
    {
        keys: ['need_encrypt'],
        type: 'boolean',
        validator: (val: any) => typeof val === 'boolean',
    },
    {
        keys: ['safety_id'],
        type: 'string',
        validator: (val: any) => typeof val === 'string',
    },
    {
        keys: ['h5_extra_attr'],
        type: 'Object',
        validator: (val: any) => val instanceof Object,
    },
];
