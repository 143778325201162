/**
 * @file 资源页面的收集
 */
import RadarPlugin from './plugin';
import { CustomEntry, KVPair } from './interface';
import { resPerfMap, RADAR_KEY } from './const';
import { isInURLWhiteList } from './utils';
import { addMonitor, removeMonitor, composedPath } from '../../util';

// const LOAD_REPORT_DELAY = 15 * 1000; // 加载上报日志

export default class Resource extends RadarPlugin {
    public key = 'resource';

    created() {
        // Nothing
        addMonitor(window, 'error', this.onResError as EventListener, true);
    }

    when(entry: PerformanceEntry) {
        if (isInURLWhiteList(entry.name as string, this.radar.ignoreList)) {
            return false;
        }
        // load 这里可以接受自定义指标
        return entry.entryType === 'resource' && (entry.initiatorType !== 'fetch' && entry.initiatorType !== 'xmlhttprequest');
    }
    // 劫持 window onerror
    private isScriptError(e: ErrorEvent) {
        // 有 message 基本上认为是脚本异常了
        return e.message || e.lineno != null;
    }
    // 劫持 window.addEventListener('error')
    private onResError = (e: ErrorEvent & PromiseRejectionEvent) => {
        const isScriptError = this.isScriptError(e);
        if (!isScriptError) {
            // resource报错 需要遵循resource插件的采样逻辑
            // 非脚本报错
            this.radar.logCollect(this.sepDimension({
                key: RADAR_KEY.RES,
                value: {
                    failed: true,
                    file: (e.target as HTMLImageElement).src,
                    // cached: false,
                    // @ts-ignore
                    res_path: composedPath(e.path || (e.composedPath && e.composedPath())),
                },
            }));
        }
    };
    onPerfReport(entry: PerformanceEntry | CustomEntry | PerformanceEntry[]) {
        const logData = this.radar.generateLog(resPerfMap, entry as PerformanceEntry);

        this.radar.logCollect(this.sepDimension({
            key: RADAR_KEY.RES,
            value: logData,
        }));
    }

    destroy() {
        // Nothing
        removeMonitor(window, 'error', this.onResError as EventListener, true);
    }

    private sepDimension(kv: KVPair) {
        const { key } = kv;
        const { protocol, file, cached, res_path, failed, res_type, ...value } = kv.value;
        const dimension = { protocol, file, cached, failed, res_path, res_type };
        return { key, value, dimension };
    }
}
