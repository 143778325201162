/**
 * @file 使用xhr发送log
 *
 * @author 天翔Skyline(skyline0705@gmail.com)
 * May 14, 2019
 */
import { DataOptions } from './types';

export default function send(
    { url, data, timeout }: DataOptions,
    callback?: (error?: any) => void,
) {
    const xhr = new XMLHttpRequest();
    // async 同步情况会报错，而且影响性能，取消使用同步
    xhr.open('POST', url);
    if (timeout) {
        xhr.timeout = timeout;
    }
    xhr.onload = () => {
        let err;
        if (xhr.status < 200 || (xhr.status >= 300 && xhr.status !== 304)) {
            err = xhr.response || xhr.status || 'unknonw error';
        }
        callback && callback(err);
    };

    xhr.ontimeout = xhr.onerror = e => {
        callback && callback(e);
    };
    xhr.send(data);
}
