/**
 * @file crash-monitor.js
 * @author kevin (lanlazy@163.com)
 * beforeunload触发条件:正常关闭标签,刷新,关闭浏览器
 * @created 2018-08-08
 */
import { AutoPVFilter, Weblogger } from '../../config/types';
import { BasePlugin, autoRegister } from '../base';
import navigationTiming from '../../util/timing';
import { addMonitor, removeMonitor, getMappingPage, urlChangeEventId } from '@/util';

if (!navigationTiming) {
    console.warn('Your browser don\'t support performance API, the auto pv duration may not correct');
}
let willunload = false;
export default class AutoPV extends BasePlugin {
    static key = 'autoPV';
    private oldPushState = history.pushState;
    private oldReplaceState = history.replaceState;
    private beginTime = navigationTiming && navigationTiming.fetchStart
        || new Date().valueOf();
    private autoPVFilter: AutoPVFilter;
    /**
     * 兼容写法，如果在 Weblog 初始化设置 plugins 时，不需要设置 weblog
     * @param weblog
     * @param autoPVFilter
     */
    constructor(autoPVFilter?: AutoPVFilter)
    constructor(weblog?: Weblogger | AutoPVFilter, options?: {autoPVFilter?: AutoPVFilter}) {
        super();
        this.autoPVFilter = () => true;
        // 兼容处理
        if (weblog) {
            if (typeof weblog === 'function') {
                this.autoPVFilter = weblog;
            } else if (weblog.logger) {
                this.apply(weblog);
            }
        }
        if (options && typeof options.autoPVFilter === 'function') {
            this.autoPVFilter = options.autoPVFilter;
        }
    }

    apply(weblog: Weblogger) {
        if (!weblog || !weblog.logger) return;
        this.weblog = weblog;
        setTimeout(() => {
            if (this.autoPVFilter(location.href, null, 'enter')) {
                const { page, params } = getMappingPage({ url: location.href }, this.weblog.logConfig.urlMap);
                this.weblog.collect('PV', {
                    type: 'enter',
                    beginTime: this.beginTime,
                    auto: true,
                    page,
                    params
                });
            }
        });
        history.pushState = this.proxyPushState;
        history.replaceState = this.proxyReplaceState;
        addMonitor(window, urlChangeEventId, this.urlChange);
        addMonitor(document, 'visibilitychange', this.visibilityChange);
    }

    private visibilityChange = () => {
        const state = document.visibilityState;
        if (state === 'visible') {
            willunload = false;
        }
        // 页面关闭也会触发 hidden 事件，这种情况下不再上报事件
        if (!this.weblog || willunload) return;
        if (this.autoPVFilter(location.href, null, state)) {
            if (state === 'hidden' || state === 'visible') {
                this.weblog.collect('PV', {
                    type: state,
                    beginTime: this.beginTime,
                    auto: true,
                });
                if (state === 'visible') {
                    // 更新新页面 enter 时间
                    this.beginTime = new Date().valueOf();
                }
            }
        }
    }

    private proxyPushState = (data: any, title: string, url?: string | null | undefined) => {
        this.urlChange();
        return this.oldPushState.call(history, data, title, url);
    }

    private proxyReplaceState = (data: any, title: string, url?: string | null | undefined) => {
        this.urlChange();
        return this.oldReplaceState.call(history, data, title, url);
    }

    private urlChange = () => {
        setTimeout(() => {
            if (!this.weblog) return;
            const { page, params } = getMappingPage({url: location.href}, this.weblog.logConfig.urlMap);
            if (page && page === this.weblog.currentUrlPackage.page) {
                this.weblog.currentUrlPackage.update(page, params);
                return;
            };
            if (this.autoPVFilter(location.href, this.weblog.currentUrl, 'leave')) {
                // 触发旧页面离开
                this.weblog.collect('PV', {
                    type: 'leave',
                    beginTime: this.beginTime,
                    auto: true
                });
            }

            if (this.autoPVFilter(location.href, this.weblog.currentUrl, 'enter')) {
                // 更新当前页面信息
                this.weblog.updateReferUrlPackage();
                this.weblog.updateCurrentUrlPackage(location.href);
                // 更新新页面 enter 时间
                this.beginTime = new Date().valueOf();
                this.weblog.collect('PV', {
                    type: 'enter',
                    auto: true,
                });
            }
        })
    }

    beforeUnload = () => {
        willunload = true;
        if (!this.weblog || !this.autoPVFilter('', this.weblog.currentUrl, 'leave')) {
            return;
        }
        this.weblog.sendImmediately('PV', {
            type: 'leave',
            beginTime: document.visibilityState === 'hidden' ? new Date().valueOf() : this.beginTime,
            auto: true,
        });
    }

    destroy() {
        removeMonitor(window, urlChangeEventId, this.urlChange);
        removeMonitor(document, 'visibilitychange', this.visibilityChange);
        history.pushState = this.oldPushState;
        history.replaceState = this.oldReplaceState;
    }
}
