/**
 * 兼容 SSR、react native、worker 等非浏览器场景
 */

const commonGlobal: any = typeof globalThis !== 'undefined' ? globalThis : typeof window !== 'undefined' ? window : typeof global !== 'undefined' ? global : typeof self !== 'undefined' ? self : {};
(() => {
    if (typeof commonGlobal.window === 'undefined') {
        console.log('weblogger is running in non-browser env');
        commonGlobal.window = {
            addEventListener: () => {},
            removeEventListener: () => {},
            setTimeout: () => {},
            setInterval: () => {},
            _WEBLOGGER_MOCKED_WINDOW_: true,
            global: commonGlobal
        };
    }
    if (typeof commonGlobal.document === 'undefined') {
        commonGlobal.document = commonGlobal.window.document || {
            cookie: '',
            addEventListener: () => {},
            removeEventListener: () => {},
            querySelector: () => {},
            querySelectorAll: () => {},
        };
        if (typeof commonGlobal.window.document === 'undefined') {
            commonGlobal.window.document = commonGlobal.document;
        }
    }
    if (typeof commonGlobal.navigator === 'undefined') {
        commonGlobal.navigator = commonGlobal.window.navigator || {
            userAgent: ''
        };
        if (typeof commonGlobal.window.navigator === 'undefined') {
            commonGlobal.window.navigator = commonGlobal.navigator;
        }
    }
    if (typeof commonGlobal.screen === 'undefined') {
        commonGlobal.screen = commonGlobal.window.screen || {};
        if (typeof commonGlobal.window.screen === 'undefined') {
            commonGlobal.window.screen = commonGlobal.screen;
        }
    }
    if (typeof commonGlobal.history === 'undefined') {
        commonGlobal.history = commonGlobal.window.history || {};
        if (typeof commonGlobal.window.history === 'undefined') {
            commonGlobal.window.history = commonGlobal.history;
        }
    }
    if (typeof commonGlobal.location === 'undefined') {
        commonGlobal.location = commonGlobal.window.location || {
            hostname: '',
            search: '',
            href: '',
            origin: ''
        };
        if (typeof commonGlobal.window.location === 'undefined') {
            commonGlobal.window.location = commonGlobal.location;
        }
    }
    try {
        if (typeof commonGlobal.localStorage === 'undefined') {
            commonGlobal.localStorage = commonGlobal.window.localStorage || {
                getItem: () => {},
                setItem: () => {}
            };
            if (typeof commonGlobal.window.localStorage === 'undefined') {
                commonGlobal.window.localStorage = commonGlobal.localStorage;
            }
        }
    } catch (err){};
})();