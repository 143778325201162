/**
 * @file crash-monitor.js
 * @author kevin (lanlazy@163.com)
 * beforeunload触发条件:正常关闭标签,刷新,关闭浏览器
 * @created 2018-08-08
 */

import { Weblogger } from '../../config/types';
import { BasePlugin, autoRegister } from '../base';
import { addMonitor, removeMonitor } from '../../util';

const defaultOptions = {
    updateInterval: 20 * 1000,
    crashThreshold: 10 * 1000,
};

export default class CrashMonitor extends BasePlugin {
    private eventName = 'onpagehide' in window ? 'pagehide' : 'beforeunload';
    static key = 'crachMonitor';
    private options = {
        ...defaultOptions,
    };
    private readonly monitorId = '@ks.monitor.crash';
    private updateIntervalId!: number;
    private historyMoniorInfo: any = null;
    /**
     * 兼容写法，如果在 Weblog 初始化设置 plugins 时，不需要设置 weblog
     * @param weblog 
     */
    constructor(weblog?: Weblogger) {
        super();
        try {
            const historyMoniorString = localStorage.getItem(this.monitorId);
            this.historyMoniorInfo = JSON.parse(historyMoniorString as string) || {};
        } catch (e) {
            // nothing
        }
        if (weblog && (weblog as Weblogger).logger) {
            this.apply(weblog as Weblogger);
        }
        addMonitor(window, this.eventName, this.stop);
    }
    apply(weblog: Weblogger) {
        if (!weblog) return;
        this.weblog = weblog;
        this.on('crash', info => {
            this.weblog.sendImmediately('CUSTOM', 'crash', info);
        });
        this.start();
    }
    destroy() {
        this.stop();
        this.off('crash');
        removeMonitor(window, this.eventName, this.stop);
    }
    checkHistoryCrash() {
        const now = new Date().valueOf();
        if (
            this.historyMoniorInfo
            && now - this.historyMoniorInfo.time > this.options.crashThreshold
        ) {
            this.emit('crash', this.historyMoniorInfo);
        }
    }
    /**
     * 开始定时更新快照
     */
    start() {
        this.checkHistoryCrash();
        this.updateIntervalId = window.setInterval(() => {
            this.update();
        }, this.options.updateInterval);
        this.update();
    }
    /**
     * 更新监控快照
     * @param {*} data 外部追加额外的快照信息
     */
    update() {
        // TODO，非标准api后续可能会需要更新
        if (!window.performance || !window.performance.memory) {
            return;
        }
        // TODO后面看看需要补其他什么信息
        const monitorInfo = {
            time: new Date().valueOf(),
            memory: {
                limit: window.performance.memory.jsHeapSizeLimit / 1024,
                total: window.performance.memory.totalJSHeapSize / 1024,
                used: window.performance.memory.usedJSHeapSize / 1024,
            },
        };
        try {
            localStorage.setItem(this.monitorId, JSON.stringify(monitorInfo));
        } catch (e) {
            // nothing
        }
    }
    /**
     * 停止监控过程
     * 1.在页面正常卸载时自动调用
     * 2.需要手动卸载时手动调用
     */
    stop = () => {
        clearInterval(this.updateIntervalId);
        try {
            localStorage.removeItem(this.monitorId);
        } catch (e) {
            // nothing
        }
    }
}