import { Base } from '../base';

export namespace ClientEvent {
    // 页面分类
    export enum PageCategory {
        UNKNOWN1 = 0,
        APP = 1, // APP
        HOME = 2, // 首页
        USER = 3, // 用户
        VIDEO = 4, // 短视频
        LIVE_STREAM = 5, // 直播
        PAY = 6, // 支付
        ADS = 7, // 广告
        SEARCH = 8, // 搜索页
        IMAGE = 9, // 图片
    }

    export interface ExpTagTrans {
        server_exp_tag: string; // 服务端下发，客户端只透传，不修改
        client_exp_tag: string; // 客户端信息（例如表示交互方式，作品是上滑进入，还是点击进入）
    }

    export interface ExpTagTransList {
        exp_tag_trans: ExpTagTrans[]
    }

    // 页面信息
    export interface UrlPackage extends Base.UrlPackage {
        category?: PageCategory; // 页面分类
        page2: string; // 一级页面。参考埋点需求填写即可
        page_seq?: number; // 页面创建的Sequence id，每创建一个新的页面就加1，与页面实例绑定。主要是配合ShowEvent中的page_show_seq使用
        entry_page_id?: string; // 入口id，正常启动的首页三大tab、侧边栏、首页进入视频拍摄及push、外链进入的落地页，均视为不同入口
        entry_page_source?: string; //入口页来源，从首页三个tab进入的详情页和直播页的source为相应tab页name(FIND, FOLLOW等)，push、外链进入的source为(PUSH, URI)，拍摄页source为进入时拍摄页当前page的name(RECORD_CAMERA等)，侧边栏进入页面的source为MENU，诸如此类
        exp_tag_list?: ExpTagTransList; // feed消费栈。页面栈中，有消费feed的页面，则将该feed的exp_tag入栈，栈顶为最近一个消费feed的exp_tag
    }

    export enum ElementType {
        UNKNOWN1 = 0, // 未知
        BUTTON = 1, // 按钮
        LABEL = 2, // 标签
        ICON = 3, // 图标, 暂时废弃不用!
        IMAGE = 4, // 图片, 例: 作品封面
        MENU = 5, // 菜单条目
        LINK = 6, // 链接
        TAB = 7, // Tab
        INPUT_BOX = 8, // 输入框
        OPTION_BOX = 9, // 选择框
        NOTIFICATION = 10, // 通知栏
        CARD = 11, // 卡片, 暂时废弃不用!
        VIEW = 12, // 视图
        PAGE = 13, // 页面
        VIDEO = 14, // 视频
        CELL = 15, // 列表中一个元素
        BANNER = 16, // Banner
        BUBBLE = 17, // 引导气泡
        POPUP_WINDOW = 18, // 浮层
    }

    // 页面元素详细信息
    export interface ElementPackage extends Base.ElementPackage {
        type?: ElementType; // 元素类型（废弃，统一使用元素自定义参数上报）
        name?: string; // 元素名称（废弃，统一使用元素自定义参数上报）
        action2: string; // 业务类型字符串版本
    }

    export enum ShowType {
        UNKNOWN2 = 0, // 未知
        PAGE = 1, // 页面
        SUB_PAGE = 2, // 子页面
        ITEM = 3, // 条目
        DIALOG = 4, // 对话框
        BANNER = 5, // Banner
        BUTTON = 6, // 按钮
        BUBBLE = 7, // 引导气泡
        VIDEO = 8, // 视频
        LIVE = 9, // 直播
        POPUP_WINDOW = 10, // 浮层
    }

    export enum ShowActionType {
        UNKNOWN4 = 0, // 未知
        CLICK = 1, // 点击(除home键、系统返回、左上角图标之外的键)进入或离开页面
        LEFT_PULL = 2, // 左滑进入或离开页面
        RIGHT_PULL = 3, // 右滑进入或离开页面
        BACK_CLICK = 4, // 页面返回点击
        UP_SLIDE = 5, // 上滑进入或离开页面
        DOWN_SLIDE = 6, // 下滑进入或离开页面
        DOWN_PULL = 7, // 下拉进入或离开页面
        CLICK_HOME = 8, // 点击home键离开页面
        CLICK_SYS_BACK = 9, // 点击系统返回键进入或离开页面
        CLICK_UPLEFT_ICON = 10, // 点击左上角进入或离开页面
        COLD_LAUNCH = 11, // 冷启动
    }

    //页面区域信息   页面+区域+元素
    export interface AreaPackage {
        name: string; //区域名称
        params: string; //区域补充参数
    }

    // 曝光事件
    export interface ShowEvent extends Base.ShowEvent {
        type?: ShowType;
        show_type?: ShowActionType; // 记录进入或离开页面的方式
        page_show_seq?: number; // 页面展示的Sequence id，每次展示一次页面就加1(页面展示事件才有意义)
        content_wrapper?: string; // 上报业务相关信息，客户端将content_wrapper转成json上报
        extra_message?: string; // 用于存放一些点击时候的额外信息，可以是 json, string 分割等，此字段仅限于客户端内部分析使用！
        area_package?: AreaPackage; // 页面区域信息
    }

    // 任务结果分组
    export enum ResultPackageDomain {
        UNKNOWN2 = 0, // 未知
        HTTP = 1, // HTTP
        SYSTEM = 2, // 系统调用
        KUAISHOU = 3, // 快手API
        KINGSOFT_LIVE_PUSH = 4, // 金山直播推流
        KINGSOFT_LIVE_PLAY = 5, // 金山直播拉流
    }
    // 任务结果
    export interface ResultPackage {
        domain: ResultPackageDomain; // 返回码分组
        code: number; // 返回码
        message: string; // 详细信息
        time_cost: number; // 任务时长ms
        subdomain: string; // 自定义，可以为空
    }

    // 任务事件
    export interface TaskEvent extends Base.TaskEvent {
        action2: string;
        trigger?: number; // 任务触发器, 例如: 在送礼时触发支付, 在私信页面触发注册
        result_package?: ResultPackage; // 任务结果
        content_package?: any; // 相关内容，TODO: 比较复杂，暂时没写
        task_detail_package?: any; // 相关内容，TODO: 比较复杂，暂时没写
        ratio?: number; // 当前log的抽样统计比例 浮点数
        content_wrapper?: string; // 上报业务相关信息，客户端将content_wrapper转成json上报
        area_package?: AreaPackage;
    }

    export enum ClickType {
        UNKNOWN1 = 0,
        CLICK = 1, // 点击
        DOUBLE_CLICK = 2, // 双击
        TRIPLE_CLICK = 3, // 三击
        LONG_PRESS = 4, // 长按
        PULL = 5, // 滑动
        DRAG = 6, // 拖拽
        SCALE = 7, // 双指缩放
        PULL_DOWN = 8, // 下拉刷新
        PULL_UP = 9, // 上拉加载更多
        RIGHT_CLICK = 10, // 鼠标右键单击
        AUTO = 11, //自动
    }
    export interface ClickEvent extends Base.Event {
        type: ClickType; // 点击类型
        direction: Base.Direction; // Action={PULL, DRAG}时有意义
        url_package: Base.UrlPackage; // 页面
        element_package: Base.ElementPackage; // 所操作的元素信息
        content_package?: any; // 相关内容，TODO: 比较复杂，暂时没写
        extra_message?: any; // 用于存放一些点击时候的额外信息，可以是 json, string 分割等，此字段仅限于客户端内部分析使用！
        content_wrapper?: string; // 上报业务相关信息，客户端将content_wrapper转成json上报
        area_package?: AreaPackage; // 页面区域信息
    }
    
    export interface CustomEvent extends Base.CustomEvent {

    }

    export interface EventPackage {
        show_event?: ShowEvent;
        click_event?: ClickEvent;
        task_event?: TaskEvent;
        custom_event?: CustomEvent
    }
}