/**
 * @file timing.js
 * @author kevin (lanlazy@163.com)
 * @created 2018-08-25
 */
import {
    PerformanceTimingProperty,
    TimingPerfMap,
    TimingPerfName,
    TimingPerf,
    TimingCustomOptions,
} from '../types';
import { addMonitor, removeMonitor } from '../../util';
import { Weblogger } from '../../config/types';
import { BasePlugin, autoRegister } from '../base';
import navigationTiming from '../../util/timing';
import { error } from '../../util/console';

// TODO页面停留时间先依赖一个全局副作用来搞，毕竟要侵入SPA模式下比较蛋疼……
const perfMap: TimingPerfMap = {
    // DNS查找时间
    dnsLookup: {
        end: 'domainLookupEnd',
        start: 'domainLookupStart',
    },
    // TCP建连时间
    tcpConnection: {
        end: 'connectEnd',
        start: 'connectStart',
    },
    // 静态资源加载时间
    resourceLoad: {
        end: 'responseEnd',
        start: 'requestStart',
    },
    // 首屏JS耗时……暂时先这么对付了，具体JS的耗时后面再提供吧
    // 计算的时候以第一个script开始时间点为起始，一直到DOM Complete完成
    // 这样看起来应该是可被定义为JS耗时了……
    jsCost: {
        custom() {
            if (typeof performance.getEntries !== 'function') {
                return 0;
            }
            const entries = performance.getEntries();
            const pageTimeInfo = entries[0];
            const firstJSTimeInfo = entries
                .filter(item => item.initiatorType === 'script')[0];
            if (firstJSTimeInfo) {
                return pageTimeInfo.domComplete - firstJSTimeInfo.fetchStart;
            }
            return 0;
        },
    },
    // DOM加载完毕时间
    documentContentLoaded: {
        end: 'domComplete',
        start: 'fetchStart',
    },
    // 页面加载完毕时间
    load: {
        end: 'loadEventEnd',
        start: 'fetchStart',
    },
    timeToFirstByte: {
        end: 'loadEventEnd',
        start: 'fetchStart',
    },
    // 首屏时间，依靠主动打点
    firstScreen: {
        custom(key: TimingPerfName, options?: TimingCustomOptions) {
            const { endPoint } = options || {};
            const end = typeof endPoint !== 'undefined' ? endPoint : new Date().valueOf();
            return navigationTiming && (end - navigationTiming.fetchStart) || 0;
        },
    },
    // 白屏时间，依靠主动打点
    whiteScreen: {
        custom(key: TimingPerfName, options?: TimingCustomOptions) {
            const { endPoint } = options || {};
            const end = typeof endPoint !== 'undefined' ? endPoint : new Date().valueOf();
            return navigationTiming && (end - navigationTiming.fetchStart) || 0;
        },
    },
};

export default class TimingMonitor extends BasePlugin {
    static key = 'timingMonitor';
    /**
     * 兼容写法，如果在 Weblog 初始化设置 plugins 时，不需要设置 weblog
     * @param weblog
     */
    constructor(weblog?: Weblogger) {
        super();
        if (!navigationTiming) {
            error('The Timing Monitor need performance APIs to Support!');
            return;
        }
        if (weblog) {
            this.apply(weblog);
        }
    }

    apply(weblog: Weblogger) {
        this.weblog = weblog;
        addMonitor(window, 'load', this.load);
        this.on('perf', ({ key, data }: { key: string, data: any; }) => {
            this.weblog.collect('CUSTOM', costMap[key as keyof typeof costMap], {
                [costMap[key as keyof typeof costMap]]: data[key],
            });
        });
    }

    load = () => {
        // 严格意义上来说类似的时间对于onload计算，都是等到window.onload执行完毕才开始计算
        // 所以页面加载完毕时间使用的是loadEventEnd事件，所以手动进入下一个event loop
        // 确保获取loadEventEnd事件
        setTimeout(() => {
            const perfInfo = this.collect();
            if (!perfInfo) {
                return;
            }
            [
                'load',
                'documentContentLoaded',
                'dnsLookup',
                'tcpConnection',
                'jsCost',
                'timeToFirstByte',
            ].forEach(key => {
                // TODO后面做一个支持度list来维护，现在先手动过滤一下
                if (key === 'jsCost' && perfInfo[key as TimingPerfName] === 0) {
                    return;
                }
                this.weblog.collect('CUSTOM', costMap[key as keyof typeof costMap], {
                    [costMap[key as keyof typeof costMap]]: perfInfo[key as TimingPerfName],
                });
            });
        });
    };

    destroy() {
        removeMonitor(window, 'load', this.load);
        this.off('perf');
    }

    calculate(key: TimingPerfName, options?: TimingCustomOptions) {
        const perf = perfMap[key];
        if (!perf) {
            throw new Error('The perf key is not correct!');
        }
        if (typeof perf.custom === 'function') {
            return perf.custom(key, options);
        }
        const endPoint = navigationTiming[perfMap[key].end as PerformanceTimingProperty];
        const startPoint = navigationTiming[perfMap[key].start as PerformanceTimingProperty];
        return endPoint - startPoint;

    }
    // 目前只针对这两个需要手动打点;
    mark(key: 'firstScreen' | 'whiteScreen', options?: TimingCustomOptions) {
        const data = this.collect(key, options);
        this.emit('perf', {
            key,
            data,
        });
    }
    collect(key?: TimingPerfName, options?: TimingCustomOptions) {
        // 如果不支持timing api的话，就直接不上报了，免得数据污染
        if (!navigationTiming) {
            return;
        }
        if (key) {
            return {
                [key]: this.calculate(key, options),
            };
        }
        const allPerf: TimingPerf = {};
        return Object.keys(perfMap).reduce((map, key) => {
            map[key as TimingPerfName] = this.calculate(key as TimingPerfName, options as TimingCustomOptions);
            return map;
        }, allPerf);
    }
}

// 优先使用pagehide 其次使用beforeunload;
const costMap = {
    load: 'total_download_cost',
    documentContentLoaded: 'operational_cost',
    dnsLookup: 'dns_query_cost',
    tcpConnection: 'tcp_cost',
    jsCost: 'js_cost',
    whiteScreen: 'white_screen_cost',
    firstScreen: 'first_screen_cost',
    timeToFirstByte: 'ttfb_cost',
};

/**
 * 如果使用了 bootstrap 则自动注册插件
 */
autoRegister(TimingMonitor);