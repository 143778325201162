import { Base } from '../proto/base';
import { CustomStatEventOptions } from '../config/types';

/**
 * 自定义事件上报
 */
export default (options: CustomStatEventOptions): Base.CustomEvent => {
    const { name, params, currentUrlPackage, referUrlPackage, ...extra } = options;
    return {
        key: name,
        value: JSON.stringify({
            url_package: currentUrlPackage,
            refer_url_package: referUrlPackage,
            ...params,
            ...extra,
        })
    };
}
