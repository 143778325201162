import Logger, { LogPackage } from './logger';
import initConfig, { LogConfig } from '../config';
import Global, { updateConfig } from '../config/global';
import { UrlMap, UrlObj, UrlMapFn, BaseOptions, TaskOperations, EventTypes, PVOptions, ShowOptions, TaskOptions, CustomOptions, OtherEventOptions, ContentPackage } from '../config/types';
import { logFactory } from './log';
import { BASE } from '../config/base-params';
import UrlPackage, { PageTypeKey } from './url-package';
import { BasePlugin, BasePluginConstructor } from '../plugins/base';
import EventEmitter from '../util/events';
import { stringifyQuery, addMonitor, removeMonitor } from '../util';
import CommonPackage, { CommonPackageOptions, transformCommonPackage } from './common';
import { ClientLog as ClientLogV3 } from '../proto/v3/client_log';
import cookieUtils, { getCookie } from '../util/cookie';
import { uuid } from '../util/uuid';
import yodaUtils, { YodaInterface, checkYoda, initYoda, KSwitchSampling, getKSwitchSampling } from '../util/yoda';
import uaUtils from '../util/ua';
import sendData from '../io';

export interface SendConfig {
    type: string;
    async?: boolean;
}

type SendType = string | SendConfig;

let pluginIndex = 0;
// session_id 用来串联在一次打开 App 期间跳转不同页面的用户行为
export const getSessionId = () => {
    let sessionId: string = '';
    const defaultKey = 'session_id';
    // const customKey = 'weblogger_session_id';
    sessionId = getCookie(defaultKey) || getCookie('sid'); // 端内从 cookie 中获取客户端传来的 session_id
    if (sessionId) return sessionId;
    const defaultSessionId = `weblogger_${uuid('weblogger')}`;
    // try {
    //     if (typeof window !== 'undefined' && window.sessionStorage) {
    //         sessionId = window.sessionStorage.getItem(defaultKey) // 尝试获取服务端传来的 session_id
    //             || window.sessionStorage.getItem(customKey) || ''; // 如果都没设置，则获取 weblogger 生成的 session_id;
    //         if (sessionId) return sessionId;
    //         // 如果没有获取到 session_id 则生成一个，并设置到 sessionStorage 中。
    //         window.sessionStorage.setItem(customKey, defaultSessionId);
    //     }
    // } catch (err) { }
    return defaultSessionId;
};
let isWillClosed = false;
export type IWeblog = typeof Weblog;
export default class Weblog<T> extends EventEmitter {
    version: string = '__VERSION__';
    currentUrlPackage!: UrlPackage;
    referUrlPackage?: UrlPackage;
    commomPackage: CommonPackage;
    baseOption: BaseOptions;
    logConfig: LogConfig & T;
    yoda: YodaInterface | null = null;
    SampledPageMap: { [key: string]: boolean | { radar: { [key: string]: number; }; }; } = {};
    // 核心日志发送
    logger = new Logger();
    // 插件
    plugins: {
        [key: string]: BasePlugin | any;
    } = {};
    /**
     * @param {?object} logConfig Logger初始化配置
     * @param {object=} options 额外配置参数
     *                    - base 公参的覆盖
     */
    constructor(
        logConfig: LogConfig & T,
        base?: BaseOptions,
    ) {
        super();
        // 设置全局 session_id
        updateConfig('sessionId', base && base.session_id || getSessionId());
        this.baseOption = { ...BASE, ...base };
        this.logConfig = initConfig(logConfig) as LogConfig & T;
        this.initUrlPackage();
        // 构建 common_package 信息
        this.commomPackage = transformCommonPackage(this.baseOption, this.logConfig);
        this.logger.init(this.logConfig, this.commomPackage);

        this.initYoda();
        this.addPlugins();
        if (typeof this.initBuildInPlugins === 'function') {
            this.initBuildInPlugins();
        }
        // 事件绑定
        if (typeof window !== 'undefined') {
            addMonitor(window, 'pagehide', this.beforeUnload);
            addMonitor(window, 'beforeunload', this.beforeUnload);
        }
    }
    get Utils() {
        return {
            yoda: this.yoda && yodaUtils,
            cookie: cookieUtils,
            ua: uaUtils,
            io: {
                sendData
            }
        }
    }
    get sessionId() {
        return getSessionId();
    }
    async initYoda() {
        if (this.logConfig.yoda && !this.yoda) {
            this.yoda = checkYoda(this.logConfig.yoda);
        }
        this.yoda && await initYoda()
        getKSwitchSampling().then(() => {
            // yoda 初始化后更新采样配置
            if (this.currentUrlPackage) this.currentUrlPackage.sampled = this.getPageSampled();
        });
        this.commomPackage.initYodaDeviceInfo();
        this.commomPackage.initYodaAppInfo();
    }
    initBuildInPlugins?(option?: any){}
    // 初始化 url 信息
    private initUrlPackage() {
        // 初始化 url 信息
        this.updateCurrentUrlPackage();
        const { referer } = this.logConfig;
        let referUrl = '';
        let referType: PageTypeKey = 'web';
        if (referer) {
            referUrl = referer.value;
            referType = referer.type && referer.type || referType;
        } else if (typeof document !== 'undefined' && document.referrer) {
            referUrl = document.referrer;
        }
        if (referUrl) {
            this.referUrlPackage = new UrlPackage(referUrl, referType, this.logConfig.urlMap as UrlMapFn | UrlMap);
        }
    }
    /**
     * 是否已获取到 kswitch 采样配置
     */
    get isKSwitchSampled() {
        return typeof KSwitchSampling !== undefined;
    }
    /**
     * 获取页面级配置
     */
    getPageSampled() {
        // 如果不是 yoda 环境，或者没从 kswitch 中获取到采样结果
        if (!this.yoda || !KSwitchSampling) {
            return typeof Global.sampled === 'boolean' ? Global.sampled : true;
        }

        const currentUrl = typeof location !== 'undefined' ? location.href.replace(/https?:\/\//, '').split('?')[0] : '*';
        if (typeof this.SampledPageMap[currentUrl] !== 'undefined') {
            return this.SampledPageMap[currentUrl];
        }

        if (KSwitchSampling) {
            let matchedOverAll;
            let level = 0;
            let radar: { [key: string]: number; } | null = null;
            let radarConfigs = [];

            // 找出当前页面的采样配置
            for (const path in KSwitchSampling) {
                let curLevel = 0;
                if (path === '*') {
                    // * 权重为 1
                    curLevel = 1;
                } else if (currentUrl.indexOf(path) === 0) {
                    if (path === currentUrl) {
                        // 相等的权重为 100
                        curLevel = 100;
                    } else if (path[path.length - 1] === '/') {
                        // 包含的权重为 10
                        curLevel = 10 + 1 - 1 / path.length;
                    }
                }
                if (!curLevel) continue;
                const { radar, overwrite, overall } = KSwitchSampling[path];
                curLevel = curLevel + (overwrite ? 1000 : 0);
                if (radar) {
                    radarConfigs.push({
                        radar,
                        level: curLevel
                    });
                }
                if (curLevel > level) {
                    level = curLevel;
                    matchedOverAll = overall;
                }
            }
            // 先判定整体采样
            if (level) {
                radar = {};
                radarConfigs.sort((r1, r2) => r1.level - r2.level).forEach(r => {
                    Object.assign(radar, r.radar);
                });
            }
            let sampled: boolean | { radar: { [key: string]: number; }; };
            // 有 overwrite 或业务方没有配置采样时，使用 kswitch 采样配置
            if (level > 1000 || typeof Global.sampled === 'undefined') {
                sampled = Math.random() < Number(matchedOverAll) && (radar ? { radar } : true);
            } else {
                sampled = typeof Global.sampled === 'boolean' ? Global.sampled : true;
            }
            this.SampledPageMap[currentUrl] = sampled;
        }
        return this.SampledPageMap[currentUrl];
    }

    get currentUrl() {
        const { page, params } = this.currentUrlPackage;
        return params && typeof params === 'object'
            ? `${page}?${stringifyQuery(params)}`
            : page;
    }

    get referUrl() {
        if (!this.referUrlPackage) {
            return '';
        }
        const { page, params } = this.referUrlPackage;
        return params && typeof params === 'object'
            ? `${page}?${stringifyQuery(params)}`
            : page;
    }
    /**
     * 更新当前 url 信息
     * @param url
     * @param type
     */
    updateCurrentUrlPackage(url: string | UrlObj = typeof location !== 'undefined' ? location.href : '', type: PageTypeKey = 'web'): void {
        // 该接口不改变 refer 信息
        // this.referUrlPackage = this.currentUrlPackage;
        if (typeof url === 'object' && url.page) {
            if (this.currentUrlPackage && url.page === this.currentUrlPackage.page) {
                return this.currentUrlPackage.update(url.page, url.params);
            }
        }
        this.currentUrlPackage = new UrlPackage(url, type, this.logConfig.urlMap as UrlMapFn | UrlMap);
        this.currentUrlPackage.sampled = this.getPageSampled();
    }
    /**
     * 更新 refer url 信息
     * @param url
     * @param type
     */
    updateReferUrlPackage(url: string | UrlObj | UrlPackage = this.currentUrlPackage, type: PageTypeKey = 'web') {
        if (url instanceof UrlPackage) {
            this.referUrlPackage = url;
        } else {
            this.referUrlPackage = new UrlPackage(url, type, this.logConfig.urlMap as UrlMapFn | UrlMap);
        }
    }
    /**
     * 重设 common_package 信息
     * @param base
     */
    updateBase(base: BaseOptions) {
        this.logger.updateBase(transformCommonPackage({
            ...this.baseOption,
            ...base,
        }, this.logConfig));
    }

    /**
     * 更新现有 common_package 信息
     */
    updateCommonPackage(options: BaseOptions | CommonPackageOptions) {
        if (typeof options !== 'object') return;
        if (!this.commomPackage) return;
        this.commomPackage.update(options);
    }
    /**
     * 初始化插件
     * @param plugins
     */
    addPlugins() {
        if (!this.logConfig.plugins || !this.logConfig.plugins.length) return;
        // 类似 webpack plugins 的实例数组
        this.logConfig.plugins.forEach((plugin: any) => {
            if (typeof plugin === 'object' && typeof plugin.apply === 'function') {
                this.addPluginInstance(plugin);
            }
        });
    }
    /**
     * 加载插件实例
     */
    addPluginInstance(pluginInstance: any) {
        if (pluginInstance) {
            const key = pluginInstance.key || pluginInstance.constructor && pluginInstance.constructor.key || `plugin_auto_key_${pluginIndex++}`;
            if (typeof pluginInstance.apply === 'function' && (!pluginInstance.weblog || pluginInstance.weblog !== this)) {
                pluginInstance.apply(this);
            }
            this.plugins[key] = pluginInstance;
        }
    }
    /**
     * 加载插件构造函数
     * @param plugin
     * @param pluginOptions
     */
    plug(plugin: BasePluginConstructor, pluginOptions?: any) {
        if (this.plugins[plugin.key]) {
            throw new Error(`There is a duplex plugin called ${plugin.key}`);
        }
        this.addPluginInstance(new plugin(this, pluginOptions));
    }

    unplug(name: string) {
        const plugin = this.plugins[name];
        if (plugin) {
            plugin.destroy();
            delete this.plugins[name];
        }
    }

    unplugAll() {
        for (const key in this.plugins) {
            if (this.plugins[key]) {
                this.unplug(key);
            }
        }
    }

    public flush = () => {
        this.logger.flush();
    };

    generateLog(
        type: SendType = TaskOperations.CLICK,
        name: string | PVOptions | ShowOptions | TaskOptions | CustomOptions | OtherEventOptions,
        params?: any,
        contentPackage?: ContentPackage,
        factory: (type: string, data: any) => any = logFactory
    ) {
        if (typeof type !== 'string') {
            type = type.type;
        }
        type = type.toUpperCase();
        const generateLogName = name;
        const getCommonProps = (name: string) => {
            let currentUrlPackage = null;
            if (typeof generateLogName === 'object') {
                currentUrlPackage = generateLogName.currentUrlPackage;
                contentPackage = (generateLogName as PVOptions | ShowOptions | TaskOptions).contentPackage || contentPackage;
            }
            if (currentUrlPackage) {
                const { page, identity, params } = currentUrlPackage;
                currentUrlPackage = {
                    identity: identity || uuid(page || ''),
                    page,
                    params: typeof params === 'object' ? JSON.stringify(params) : params
                };
            } else {
                currentUrlPackage = this.currentUrlPackage.toJSON();
            }
            if (contentPackage) {
                contentPackage = typeof contentPackage === 'string' ? contentPackage : JSON.stringify(contentPackage);
            }
            return {
                name,
                // 传入的 url_package 信息不应该是可变的对象，会导致延时的 log page 信息变更
                currentUrlPackage,
                referUrlPackage: this.referUrlPackage ? this.referUrlPackage.toJSON() : undefined,
                contentPackage
            };
        };
        if (typeof name === 'object') {
            if (type === 'PV') {
                // PV 类型
                const { type, page, params: pageParams } = name as PVOptions;
                this.currentUrlPackage.update(page, pageParams);
                return factory(EventTypes.PV, {
                    ...name as PVOptions,
                    type,
                    ...getCommonProps('PV')
                });
            }
            // 如果参数中带有 urlPage 则更新 page 信息
            const { urlPage } = name as ShowOptions;
            urlPage && this.currentUrlPackage.update(urlPage.page);

            if (type === EventTypes.SHOW) {
                // SHOW 类型
                const { action } = name as ShowOptions;
                return factory(type, {
                    ...name as ShowOptions,
                    ...getCommonProps(action)
                });
            }
            // Radar 日志特殊处理
            if (type === 'RADAR') {
                return factory(type, name as OtherEventOptions);
            }
            if (type === EventTypes.CUSTOM) {
                // CUSTOM 类型
                const { key, value, eventId } = name as CustomOptions;
                return factory(type, {
                    params: value,
                    eventId,
                    ...getCommonProps(key),
                });
            }
            // TASK 类型
            const { action, params: taskParams, status, type: taskType, eventId } = name as TaskOptions;
            return factory(type, {
                ...name,
                params: taskParams,
                status,
                taskType,
                eventId,
                ...getCommonProps(action),
            });
        }

        if (type === 'PV') {
            this.currentUrlPackage.update(name, params);
        }
        return factory(type, {
            params,
            ...getCommonProps(name),
        });
    }

    get isSendSampled() {
        return this.currentUrlPackage && this.currentUrlPackage.sampled || Global.isDebug;
    }

    send(
        type: SendType = TaskOperations.CLICK,
        name: string | PVOptions | ShowOptions | TaskOptions | CustomOptions,
        params?: any,
        contentPackage?: ContentPackage,
        immediately?: boolean
    ) {
        // 如果该用户不被采样，则所有日志都不会上报
        if (!this.isSendSampled) {
            return;
        }
        const log = this.generateLog(type, name, params, contentPackage) as ClientLogV3.ReportEvent;
        // 雷达日志单独处理
        if (type === 'RADAR') {
            return this.logger.sendRadar(log);
        }
        const callback = typeof name === 'object' && name.callback || undefined;
        return this.logger.send(log, !!immediately, callback);
    }

    collect(type: SendType, options: PVOptions | ShowOptions | TaskOptions | CustomOptions): void;
    collect(type: SendType, name: string, params: any, contentPackage?: string): void;
    collect(
        type: SendType = TaskOperations.CLICK,
        name: string | PVOptions | ShowOptions | TaskOptions | CustomOptions,
        params?: any,
        contentPackage?: ContentPackage,
    ) {
        return this.send(type, name, params, contentPackage, false);
    }

    sendImmediately(type: SendType, options: PVOptions | ShowOptions | TaskOptions | CustomOptions): void;
    sendImmediately(type: SendType, name: string, params: any, contentPackage?: string): void;
    sendImmediately(
        type: SendType = TaskOperations.CLICK,
        name: string | PVOptions | ShowOptions | TaskOptions | CustomOptions,
        params?: any,
        contentPackage?: ContentPackage,
    ) {
        return this.send(type, name, params, contentPackage, true);
    }

    sendPackage(data: LogPackage, callback?: (error?: any) => void) {
        this.logger.sendPackage(data, callback);
    }

    beforeUnload = (event: Event) => {
        if (isWillClosed) return;
        this.logger.drain();
        for (const key in this.plugins) {
            const plugin = this.plugins[key];
            if (typeof plugin.beforeUnload === 'function') {
                plugin.beforeUnload(event);
            }
        }
        isWillClosed = true;
        setTimeout(() => {
            isWillClosed = false;
        }, 2000);
    };
    destroy() {
        this.unplugAll();
        if (typeof window !== 'undefined') {
            removeMonitor(window, 'pagehide', this.beforeUnload);
            removeMonitor(window, 'beforeunload', this.beforeUnload);
        }
    }
}
