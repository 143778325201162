import { getMappingPage, getUrlAndQueryFromUrl } from '../util';
import { uuid } from '../util/uuid';
import { UrlMapFn, UrlMap } from '../config/types';
import Global from '../config/global';

export enum PageType {
    // 未知
    UNKNOWN_PAGE_TYPE = 0,
    // native页面
    NATIVE = 1,
    // h5页面
    H5 = 2,
    // 小程序页面
    MINA = 3,
}

export type PageTypeKey = 'web' | 'native' | 'mina';

export interface UrlObj {
    page?: string;
    params?: any;
}
// tslint:disable variable-name
export default class UrlPackage {
    // 一级页面。参考埋点需求填写即可
    page: string = '';
    // 参数列表参考埋点需求填写，并转成 json 字符串格式
    params?: {[key: string]: any};
    // 实例的全局唯一标识，使用UUID即可
    identity: string = '';
    // 应用页面类型
    // TODO这货后面还需要考虑小程序的支持
    page_type: PageType;
    // 采样结果
    sampled?: boolean | {radar: {[key: string]: number}};
    constructor(url: string | UrlObj, pageType: PageTypeKey = 'web', urlMap?: UrlMap | UrlMapFn) {
        switch (pageType) {
            case 'web':
                this.page_type = PageType.H5;
                break;
            case 'native':
                this.page_type = PageType.NATIVE;
                break;
            case 'mina':
                this.page_type = PageType.MINA;
                break;
            default:
                this.page_type = PageType.UNKNOWN_PAGE_TYPE;
        }
        if (typeof url === 'string') {
            // TODO: 避免兼容问题，暂不处理
            // try {
            //     url = window.decodeURIComponent(url);
            // } catch (err) {}
            const obj = getUrlAndQueryFromUrl(url);
            this.page = obj.page;
            this.params = obj.params;
        } else {
            this.page = (url || {}).page || '';
            this.params = (url || {}).params || undefined;
        }
        this.identity = uuid(this.page);
        if (urlMap && typeof url === 'string') {
            this.init(url, urlMap);
        }
    }

    init(url: string, urlMap: UrlMap | UrlMapFn = {}) {
        const { page, params } = getMappingPage({url, page: this.page, params: this.params}, urlMap);
        this.update(page, params);
    }

    update(page: string = '', params?: any) {
        if (page && page !== this.page) {
            this.page = page;
            this.identity = uuid(page);
        }
        if (params) {
            if (!this.params) this.params = {};
            Object.assign(this.params, params);
        }
    }

    toJSON() {
        return {
            page: this.page,
            identity: this.identity,
            page_type: this.page_type,
            params: JSON.stringify(this.params)
        };
    }
    /**
     * 获取真实的 url 信息，并与 PV 上报的通过参数做关联
     */
    getRealUrlPackage() {
        const { page, params } = getUrlAndQueryFromUrl(location.href);
        return {
            page: page,
            params: JSON.stringify({
                url: location.href,
                page: this.page,
                query: params
            }),
            page_type: this.page_type,
            identity: this.identity
        };
    }
}
// tslint:enable
