/**
 * @file Radar 核心部件，负责插件组合、上报日志处理
 * @author Malcolm Yu
 */

import { Weblogger } from '../../config/types';
import { RadarRoot } from './base';
import { RADAR_KEY } from './const';
import { error } from '../../util/console';
import { RadarMonitor, RadarOptions, CustomKey, SetDimensionParam, CustomDimensionKey, EventDimension } from './interface';
import { normalizeURL, radarWarnLog, getUAList, getWebViewType } from './utils';

import { perfNow } from './timing';
import NavigationPlugin from './navigation';
import ResourcePlugin from './resource';
import APIPlugin from './xhr';
import ErrorPlugin from './error';


const MORE_REPORTED = ['play_block', 'play_error'];
const CUSTOM_DIM_KEY: CustomDimensionKey[] = ['c_dimension1', 'c_dimension2', 'c_dimension3'];

export default class Radar extends RadarRoot implements RadarMonitor {
    static key = 'radar';
    public radarIgnoreList: string[] = ['https://web-trace.ksapisrv.com/ktrace/collect'];
    // public ignoreList: string[];
    public radarFmp: number = 0;
    private customReported: CustomKey[] = [];
    /**
     * 兼容写法，如果在 Weblog 初始化设置 plugins 时，不需要设置 weblog
     * @param weblog 
     * @param options 
     */
    constructor(options: RadarOptions);
    constructor(weblog: Weblogger | RadarOptions, options?: RadarOptions) {

        // this.currentUrlPackage.sampled 
        //
        super(weblog, options);
        if (!this.validOption()) {
            return;
        }

        this.use(NavigationPlugin, this);
        if (!this.options || !this.options.unuseResourcePlugin) {
            this.use(ResourcePlugin, this);
        }
        if (!this.options || !this.options.unuseAPIPlugin) {
            this.use(APIPlugin, this);
        }
        if (!this.options || !this.options.unuseErrorPlugin) {
            this.use(ErrorPlugin, this);
        }
    }

    // 采样控制 对外暴露调用
    static samplingControl(options?: RadarOptions | number) {
        if (!options) {
            return false;
        }
        // 计算随机数
        const rand = Math.random();
        if (typeof options === 'number') {
            // 传入的是单一模块采样率 无需从options中取值
            return rand < options;
        }
        // radar整体采样率逻辑
        const { sampling } = options;

        return rand < sampling;
    }

    public collect(key: string, duration: number) {
        if (key.indexOf('c_') !== 0) {
            error(`[Radar] 传入的自定义指标 ${key} 必须为 c_ 开头`);
            return;
        }
        // 支持 performance API 的可以直接用 performance now
        this.logCollect({
            key: RADAR_KEY.CUSTOM,
            value: {
                metric_value: duration,
            },
            dimension: {
                metric_name: key,
            },
        });
    }

    private reportTimePoint(key: CustomKey, time?: string | number) {

        if (this.customReported.indexOf(key) > -1) {
            return null;
        }
        // 这两个是可以上报多次的
        if (MORE_REPORTED.indexOf(key) === -1) {
            this.customReported.push(key);
        }

        let value = time;

        if (typeof time !== 'string') {
            // 支持 performance API 的可以直接用 performance now
            const detail = perfNow(time);
            // 没有 performance API 的暂不记录了
            if (detail.value == null) return;
            value = this.throttle(detail.value, detail.detail as any, 'fmp', detail.detail);
        }
        if (value == null) return;

        this.reportPerformance({
            custom: true,
            entry: {
                key,
                value,
            },
            renderTime: time as number
        });

        return value;
    }

    // 用户自定义系列指标
    public fmp(time?: number) {
        // 记录一下业务上报的fmp的时间戳
        this.radarFmp = time || Date.now();

        const fmp = this.reportTimePoint('fmp', this.radarFmp);

        if (fmp) {
            try {
                // @ts-ignore
                if (performance.timing) {
                    // 获取fmp的时候 添加时间戳到timing上 方便后续计算
                    (performance.timing as any).fmp = fmp;
                    (performance.timing as any).radarFmp = this.radarFmp;
                };
            } catch (e) {
                // do nothing，防止部分机型报错
            }
            // debug模式下记录log
            radarWarnLog('fmp', fmp);

            this.logCollect({
                key: RADAR_KEY.CUSTOM,
                value: {
                    fmp,
                    fmp_time: this.radarFmp,
                    // 自定义上报是否合并到load表
                    'merge': true
                },
            });
        }
    }

    // 用户定义的事件
    public event(dimension: EventDimension, value?: object) {
        const uaList = getUAList();
        // 处理extra_info
        if (dimension.extra_info && dimension.extra_info instanceof Object) {
            dimension.extra_info = JSON.stringify(dimension.extra_info);
        }
        // 处理yoda_version
        if (dimension.yoda_version === undefined) {
            dimension.yoda_version = uaList.Yoda || '';
        }
        // 处理webview_type
        if (dimension.webview_type === undefined) {
            dimension.webview_type = getWebViewType();
        }
        this.logCollect({
            key: RADAR_KEY.EVENT,
            value,
            dimension,
        });
    }

    // 用于无头浏览器环境手动模拟onload事件内容进行perf数据收集
    public headlessTestReport() {
        const ua = window.navigator.userAgent;
        if (ua.indexOf('headless')) {
            this.asyncReportTiming();
            return;
        }
        console.warn('[radar] report: ', '禁止在正常浏览器中使用手动上报功能');
    }

    public playClicked(time?: number) {
        this.reportTimePoint('play_clicked', time);
    }

    public playIDR(time?: number) {
        this.reportTimePoint('play_idr', time);
    }

    public playBlock(time?: string) {
        this.reportTimePoint('play_block', time);
    }

    public playError(time?: string) {
        this.reportTimePoint('play_error', time);
    }

    public setDimensions(param: SetDimensionParam) {
        const paramKey = Object.keys(param);
        const hasOtherDim = paramKey.some(key => CUSTOM_DIM_KEY.indexOf(key as CustomDimensionKey) === -1);
        if (hasOtherDim) {
            error(`[Radar] 传入的自定义维度只能为 ${CUSTOM_DIM_KEY.join('、')}，目前传入的是：${paramKey.join('、')}`);
        }
        paramKey.forEach(key => {
            const k: CustomDimensionKey = key as CustomDimensionKey;
            if (param[k] == null) {
                delete this.customDimension[k];
            } else {
                this.customDimension[k] = param[k];
            }
        });
    }

    validOption() {
        if (!this.options) {
            // 未传入参数默认不开启
            return false;
        }

        const { sampling, projectId } = this.options;
        if (typeof projectId !== 'string') {
            error('[Radar] 必须配置 projectId 属性');
            return false;
        }
        if (sampling > 1 || sampling < 0) {
            error('[Radar] 采样率 sampling 必须在 0~1 之间');
            return false;
        }

        return true;
    }
}