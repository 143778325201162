import sendIframe from './iframe';
import sendXHR from './xhr';
import { needCompatible } from '../util/ua';
import { DataOptions } from './types';
import sendBeacon from './beacon';
import { transToFormData } from './util';

export default function sendData(
    { url, data, timeout, format }: DataOptions,
    callback?: (error?: any) => void
) {
    // 使用 iframe 发送
    if (needCompatible()) {
        return sendIframe({ url, data }, callback);
    }
    data = format && format === 'string' ? JSON.stringify(data) : transToFormData(data);
    // 优先走 sendBeacon，如果成功把数据添加到 beacon 通道，则认为发送成功
    if (sendBeacon({url, data})) {
        return callback && callback();
    }
    sendXHR({ url, data, timeout }, callback);
}
