/**
 * @file 直接使用sendBeacon
 *
 * @author 天翔Skyline(skyline0705@gmail.com)
 * May 14, 2019
 */
import { DataOptions } from './types';
import { getUAInfo } from '../util/ua';
let enableBeacon = false;
(() => {
    try {
        const uaInfo = getUAInfo();
        const forceUseBeacon = uaInfo.browser.name === 'Chrome'
            && +(uaInfo.browser.major || 0) > 77;
        enableBeacon = forceUseBeacon && typeof window !== 'undefined' && typeof window.navigator !== 'undefined' && typeof window.navigator.sendBeacon === 'function';
    } catch (err) {};
})();
export default function send(
    { url, data }: DataOptions
)  {
    if (!enableBeacon) return false;
    return navigator.sendBeacon(url, data);
}
