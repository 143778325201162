/**
 * @file Radar 的早期设计有点问题，合理的设计应该永远主干是空的，一切均以插件方式载入
 * 插件享受主干的生命周期调度、核心逻辑处理与事件发送等
 * 由于 Navigation 的逻辑处理十分复杂，这里也把 Navi 拆出来单独处理
 */
import RadarPlugin from './plugin';
import { CustomEntry, NavigationLogData, KVPair, webviewPerfRes } from './interface';
import { addMonitor, removeMonitor } from '../../util';
import { chromeMetric, formatLoadTime, check4Tab } from './utils';
import { RADAR_KEY, domPerfMap, webviewPerfMap, loadTimestamp } from './const';

const LOAD_REPORT_DELAY = 10 * 1000; // 加载上报日志

export default class Navigation extends RadarPlugin {
    public key = 'navigation';
    private eventName = 'onpagehide' in window ? 'pagehide' : 'beforeunload';
    private logData: NavigationLogData = {};
    private logSended: boolean = false;
    private saveWebviewEntry: any = {};
    // 处理上报时机
    private delay: any;
    private hasReportBase: boolean = false;
    private record = {
        fmp: false,
        base: false,
        webviewPerf: false
    };
    // 端外延迟2s的timeout
    private loadTimeout: any = null;

    // 立即上报

    get sendImmediate() {
        return this.record.fmp && this.record.base && this.record.webviewPerf && !!check4Tab();
    }

    get data() {
        return this.sepDimension({
            key: RADAR_KEY.LOAD,
            value: this.logData,
        });
    }

    async created() {
        // 目前在端外和使用了 yoda 聚合 bridge 的场景下，均不需要进行兜底
        // 但是在端内没有 yoda 聚合能力的情况下，需要做一个兜底
        // if (this.radar.isUsingDetachedReport) {
        //     this.delay = setTimeout(() => this.reportOnUnload('noflash'), LOAD_REPORT_DELAY);
        // }
        // addMonitor(window, this.eventName, this.reportOnUnload);
    }

    when(entry: PerformanceEntry | CustomEntry) {
        // 接受 fmp/play_clicked/play_idr 三个自定义指标
        if ((entry as CustomEntry).custom) {
            const { key } = (entry as CustomEntry).entry || {};
            if (['fmp', 'play_clicked', 'play_idr', 'webViewPerf'].indexOf(key) > -1) {
                return true;
            }
            return false;
        }

        // 接受 level1 和 level2
        return !(entry as PerformanceEntry).entryType
            || (entry as PerformanceEntry).entryType === 'navigation';
    }

    async getWebviewLoadData() {
        if (this.record.webviewPerf) {
            // 禁止重复获取
            return;
        }
        const { yoda } = this.radar.weblog.Utils;
        const webviewPerf = yoda && await yoda.getWebviewLoadPerf() as webviewPerfRes;
        // 调用成功后更改状态 如果第一次调用失败 会在unload之前再调用一次
        // 兼容旧版本客户端webviewPerf是一个空对象的问题
        if (webviewPerf && webviewPerf.timeData) {
            // 没有获取到就不上报
            this.record.webviewPerf = true;
            // 第四tab下 会存在没有userstart的情况 需要使用pageStart为webview初始时间进行计算
            let { userStart, pageStart } = webviewPerf.timeData;
            if (!userStart) {
                webviewPerf.timeData.userStart = pageStart;
            }
            this.onPerfReport({
                custom: true,
                entry: {
                    key: 'webViewPerf',
                    // 客户端返回数据为
                    /**
                      {
                      result:number
                      timeData:{string:number}
                      }
                     */
                    value: webviewPerf.timeData,
                },
            });
        }
    }
    async onPerfReport(entry: PerformanceEntry | CustomEntry) {
        if ((entry as CustomEntry).custom) {
            const data = (entry as CustomEntry).entry;
            if (data) {
                const { key, value } = data;
                const { renderTime } = (entry as CustomEntry);
                if (key === 'fmp') {
                    //  fmp晚于load上报时 为了得到fmo_time数据 需要重新计算一次loadtime，随着fmp上报
                    (performance.timing as any).radarFmp = renderTime;
                    this.record.fmp = true;
                    const loadTime = formatLoadTime(performance.timing as any, loadTimestamp);
                    Object.assign(this.logData, { [key]: value }, loadTime);
                    this.getWebviewLoadData();
                    if (this.record.webviewPerf) {
                        // 兼容一下fmp特别慢的情况，当fmp收集到的时候 补全to_fmp
                        const webviewTiming = this.radar.generateLog(webviewPerfMap, this.saveWebviewEntry as PerformanceEntry);
                        Object.assign(this.logData, webviewTiming);
                    }
                }
                if (key === 'webViewPerf') {
                    const webviewEntry = data.value;
                    if (!webviewEntry) {
                        // 说明没有收集到webview指标
                        return;
                    }

                    this.saveWebviewEntry = webviewEntry;
                    const webviewTiming = this.radar.generateLog(webviewPerfMap, webviewEntry as PerformanceEntry);
                    Object.assign(this.logData, webviewTiming);
                }
            }
        } else {
            // load数据走这里的逻辑
            const logData = this.radar.generateLog(domPerfMap, entry as PerformanceEntry);
            const loadTime = formatLoadTime(performance.timing as any, loadTimestamp);
            // 无重复字段 不会覆盖
            Object.assign(this.logData, logData, loadTime);
            this.record.base = true;
        }
        // yoda 通道每次收到自定义数据都合并上报一次
        // yodaAlready用于兼容radar.bridge  使用非桥接上报版本优先通过桥接上报 如果fmp上报先于yodaready,需要在这里做一个兜底,否则会丢数据
        if (this.radar.isSupportedYodaConcat) {
            /**
             * 目前有三个位置可以调用此方法
             * 1。onload事件
             * 2。fmp上报
             * 3. webviewPerf
             * 端内分几种情况：
             * 1. onload +webviewPerf = to_fmp为NaN 客户端侧上报为Null 清洗侧为0 其他正常
             * 2. onload +fmp 不会上报to_fmp等字段  清洗侧为null
             * 3. onload +fmp+webviewperf 正常上报
             */
            // 防止上报的fmp上报之后 yoda没有ready 所以每次上报都会调用一次 如果bridge之前调用成功过，则不会继续调用bridge
            this.getWebviewLoadData();
            this.radar.logCollect(this.data);
        } else if (this.radar.isUsingDetachedReport && this.record.webviewPerf && this.record.fmp && this.record.base) {
            // 这里是为了覆盖客户端版本过低 对bridge支持不好的情况
            // 端内对 unload 支持很差，先弄成三个信息都有就强制上报（可能无需上报webviewperf 由数据侧根据时间戳计算）
            // 没有yoda 无法获取webview信息 
            // 上报之前需要合并webviewtiming
            this.reportOnUnload('noflash');
        } else if (this.record.fmp && this.record.base && !this.hasReportBase) {
            // 端外有这两个数据就立即上报
            // 这里应用于fmp先于load被收集的场景  或
            // load被收集2s内收集到fmp的场景 此时hasReportBase为false

            /**
             * 端外有两种情况
             * 1.fmp先于load 
             * 2.load先于fmp
             */
            clearTimeout(this.loadTimeout);
            this.loadTimeout = null;
            this.loadReport();
        } else if (!this.hasReportBase && this.record.base) {
            // 端外收集到load数据后延迟2s上报等待fmp数据 
            // 这里应用于load先于fmp被收集的场景
            this.loadTimeout = setTimeout(() => {
                this.loadReport();
                this.hasReportBase = true;
            }, 2000);
        }
    }

    destroy() {
        clearTimeout(this.delay);
        removeMonitor(window, this.eventName, this.reportOnUnload);
    }

    private sepDimension(kv: KVPair) {
        const { key } = kv;
        const { protocol, ...value } = kv.value;
        const dimension = { protocol, sendImmediate: this.sendImmediate };
        return { key, value, dimension };
    }

    // FIXME：处理一下上报差值
    private reportFirstScreen(data: NavigationLogData) {
        if (data.play_clicked && data.play_idr) {
            const firstScreen = data.play_idr - data.play_clicked;
            if (firstScreen > 0 && firstScreen < 60000) {
                this.logData.play_first_screen = firstScreen;
            }
        }
    }

    // 按照苗老师处理页面离开行为的思路来进行页面性能上报
    private reportOnUnload = (flush: any) => {
        // 走 yoda 通道的不额外上报
        if (this.logSended || this.radar.isSupportedYodaConcat) return;
        this.logSended = true;
        // FIXME: 上报差值，有点丑

        this.reportFirstScreen(Object.assign(this.logData, chromeMetric));
        this.radar.logCollect(this.data);
        if (flush !== 'noflash') {
            this.radar.flush();
            this.radar.weblog.flush();
        }
    };
    // 强制上报当前收集到的数据
    private loadReport() {
        // 加入lcp数据
        Object.assign(this.logData, chromeMetric)
        this.radar.logCollect(this.data);
        this.radar.flush();
        this.radar.weblog.flush();
    }
}
