// 为了减少点代码体积，自己搞一个简易的EventEmitter
import { deleteArray } from '.';

export default class EventEmitter {
    private events: {
        [key: string]: Array<(...args: any[]) => void>;
    } = {};
    on(eventName: string, callback: (...args: any[]) => void) {
        if (callback && typeof callback === 'function') {
            this.events[eventName] = this.events[eventName] || [];
            this.events[eventName].push(callback);
        }
    }
    off(eventName: string, callback?: (...args: any[]) => void) {
        if (!this.events[eventName]) {
            return;
        }
        if (callback  && typeof callback === 'function') {
            deleteArray(this.events[eventName], callback);
        }
        if (!callback) {
            this.events[eventName] = [];
        }
    }
    emit(eventName: string, ...args: any[]) {
        if (!this.events[eventName]) {
            return;
        }
        this.events[eventName].forEach(callback => {
            callback(...args);
        });
    }
}
