/**
 * 浏览器版本，只有 HTTP 上报，兼容旧版本包含了插件
 */

import '../util/polyfill';
import BaseWeblogger from '../core';
import { BasePlugin } from '../plugins/base';
// plugins
import CrashMonitor from '../plugins/crashMonitor';
import FpsMonitor from '../plugins/fpsMonitor';
import TimingMonitor from '../plugins/timingMonitor';
import Radar from '../plugins/radar/radar';
import RadarPlaceHolder from '../plugins/radar/placeholder';
import AutoPV from '../plugins/autoPV';
import { BuildInPluginsConfig } from './types';
export { LoggerConfigByUser, SupportTypes, SendConfig, PVOptions, ShowOptions, TaskOptions, CustomOptions, BaseOptions } from './types';

class Weblog extends BaseWeblogger<BuildInPluginsConfig> {
    initBuildInPlugins() {
        const {
            autoPV, radar, fps, timing, crash, autoPVFilter
        } = this.logConfig;
        if (fps || typeof fps === 'undefined') {
            this.plug(FpsMonitor);
        }
        if (timing || typeof timing === 'undefined') {
            this.plug(TimingMonitor);
        }
        if (crash || typeof crash === 'undefined') {
            this.plug(CrashMonitor);
        }
        if (autoPV || typeof autoPV === 'undefined') {
            this.plug(AutoPV, autoPVFilter);
        }
        if (radar) {
            if (radar.sampling && Radar.samplingControl(radar)) {
                this.plug(Radar, radar);
            } else {
                this.plug(RadarPlaceHolder);
            }
        }
    }
}
// 注册到全局对象
if (typeof window !== 'undefined') {
    if (typeof window._GLOBAL_KS_WEBLOGGER_ !== 'undefined') {
        window._GLOBAL_KS_WEBLOGGER_.Factory = Weblog;
    } else {
        // @ts-ignore
        window.Weblog = Weblog;
    }
}


export {
    Weblog,
    BasePlugin,
};

export default Weblog;