import { DEFAULT_IGNORE_LIST } from './const';
import { chromeMetrics, chromeMetricsConfig } from './interface';
import { getLCP, getFID, getCLS } from 'web-vitals';
import { parseQueryString } from '../../util';
import { getCookie } from '../../util/cookie';

import {
    loadPerformanceReport
} from '../types';


const reProtocol = /^(https?:)?\/\//;

export function normalizeURL(ignoreList: string[] = []) {
    const list = ignoreList.concat(DEFAULT_IGNORE_LIST);

    // 把协议头剃掉，防止出现协议头不一致
    return list.map(url => url.replace(reProtocol, ''));
}

export function isInURLWhiteList(url: string, ignoreList: string[]) {
    if (!url) return false;
    const domainPath = url.replace(reProtocol, '');
    return ignoreList.some(whiteURL => {
        return domainPath.indexOf(whiteURL) >= 0;
    });
}
// 计算雷达自身耗时
export const radarCost: any = {};
// 保留拓展能力
export function radarPoint(name: string, open: boolean = false) {
    // if (!open) {
    //     // 默认关闭 需要业务方手动开启
    //     return
    // }
    const costName = radarCost[name];
    if (!costName) {
        radarCost[name] = { startTime: new Date().getTime() };
    } else if (!costName.endTime) {
        costName.endTime = new Date().getTime();
        costName.duration = costName.endTime - costName.startTime;
        delete costName.startTime;
        delete costName.endTime;
    }
}

/**
    * 收集chrome指标 比如lcp cls fid等
    * 会在unload事件里统一合并上报
    */
export function collectChromeMetrics(opt: chromeMetrics) {
    Object.assign(chromeMetric, opt);
}
export const chromeMetric: chromeMetrics = {};

export const chromeMetricConfig: chromeMetricsConfig[] = [
    { name: 'fid', collectFn: getFID },
    { name: 'lcp', collectFn: getLCP },
    { name: 'cls', collectFn: getCLS }
];

// 取消通过客户端注入脚本来收集错误
export const removeBridgeJsErrorCollect = () => {
    if (typeof window !== 'undefined') {
        (window as any).yodaCollectErrorDataDestroy && (window as any).yodaCollectErrorDataDestroy();
    }
};

// 雷达通用debug函数
export const radarWarnLog = (type: string, value: any) => {
    const query = parseQueryString(location.search.slice(1));

    if (+getCookie('debug') === 1 || +query.debug === 1) {
        console.warn(`[radar] ${type}: `, value);

    }

};


/**
 * @file 对 radar 的数据进行预处理
 */
function squashData(data: any[]) {
    let loadLog = null;
    let j = 0;
    let i = 0;

    // @ts-ignore
    for (; i < data.length; i++) {
        if (data[i].key === 'load') {
            loadLog = data[i];
        } else {
            data[j++] = data[i];
        }
    }

    data.length = j;

    return loadLog;
}

interface UrlPage {
    page?: string; // url_package.page 当前 page 的 name
    identity?: string;
}

export interface CustomStatEventOptions {
    key: string;
    value?: any;
    serviceName?: string;
    subBiz?: string;
    needEncrypt?: boolean;
    realtime?: boolean;
    h5ExtraAttr?: string;
    container?: string;
    eventId?: string;
    urlPage?: UrlPage;
}
interface SendRadarLogOptions {
    removeStashedLog: string[];
    sendImmediate: boolean;
    customData: {
        h5_extra_attr: string | undefined;
        project_id: string;
        data: any[];
    };
}
interface FormattedRadarLog {
    loadLog: SendRadarLogOptions | null;
    commonLog: SendRadarLogOptions;
}

export function formatYodaRadarData(
    options: CustomStatEventOptions,
): FormattedRadarLog {
    if (!options.value) return options as any;
    const params = options.value;
    // 过滤出最后一条load数据
    const loadLog = squashData(params.data);
    const commomParams = {
        project_id: params.project_id,
        app_version_name: params.app_version_name,
        url_package: params.url_package,
        // 统一传下划线格式
        h5_extra_attr: options.h5ExtraAttr,
    };
    return {
        loadLog: loadLog ? {
            removeStashedLog: [],
            sendImmediate: loadLog.dimension.sendImmediate,
            customData: {
                data: [loadLog],
                ...commomParams,
            },
        } : null,
        commonLog: {
            removeStashedLog: [],
            sendImmediate: true,
            customData: {
                data: params.data,
                ...commomParams
            },
        },
    };
}
export const getUAList = () => {
    const ua = window.navigator.userAgent;
    const uaList: { [key: string]: string; } = {};
    ua.split(' ').map(item => {
        const arr = item.split('/');
        uaList[arr[0]] = arr[1];
    });
    return uaList;
};


// 获取webview_type
export const getWebViewType = () => {
    const ua = window.navigator.userAgent;
    if (ua.indexOf('Android') !== -1) {
        return 'WebView';
    }
    if (ua.indexOf('iPhone') !== -1) {
        const uaList = getUAList();
        if (uaList.Yoda && uaList.WebViewType === 'WK') {
            return 'YodaWKWebView';
        }
        if (!uaList.Yoda && uaList.WebView === 'WKWebView') {
            return 'WKWebView';
        }
        return 'UIWebView';
    }
    return '';
};

export const formatLoadTime = (timing: loadPerformanceReport, entry: loadPerformanceReport) => {

    const res: loadPerformanceReport = {};
    Object.keys(entry).forEach(item => {
        if (timing[entry[item]] > 0) {
            res[item] = timing[entry[item]];
        }
    });
    return res;
};
// 判断是否在第四tab环境下
export const check4Tab = () => {
    const ua = window.navigator.userAgent;
    // tbht不等于0
    const reg = /tbht\/([\w]+)/i;
    const res = ua.match(reg);
    return res && res[1] !== '0';
};
