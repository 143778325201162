/**
 * @file fps.js
 * @author kevin (lanlazy@163.com)
 * @created 2018-08-25
 */

import { raf, caf } from '../../util';
import {
    FPSMonitorOptions,
} from '../types';
import { BasePlugin, autoRegister } from '../base';
import { Weblogger } from '../../config/types';

const defaultOptions = {
    // 更新间隔
    interval: 10 * 1000,
    // 浮点精度 0 取整
    decimals: 2,
};

export default class FPSMonitor extends BasePlugin {
    static key = 'fpsMonitor';
    private options = {
        ...defaultOptions,
    };
    private count = 0;
    private fpsTickId!: number;
    private loopId!: number;
    private loopBeginTime!: number;
    private loopEndTime!: number;
    /**
     * 兼容写法，如果在 Weblog 初始化设置 plugins 时，不需要设置 weblog
     * @param weblog 
     */
    constructor(options?: FPSMonitorOptions)
    constructor(weblog?: Weblogger | FPSMonitorOptions, options?: FPSMonitorOptions) {
        super();
        // 兼容处理
        let opts;
        if (options) {
            opts = options;
        } else if (weblog) {
            if ((weblog as Weblogger).logger) {
                this.apply(weblog as Weblogger);
            } else {
                opts = weblog;
            }
        }
        Object.assign(this.options, options);

        this.on('performance', fps => {
            this.weblog.collect('CUSTOM', 'fps', { fps });
        });
        this.start();
    }
    destroy() {
        this.off('performance');
        this.stop();
    }
    start() {
        this.loop();
        this.fpsTick();
    }
    fpsTick() {
        this.fpsTickId = raf(() => {
            this.count++;
            this.fpsTick();
        });
    }
    loop() {
        this.loopBeginTime = new Date().valueOf();
        this.loopId = window.setTimeout(() => {
            this.loopEndTime = new Date().valueOf();
            const fps = this.calculate();
            this.emit('performance', fps);
            this.count = 0;
            this.loop();
        }, this.options.interval);
    }
    stop() {
        caf(this.fpsTickId);
        window.clearTimeout(this.loopId);
    }
    calculate() {
        const during = this.loopEndTime - this.loopBeginTime;
        // raf执行次数
        const runCount = during / this.count;
        return +(1000 / runCount).toFixed(this.options.decimals);
    }
}