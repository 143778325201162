import { BasePlugin } from '../base';
export default class RadarPlaceHolder extends BasePlugin {
    static key = 'radar';

    fmp() {
        // do nothing
    }

    event() {
        // do nothing
    }

    headlessTestReport() {
        // do nothing
    }

    playClicked() { /* do nothing */ }
    playIDR() { /* do nothing */ }
    playBlock() { /* do nothing */ }
    playError() { /* do nothing */ }

    setDimensions() { /* do nothing */ }

    destroy() {
        // do nothing
    }
}