// TODO下面这个暂时不能这么用，因为计量方式和perfirmanceTiming不一样
// export default performanceNavigationTiming || performanceTiming || null;
export default () => {
    const perf = typeof window !== 'undefined'
    ? window.performance
    : null;

    if (perf && perf.timing) {
        if (typeof perf.timing.toJSON === 'function') {
            const performanceTiming = perf.timing.toJSON();
            if (performanceTiming && !performanceTiming.redirectStart) {
                performanceTiming.redirectStart = performanceTiming.navigationStart;
            }
            return performanceTiming;
        }

        return perf.timing;
    }

    return null;
};

interface PerfNowDetail {
    value: number | null;
    detail?: {
        start: string;
        startPoint: number;
        end: string;
        endPoint: number;
    };
}

export function perfNow(time?: number): PerfNowDetail {
    const perf = typeof window !== 'undefined'
    ? window.performance
    : null;

    if (!perf) return { value: null };

    // 用户传入时间戳则使用用户的传入
    if (time != null) {
        return {
            value: time - perf.timing.navigationStart,
            detail: {
                start: 'navigationStart',
                startPoint: perf.timing.navigationStart,
                end: 'customTime',
                endPoint: time,
            },
        };
    }

    if (typeof perf.now === 'function') {
        return {
            value: perf.now(),
            detail: {
                start: 'navigationStart',
                startPoint: perf.timing.navigationStart,
                end: 'perfNowAPI',
                endPoint: perf.now(),
            },
        };
    }

    const now = Date.now();

    return {
        value: now - perf.timing.navigationStart,
        detail: {
            start: 'navigationStart',
            startPoint: perf.timing.navigationStart,
            end: 'dateNow',
            endPoint: now,
        },
    };
}
